import React from 'react';
import { Path, resolvePath, useNavigate } from 'react-router';

import pages from 'pages';
import { MenuButton } from 'shared/components/MenuButton/MenuButton';
import { TIcon } from 'shared/components/icons/TIcon';
import { EverBrightLogo } from '../icons/EverBrightLogo';
import { LogoLink, MenuItems, NavigationWrapper } from './Navigation.styles';

export type MenuOptions = {
  label: string;
  link: Path | string;
  icon: TIcon;
};

type Props = {
  menuOptions: MenuOptions[];
};

export function Navigation({ menuOptions }: Props) {
  const navigate = useNavigate();

  return (
    <NavigationWrapper>
      <LogoLink to={resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`)}>
        <EverBrightLogo />
      </LogoLink>

      <MenuItems>
        {menuOptions.map((item) => (
          <MenuButton
            key={item.label}
            label={item.label}
            Icon={item.icon}
            onClick={() => {
              navigate(item.link);
            }}
          />
        ))}
      </MenuItems>
    </NavigationWrapper>
  );
}

export default Navigation;
