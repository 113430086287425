import styled from 'styled-components';

export const TableHeaderStyled = styled.thead`
  height: 48px;
  text-align: left;
`;

export const HeaderRow = styled.tr`
  th {
    padding-bottom: 8px;
  }

  th:first-child {
    padding-left: 16px;
  }

  th:last-child {
    padding-right: 16px;
  }
`;

type HeaderCellInnerProps = {
  $hasCursor: boolean;
};

export const HeaderCell = styled.th<HeaderCellInnerProps>`
  font-family: Avenir;
  padding-left: 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
  cursor: ${({ $hasCursor }) => ($hasCursor ? 'pointer' : 'initial')};
`;

export const HeaderCellInner = styled.div`
  display: flex;
  align-items: center;
  min-width: 72px;

  & > svg {
    margin-left: 4px;
  }
`;
