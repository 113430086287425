import styled from 'styled-components';
import Text from 'shared/components/Text/Text';

export const ContentWrapper = styled.div`
  display: flex;
  gap: 4px;
  button {
    height: fit-content;
  }
`;

export const NameText = styled(Text)`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;
