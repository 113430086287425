import { TTheme } from 'shared/design-system/theme/theme';

import {
  DISABLED_CHECKBOX_STATE,
  ENABLED_CHECKBOX_STATE,
  ERROR_CHECKBOX_STATE,
  TCheckboxStatesKey,
  TCheckboxStyleVariantsKey,
} from './TCheckboxes';

export const getCheckboxState = (
  isDisabled: boolean,
  shouldShowError: boolean,
): TCheckboxStatesKey => {
  if (isDisabled) {
    return DISABLED_CHECKBOX_STATE;
  }
  return shouldShowError ? ERROR_CHECKBOX_STATE : ENABLED_CHECKBOX_STATE;
};

export const getCheckboxStyleVariantValues = (
  theme: TTheme,
  variant: TCheckboxStyleVariantsKey,
  state: TCheckboxStatesKey,
) => ({
  ...theme.checkboxes.variants[variant][state],
});
