import { breakpoints } from './breakpoints';
import { lightThemeColors, darkThemeColors } from './colors/themeColors';
import { TThemeColors } from './colors/TThemeColors';
import { darkThemeElevations, lightThemeElevations } from './elevations/themeElevations';
import { TThemeElevations } from './elevations/TThemeElevations';
import { grid } from './grid';
import { darkThemeInputs, lightThemeInputs, TInputs } from './inputs';
import { TDropdowns, darkThemeDropdowns, lightThemeDropdowns } from './dropdowns';
import { darkThemeCheckboxes, lightThemeCheckboxes, TCheckboxes } from './checkboxes';
import { lightThemeTables, TTables } from './tables';

export type TThemeName = 'lightTheme' | 'darkTheme';

export type TTheme = {
  name: TThemeName;
  breakpoints: typeof breakpoints;
  checkboxes: TCheckboxes;
  colors: TThemeColors;
  dropdowns: TDropdowns;
  elevations: TThemeElevations;
  grid: typeof grid;
  inputs: TInputs;
  tables: TTables;
};

const sharedThemeValues = {
  breakpoints,
  grid,
};

export const lightTheme: TTheme = {
  name: 'lightTheme',
  checkboxes: lightThemeCheckboxes,
  colors: lightThemeColors,
  dropdowns: lightThemeDropdowns,
  elevations: lightThemeElevations,
  inputs: lightThemeInputs,
  tables: lightThemeTables,
  ...sharedThemeValues,
} as const;

export const darkTheme: TTheme = {
  name: 'darkTheme',
  checkboxes: darkThemeCheckboxes,
  colors: darkThemeColors,
  dropdowns: darkThemeDropdowns,
  elevations: darkThemeElevations,
  inputs: darkThemeInputs,
  tables: lightThemeTables,
  ...sharedThemeValues,
} as const;
