import React from 'react';
import { useTheme } from 'styled-components';

import { AccountType } from 'api/types';
import { Button } from 'shared/components/Button/Button';
import { Label, StaticTextWrapper, Value } from 'modules/account/components/Form.styles';
import { AccountIcon } from 'shared/components/icons';
import { FoldersIcon } from 'shared/components/icons/FoldersIcon';
import {
  HeaderText,
  HeaderWrapper,
  IconWrapper,
  UserInfoCardWrapper,
} from '../UserInfoCard/UserInfoCard.styles';

type Props = {
  account: AccountType;
  onClickHomeownerTransfer: (account: AccountType) => void;
  onClickArchiveAccount: (account: AccountType) => void;
};

export function HomeownerTransferCard({
  account,
  onClickHomeownerTransfer,
  onClickArchiveAccount,
}: Props) {
  const theme = useTheme();

  const handleClickHomeownerTransfer = () => {
    onClickHomeownerTransfer(account);
  };

  const handleToggleArchiveAccount = () => {
    onClickArchiveAccount(account);
  };

  return (
    <UserInfoCardWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <AccountIcon color={theme.colors.iconIconPrimarySecondary1100} filled={false} />
        </IconWrapper>
        <HeaderText as="h3">{`Homeowner Account ${account.homeowner_account_id}`}</HeaderText>
      </HeaderWrapper>

      <StaticTextWrapper>
        <div>
          <Label as="p">Agreements</Label>
          <ul>
            {account.agreements.map((agreement) => (
              <li key={agreement.id}>
                <Value as="p">{agreement.homeowner_contract_id}</Value>
              </li>
            ))}
          </ul>
        </div>

        <Button
          dataTestId="transfer-account-btn"
          styleVariant="secondary"
          label="Transfer Account"
          onClick={handleClickHomeownerTransfer}
        />

        <Button
          dataTestId="archive-account-btn"
          styleVariant="tertiary"
          label={`${account.archived ? 'Unarchive' : 'Archive'} Account`}
          onClick={handleToggleArchiveAccount}
          Icon={FoldersIcon}
        />
      </StaticTextWrapper>
    </UserInfoCardWrapper>
  );
}

export default HomeownerTransferCard;
