/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { validateUuid } from 'admin/utils/validateUuid';
import { Button } from 'shared/components/Button/Button';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { StyledForm } from './BackfillWorkflowForm.styles';

export interface IFormInput {
  newWorkflows: string;
}

type Props = {
  onSubmit: (newWorkflows: string[]) => void;
};
export function BackfillWorkflowsForm({ onSubmit }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.backfill' });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  const addWorkflowIds = ({ newWorkflows }: IFormInput) => {
    const uuidsToAdd = newWorkflows.split(',').map((uuid) => uuid.trim());
    const validUuids: Set<string> = new Set();
    const invalidUUIDs: Set<string> = new Set();

    uuidsToAdd?.forEach((uuid) => {
      if (validateUuid(uuid)) {
        clearErrors('newWorkflows');
        validUuids.add(uuid);
      } else {
        invalidUUIDs.add(uuid);
      }
    });

    if (Array.from(invalidUUIDs).length) {
      setError('newWorkflows', {
        message: `Invalid UUIDs: ${Array.from(invalidUUIDs).join(', ')}`,
      });
    } else {
      onSubmit(Array.from(validUuids));
      reset();
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(addWorkflowIds)}>
      <TextInput
        ariaLabel="input workflow"
        label="Backfill Subset of Workflows"
        placeholder="workflow_id_1, workflow_id_2, workflow_id_3, ..."
        hasError={Boolean(errors.newWorkflows)}
        errorMessage={errors.newWorkflows?.message}
        {...register('newWorkflows', { required: t('required') })}
      />

      <Button type="submit" label="Add Workflow(s)" styleVariant="secondary" />
    </StyledForm>
  );
}

export default BackfillWorkflowsForm;
