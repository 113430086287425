import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Text = styled.span`
  text-align: center;
  margin: 0 32px 0 0;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const PaginationButton = styled.button<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'unset' : 'pointer')};
  height: 24px;
  padding: 0 10px;
  margin-right: 12px;
  background: transparent;
  border: 1.5px solid
    ${({ $disabled, theme }) =>
      $disabled ? 'transparent' : theme.colors.backgroundSystemDisabledGrey10};
  border-radius: 3px;
  color: ${({ $disabled, theme }) =>
    $disabled
      ? theme.colors.iconSystemIconDisabledGrey50
      : theme.colors.iconIconPrimarySecondary1100};
  font-weight: 400;
  font-size: 14px;
`;

export const NumberButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  font-weight: ${({ $active }) => ($active ? 800 : 400)};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.iconIconPrimarySecondary1100};
  background: transparent;
  border: none;
  margin-right: 12px;
`;
