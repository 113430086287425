import React from 'react';
import { useTheme } from 'styled-components';

import everbrightLight from 'static/everbright.light-mode.svg';
import everbrightDark from 'static/everbright.dark-mode.svg';

export function EverBrightLogo() {
  const theme = useTheme();

  return theme.name === 'darkTheme' ? (
    <img src={everbrightDark} alt="everbright logo" height={30.9} width="auto" />
  ) : (
    <img src={everbrightLight} alt="everbright logo" height={30.9} width="auto" />
  );
}

export default EverBrightLogo;
