import React, { useEffect, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { endOfWeek, startOfWeek } from 'date-fns';
import 'react-day-picker/dist/style.css';

import { Calendar } from './Calendar';

type Props = {
  currentDate: Date;
  selectedDate: Date;
  onDateChange: MonthChangeEventHandler;
  onCloseModal: () => void;
  dateRange: { oldest: Date; newest: Date };
  inServiceDate: Date;
};

const getWeekRange = (date: Date) => {
  const from = startOfWeek(date);
  const to = endOfWeek(from);

  return { from, to };
};

export function WeekPicker({
  currentDate,
  selectedDate,
  onDateChange,
  onCloseModal,
  dateRange,
  inServiceDate,
}: Props) {
  const [selected, setSelected] = useState(getWeekRange(selectedDate));

  useEffect(() => {
    setSelected(getWeekRange(selectedDate));
  }, [selectedDate]);

  const handleOk = () => {
    onDateChange(selected.from);
    onCloseModal();
  };

  const handleDayClick = (date: Date) => {
    setSelected(getWeekRange(date));
  };

  return (
    <Calendar
      mode="range"
      currentDate={currentDate}
      selectedDate={selected}
      onDayClick={handleDayClick}
      onCancel={onCloseModal}
      onOk={handleOk}
      dateRange={dateRange}
      inServiceDate={inServiceDate}
    />
  );
}

export default WeekPicker;
