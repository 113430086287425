import React, { useState, useEffect } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { Calendar } from './Calendar';

type Props = {
  currentDate: Date;
  selectedDate: Date;
  onDateChange: MonthChangeEventHandler;
  onCloseModal: () => void;
  dateRange: { oldest: Date; newest: Date };
  inServiceDate: Date;
};

export function DayPicker({
  currentDate,
  selectedDate,
  onDateChange,
  onCloseModal,
  dateRange,
  inServiceDate,
}: Props) {
  const [selected, setSelected] = useState(selectedDate);

  useEffect(() => {
    setSelected(selectedDate);
  }, [selectedDate]);

  const handleOk = () => {
    onDateChange(selected);
    onCloseModal();
  };

  const handleDayClick = (date: Date) => {
    setSelected(date);
  };

  return (
    <Calendar
      currentDate={currentDate}
      selectedDate={selected}
      onDayClick={handleDayClick}
      onCancel={onCloseModal}
      onOk={handleOk}
      dateRange={dateRange}
      inServiceDate={inServiceDate}
    />
  );
}

export default DayPicker;
