import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { FaqType, TagType } from 'api/types';
import { TagRequestBody } from 'api/faqs';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import { SelectInput } from 'shared/components/SelectInput/SelectInput';
import { CircleXIcon } from 'shared/components/icons';
import { TagContainer } from './AddTagModal.styles';
import { RemoveButton, Tag, Tags, TagText } from '../FaqCard/FaqCard.styles';

export type AddTagsProp = {
  faq: FaqType;
  newExistingTags: TagType[];
  newTags: string[];
};

type Props = {
  isOpen: boolean;
  faq?: FaqType;
  allTags: TagType[];
  onClickAddTags: (data: AddTagsProp) => void;
  onClickRemoveTag: (body: TagRequestBody) => void;
  onClose: () => void;
};

export function AddTagModal({
  faq,
  allTags,
  isOpen,
  onClickAddTags,
  onClickRemoveTag,
  onClose,
}: Props) {
  const [selectedExistingTags, setSelectedExistingTags] = useState<TagType[]>(faq?.tags || []);
  const [newTags, setNewTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation('translation', { keyPrefix: 'admin.faq' });
  const { t: tGeneral } = useTranslation();

  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  const options = useMemo(() => {
    let result = allTags.map((tag) => ({ title: tag.tag, value: tag.id }));

    // Filter out the tags that are already in selectedExistingTags
    result = result.filter(
      (option) => !selectedExistingTags.some((selectedTag) => selectedTag.id === option.value),
    );

    if (inputValue) {
      result = result.filter((option) =>
        option.title.toLowerCase().includes(inputValue.toLowerCase()),
      );
    }
    return result;
  }, [allTags, inputValue, selectedExistingTags]);

  if (!faq) {
    return null;
  }

  const ctaButtons: CtaButtons = {
    tertiary: {
      label: tGeneral('cta.cancel'),
      onClick: handleClose,
    },
    primary: {
      label: t('addTags'),
      onClick: () =>
        onClickAddTags({
          faq,
          newExistingTags: selectedExistingTags,
          newTags,
        }),
    },
  };

  const handleSelectTagOption = (tagId: string) => {
    setSelectedExistingTags((prevTags) => {
      const uniqueTags = new Set([...prevTags]);
      const newTag = allTags.find((tag) => tagId === tag.id);
      if (newTag) {
        uniqueTags.add(newTag);
      }
      return Array.from(uniqueTags);
    });

    setInputValue('');
  };

  const handleSelectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const { value } = e.currentTarget;

      const formattedValue = value.trim().toLowerCase().split(' ').join('_');

      setNewTags((prevTags) => {
        const uniqueTags = new Set([...prevTags, formattedValue]);
        return Array.from(uniqueTags);
      });

      setInputValue('');
    }
  };

  const handleClickRemoveTag = (tagToRemove: TagType) => {
    if (tagToRemove.id === 'newTag') {
      setNewTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove.tag));
    } else {
      onClickRemoveTag({ faqId: faq.id, tagId: tagToRemove.id });
      setSelectedExistingTags((prevTags) => prevTags.filter((tag) => tag.id !== tagToRemove.id));
    }
  };

  const selectedTags = selectedExistingTags.concat(newTags.map((tag) => ({ id: 'newTag', tag })));

  return (
    <Modal
      isOpen={isOpen}
      isFullWidth
      fitContent
      title={t('addTag')}
      styleVariant="tertiary"
      contentLabel="Add Tag Modal"
      ctaButtons={ctaButtons}
      ctaSpacedBetween
      onRequestClose={handleClose}
    >
      <TagContainer>
        <SelectInput
          options={options}
          inputValue={inputValue}
          onChange={handleSelectInputChange}
          onKeyDown={handleKeyDown}
          onSelectOption={handleSelectTagOption}
        />

        <Tags>
          {selectedTags.map((tag) => (
            <Tag key={tag.id} $isHidden={false}>
              <TagText as="p" $isHidden={false}>
                {tag.tag}
              </TagText>
              <RemoveButton $isHidden={false} onClick={() => handleClickRemoveTag(tag)}>
                <CircleXIcon color={theme.colors.textLinkLinkVisitedFunction4100} />
              </RemoveButton>
            </Tag>
          ))}
        </Tags>
      </TagContainer>
    </Modal>
  );
}

export default AddTagModal;
