import React from 'react';

import AdminTableView from 'legacy/views/admin/AdminTableView';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Admins as AdminsV2 } from 'admin/modules/admins/screens/Admins';
import { FlagName } from 'api/types';

export function Admins() {
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  return adminV2 ? <AdminsV2 /> : <AdminTableView />;
}

export default Admins;
