import React from 'react';

import { TTableStyleVariantsKey } from 'shared/design-system/theme/tables';
import { TableStyled, TbodyStyled } from './Table.styles';
import { TableHeader } from './TableHeader/TableHeader';
import { TableRow } from './TableRow/TableRow';
import { TTableColumn, TTableRow } from './TTable';

export type TTableProps = {
  columns: TTableColumn[];
  rows?: TTableRow[];
  isFullWidth?: boolean;
  styleVariant?: TTableStyleVariantsKey;
};

/**
 * @param {TTableColumn[]} [columns] - data for th tags
 * @param {TTableRow[]} [rows] - data for tr tags
 * @param {boolean} isFullWidth - defaults to true
 * @param {styleVariant} [TTableStyleVariantsKey] - defaults to primary style (optional)
 */

export function Table({
  columns,
  rows,
  isFullWidth = true,
  styleVariant = 'default',
}: TTableProps) {
  return (
    <TableStyled $isFullWidth={isFullWidth} $styleVariant={styleVariant}>
      <TableHeader tableHeaderData={columns} />
      <TbodyStyled>{rows?.map((row) => <TableRow key={row.key} tableRowData={row} />)}</TbodyStyled>
    </TableStyled>
  );
}

export default Table;
