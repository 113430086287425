import styled from 'styled-components';
import { Text } from 'shared/components/Text/Text';

export const BodyWrapper = styled.div`
  max-height: 444px;
`;

export const BodyText = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const BodyBoldText = styled(BodyText)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const OrderedList = styled.ol`
  margin-top: 4px;
  padding-left: 18px;
`;
