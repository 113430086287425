import React from 'react';
import {
  TextAnchorType,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { DateRange } from 'api/system/utils/DateRange';
import { useTheme } from 'styled-components';
import { AxisType, ChartDataType } from 'modules/types';
import { useBreakpoints } from 'hooks/useWindowSize';
import { getDayChartStyles } from '../getChartStyles';
import { ChartLinearGradient } from '../ChartLinearGradient';
import { ToolTip } from '../Tooltip/ToolTip';
import { GridComponent } from '../GridComponent';

type Props = {
  data: ChartDataType[];
  axes?: { x: AxisType; y: AxisType };
  tooltipDateFormatter: (date: Date, dateRange: DateRange) => string;
  dateRange: DateRange;
  yAxisUnit: string;
};

export function AreaChart({ data, axes, tooltipDateFormatter, dateRange, yAxisUnit }: Props) {
  const theme = useTheme();
  const screenSize = useBreakpoints();
  const yTickLabelOffset = {
    x: screenSize === 'lg' || screenSize === 'xl' ? -20 : -3,
    y: screenSize === 'lg' || screenSize === 'xl' ? 0 : 15,
  };

  const chartStyles = getDayChartStyles(theme, screenSize);

  return (
    <VictoryChart
      singleQuadrantDomainPadding={false}
      domainPadding={chartStyles.domainPadding}
      padding={chartStyles.padding}
      height={chartStyles.height}
      width={chartStyles.width}
      name="chart"
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          voronoiBlacklist={['area', 'chart']}
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={
                <ToolTip
                  dateRange={dateRange}
                  tooltipDateFormatter={tooltipDateFormatter}
                  chartType="area"
                />
              }
            />
          }
        />
      }
    >
      <VictoryAxis
        dependentAxis
        tickFormat={axes?.y.tickFormat}
        style={{
          grid: { strokeWidth: 0.5, stroke: theme.colors.chartChartLightblueSecondary4100 },
          axis: { stroke: 'transparent' },
          tickLabels: chartStyles.tickLabels,
        }}
        tickValues={axes?.y.tickValues}
        tickLabelComponent={
          <VictoryLabel dy={yTickLabelOffset.y} dx={yTickLabelOffset.x} textAnchor="start" />
        }
        gridComponent={<GridComponent />}
        standalone={false}
      />
      <VictoryAxis
        name="xAxis"
        tickValues={axes?.x.tickValues}
        domain={axes?.x.domain}
        tickFormat={axes?.x.tickFormat}
        style={{
          tickLabels: { ...chartStyles.tickLabels, padding: 10 },
          axis: { stroke: theme.colors.strokeAccentGreyStrongerGrey50 },
        }}
      />
      <ChartLinearGradient />
      <VictoryArea
        style={{ data: { fill: 'url(#myGradient)' } }}
        data={data}
        interpolation="natural"
        name="area"
      />
      <VictoryLine
        name="line"
        style={{
          data: {
            stroke: theme.colors.strokeInteractionActiveSecondary2100,
            strokeWidth: 1.0,
          },
        }}
        data={data}
        interpolation="natural"
      />
      <VictoryLabel
        x={chartStyles.label.x}
        y={chartStyles.label.y}
        textAnchor={chartStyles.label.textAnchor as TextAnchorType}
        text={yAxisUnit}
        style={{
          ...chartStyles.label.style,
          fontWeight: chartStyles.unitLabel.fontWeight,
          paddingBottom: chartStyles.unitLabel.paddingBottom,
        }}
      />
    </VictoryChart>
  );
}

export default AreaChart;
