/* eslint-disable class-methods-use-this */
import { DateRange } from 'api/system/utils/DateRange';
import { MonitoringDatum, RawMonitoringDatumType } from 'api/types';
import { ChartDataType } from 'modules/types';
import { isWithinInterval, subMinutes } from 'date-fns';
import { getSystemTimezoneConversion } from './getSystemTimezoneConversion';

export class ChartDataProvider {
  relativeSystemTimeOffset: number;

  constructor(systemTimezone?: string) {
    this.relativeSystemTimeOffset = systemTimezone
      ? getSystemTimezoneConversion(systemTimezone)
      : 0;
  }

  parseDates<T>(rawData: RawMonitoringDatumType<T>[]) {
    return rawData?.map(({ value, timestamp, unit }) => ({
      value,
      time: new Date(timestamp),
      unit,
    }));
  }

  /** Filters requested data to only include information from the requested times */
  filterData<T>(monitoringData: MonitoringDatum<T>[], dateRange: DateRange): MonitoringDatum<T>[] {
    const { startDate, endDate } = dateRange;
    return monitoringData.filter((entry) =>
      isWithinInterval(entry.time, { start: subMinutes(startDate, 1), end: endDate }),
    );
  }

  aggregateData<T>(
    _: MonitoringDatum<T>[],
    __: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    throw new Error('Method not implemented.');
  }

  prepareData<T>(
    data: RawMonitoringDatumType<T>[],
    dateRange: DateRange,
  ): [ChartDataType[], number | null, T | null] {
    const datesAdded = this.parseDates(data);
    const [dailyProduction, largestProductionValue, largestProductionUnit] = this.aggregateData<T>(
      datesAdded,
      dateRange,
    );
    const productionData: ChartDataType[] = [];
    dailyProduction.forEach((value: MonitoringDatum<T>, key: number) => {
      productionData.push({
        x: key,
        y: value.value,
        unit: value.unit as string,
        value: value.value,
        time: value.time,
      });
    });
    return [productionData, largestProductionValue, largestProductionUnit];
  }
}

export default ChartDataProvider;
