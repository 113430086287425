import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function AgreementsIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0C0.896875 0 0 0.896875 0 2V14C0 15.1031 0.896875 16 2 16H10C11.1031 16 12 15.1031 12 14V5H8C7.44688 5 7 4.55312 7 4V0H2ZM8 0V4H12L8 0ZM2.5 2H4.5C4.775 2 5 2.225 5 2.5C5 2.775 4.775 3 4.5 3H2.5C2.225 3 2 2.775 2 2.5C2 2.225 2.225 2 2.5 2ZM2.5 4H4.5C4.775 4 5 4.225 5 4.5C5 4.775 4.775 5 4.5 5H2.5C2.225 5 2 4.775 2 4.5C2 4.225 2.225 4 2.5 4ZM4.19375 11.9312C4.00312 12.5656 3.41875 13 2.75625 13H2.5C2.225 13 2 12.775 2 12.5C2 12.225 2.225 12 2.5 12H2.75625C2.97812 12 3.17188 11.8562 3.23438 11.6438L3.7 10.0969C3.80625 9.74375 4.13125 9.5 4.5 9.5C4.86875 9.5 5.19375 9.74063 5.3 10.0969L5.6625 11.3031C5.89375 11.1094 6.1875 11 6.5 11C6.99687 11 7.45 11.2812 7.67188 11.725L7.80937 12H9.5C9.775 12 10 12.225 10 12.5C10 12.775 9.775 13 9.5 13H7.5C7.30937 13 7.1375 12.8938 7.05312 12.725L6.77812 12.1719C6.725 12.0656 6.61875 12 6.50313 12C6.3875 12 6.27813 12.0656 6.22813 12.1719L5.95312 12.725C5.8625 12.9094 5.66563 13.0188 5.4625 13C5.25938 12.9812 5.08437 12.8406 5.02812 12.6469L4.5 10.9062L4.19375 11.9312Z"
        fill={color}
      />
    </svg>
  );
}

export default AgreementsIcon;
