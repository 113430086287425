import { lightThemeColors as colors } from '../colors/themeColors';
import { TCheckboxes } from './TCheckboxes';

export const lightThemeCheckboxes: TCheckboxes = {
  variants: {
    default: {
      enabled: {
        // default
        checkboxBackgroundUnchecked: colors.buttonButtonInverseWhite,
        checkboxBackgroundChecked: colors.buttonButtonPrimarySecondary2100,
        borderUnchecked: `1px solid ${colors.chartChartBluePressedFunction41002}`,
        borderChecked: 'none',
        checkColor: colors.buttonButtonInverseWhite,
        hintFontColor: colors.textSecondarySubtleGrey80,
        labelFontColor: colors.textPrimarySecondary1100,

        // hover
        checkboxBackgroundUncheckedHover: colors.backgroundInteractionHoverGrey10,
        checkboxBackgroundCheckedHover: colors.buttonButtonPrimaryHoverFunction4100,
        borderUncheckedHover: `1px solid ${colors.chartChartBluePressedFunction41002}`,
        borderCheckedHover: 'none',
        checkColorHover: colors.buttonButtonInverseWhite,
        hintFontColorHover: colors.textSecondarySubtleGrey80,
        labelFontColorHover: colors.textPrimarySecondary1100,

        // active
        checkboxBackgroundUncheckedActive: colors.backgroundInteractionPressedSecondary4100,
        checkboxBackgroundCheckedActive: colors.buttonButtonPrimaryPressedSecondary1100,
        borderUncheckedActive: `1px solid ${colors.strokeInteractionActiveSecondary2100}`,
        borderCheckedActive: 'none',
        checkColorActive: colors.buttonButtonInverseWhite,
        hintFontColorActive: colors.textSecondarySubtleGrey80,
        labelFontColorActive: colors.textPrimarySecondary1100,

        borderRadius: '4px',
      },
      error: {
        // default
        checkboxBackgroundUnchecked: colors.buttonButtonInverseWhite,
        checkboxBackgroundChecked: colors.buttonSystemButtonErrorFunction1100,
        borderUnchecked: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        borderChecked: 'none',
        checkColor: colors.buttonButtonInverseWhite,
        hintFontColor: colors.textSystemErrorFunction1100,
        labelFontColor: colors.textPrimarySecondary1100,

        // hover
        checkboxBackgroundUncheckedHover: colors.buttonButtonInverseWhite,
        checkboxBackgroundCheckedHover: colors.buttonSystemButtonErrorFunction1100,
        borderUncheckedHover: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        borderCheckedHover: 'none',
        checkColorHover: colors.buttonButtonInverseWhite,
        hintFontColorHover: colors.textSystemErrorFunction1100,
        labelFontColorHover: colors.textPrimarySecondary1100,

        // active
        checkboxBackgroundUncheckedActive: colors.buttonButtonInverseWhite,
        checkboxBackgroundCheckedActive: colors.buttonSystemButtonErrorFunction1100,
        borderUncheckedActive: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        borderCheckedActive: 'none',
        checkColorActive: colors.buttonButtonInverseWhite,
        hintFontColorActive: colors.textSystemErrorFunction1100,
        labelFontColorActive: colors.textPrimarySecondary1100,

        borderRadius: '4px',
      },

      disabled: {
        // default
        checkboxBackgroundUnchecked: colors.buttonButtonInverseWhite,
        checkboxBackgroundChecked: colors.iconSystemIconDisabledGrey50,
        borderUnchecked: `1px solid ${colors.strokeAccentGreyStrongerGrey50}`,
        borderChecked: 'none',
        checkColor: colors.buttonButtonInverseWhite,
        hintFontColor: colors.textSecondarySubtleGrey80,
        labelFontColor: colors.textPrimarySecondary1100,

        // hover
        checkboxBackgroundUncheckedHover: colors.buttonButtonInverseWhite,
        checkboxBackgroundCheckedHover: colors.iconSystemIconDisabledGrey50,
        borderUncheckedHover: `1px solid ${colors.strokeAccentGreyStrongerGrey50}`,
        borderCheckedHover: 'none',
        checkColorHover: colors.buttonButtonInverseWhite,
        hintFontColorHover: colors.textSecondarySubtleGrey80,
        labelFontColorHover: colors.textPrimarySecondary1100,

        // active
        checkboxBackgroundUncheckedActive: colors.buttonButtonInverseWhite,
        checkboxBackgroundCheckedActive: colors.iconSystemIconDisabledGrey50,
        borderUncheckedActive: `1px solid ${colors.strokeAccentGreyStrongerGrey50}`,
        borderCheckedActive: 'none',
        checkColorActive: colors.buttonButtonInverseWhite,
        hintFontColorActive: colors.textSecondarySubtleGrey80,
        labelFontColorActive: colors.textPrimarySecondary1100,

        borderRadius: '4px',
      },
    },
  },
};

export default lightThemeCheckboxes;
