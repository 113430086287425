import { TTables } from './TTables';

export const lightThemeTables: TTables = {
  variants: {
    default: {
      table: {},
      header: {},
      cell: {},
      row: {},
    },
  },
};

export default lightThemeTables;
