/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import parsePhoneNumberFromString, { E164Number } from 'libphonenumber-js';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/components/Button/Button';
import { ButtonWrapper, Footer } from 'shared/components/Modal/Modal.styles';
import PhoneInput from 'shared/components/PhoneInput/PhoneInput';
import TextInput from 'shared/components/TextInput/TextInput';
import { StyledForm } from './UserFormBody.styles';

export interface IFormInput {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: E164Number;
}

type Props = {
  initialValues?: IFormInput;
  onBack: () => void;
  onSubmit: (formData: IFormInput) => void;
};

export function UserFormModalBody({ initialValues, onBack, onSubmit }: Props) {
  const [isPhoneValid, setIsPhoneValid] = useState(Boolean(initialValues?.phone_number));

  const { t } = useTranslation('translation', { keyPrefix: 'admin.homeownerTransfer' });
  const { t: tGeneral } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: initialValues,
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="contact-form-firstName"
        ariaLabel={t('firstName')}
        label={t('firstName')}
        hasError={Boolean(errors.first_name)}
        errorMessage={errors.first_name?.message}
        {...register('first_name', { required: t('required') })}
      />
      <TextInput
        id="contact-form-lastName"
        ariaLabel={t('lastName')}
        label={t('lastName')}
        hasError={Boolean(errors.last_name)}
        errorMessage={errors.last_name?.message}
        {...register('last_name', { required: t('required') })}
      />
      <TextInput
        id="contact-form-email"
        ariaLabel={tGeneral('account.email')}
        label={tGeneral('account.email')}
        hasError={Boolean(errors.email)}
        errorMessage={errors.email?.message}
        {...register('email', { required: t('required') })}
      />
      <PhoneInput
        id="contact-form-phone"
        ariaLabel={tGeneral('account.phoneNumber')}
        label={tGeneral('account.phoneNumber')}
        onValidate={setIsPhoneValid}
        defaultValue={
          initialValues?.phone_number
            ? parsePhoneNumberFromString(initialValues.phone_number, 'US')?.formatNational()
            : undefined
        }
        hasError={Boolean(errors.phone_number)}
        errorMessage={errors.phone_number?.message}
        margin="0 0 4px 0"
        {...register('phone_number', {
          validate: () => {
            if (!isPhoneValid) {
              return tGeneral('account.invalidPhoneNumber');
            }
            return true;
          },
        })}
      />

      <Footer $ctaSpacedBetween>
        <ButtonWrapper>
          <Button label={tGeneral('cta.back')} styleVariant="secondary" onClick={onBack} />
          <Button type="submit" label={tGeneral('cta.next')} />
        </ButtonWrapper>
      </Footer>
    </StyledForm>
  );
}

export default UserFormModalBody;
