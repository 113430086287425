import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';

import { pages } from 'pages';
import { useUser } from 'hooks/useUser';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorButtonStyled, FallbackBody, FallbackWrapper } from './ErrorBoundary.styles';
import { ErrorLaptopImg } from '../icons';

const logErrorToDatadog = (error: Error, info: React.ErrorInfo) => {
  datadogLogs.logger.error('ErrorBoundary caught an error', {
    error,
    componentStack: info.componentStack || 'No component stack available',
  });
};

export function ErrorFallback({ is404 }: { is404?: boolean }) {
  const { t } = useTranslation();
  const { userResult } = useUser();

  const backToLink = userResult?.user
    ? `/${pages.DASHBOARD}`
    : `${window.location.host}/${pages.LOGIN}`;

  const handleGoBack = () => {
    window.history.replaceState(null, '', backToLink);
    window.history.go(0);
  };

  const handleButtonAction = () => (is404 === true ? handleGoBack() : window.history.go(0));

  return (
    <FallbackWrapper>
      <FallbackBody>
        <h1>{is404 === true ? t('error.notFound.header') : t('error.generic.header')}</h1>
        <p>{is404 === true ? t('error.notFound.description') : t('error.generic.description')}</p>
        <ErrorButtonStyled
          $maxWidth={is404 === true ? '122px' : '148px'}
          onClick={handleButtonAction}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') handleButtonAction();
          }}
        >
          <span>{is404 === true ? t('error.notFound.button') : t('error.generic.button')}</span>
        </ErrorButtonStyled>
      </FallbackBody>
      <ErrorLaptopImg />
    </FallbackWrapper>
  );
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={() => ErrorFallback({})} onError={logErrorToDatadog}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
