import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyText } from '../AuthCopyAckModal.styles';

export function ConfirmedBody() {
  const { t } = useTranslation();

  return (
    <>
      <BodyText as="p" $margin="24px 0 8px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeConfirmed.body1')}
      </BodyText>
      <BodyText as="p" $margin="8px 0">
        {t('acknowledgeAuthoritativeCopy.acknowledgeConfirmed.body2')}
      </BodyText>
    </>
  );
}

export default ConfirmedBody;
