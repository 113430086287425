import styled from 'styled-components';

import { FONT_FAMILY, Text } from '../Text/Text';
import { Divider } from '../Divider/Divider';

export const ScreenShareWrapper = styled.label`
  ${({ theme }) => `
color: ${theme.colors.buttonButtonPrimarySecondary2100}}

`}
  :is(label) {
    font-feature-settings:
      'liga' off,
      'clig' off;
    flex: 1 0 0;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    width: 125px;
    line-height: 24px; /* 150% */
  }
`;

export const ScreenCastWrapper = styled.a`
  display: flex;
  height: 44px;
  padding: 10px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  img {
    align-self: flex-start;
    margin-left: 16px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    justify-content: center;
    gap: 24px;
    img {
      align-self: center;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
    img {
      align-self: flex-start;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    justify-content: space-between;
    gap: 0;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0 31px 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    justify-content: center;
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 18px;
  }
`;

export const MenuLink = styled.a`
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};

  &:hover {
    color: ${({ theme }) => theme.colors.textLinkLinkSecondary2100};
  }

  &:active {
    color: ${({ theme }) => theme.colors.textLinkLinkVisitedFunction4100};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.textLinkLinkSecondary2100};
    outline: 3px solid ${({ theme }) => theme.colors.chartChartLightbluePressedSecondary240};
    border-radius: 5%;
  }

  &:visited {
    color: ${({ theme }) => theme.colors.textSecondaryGrey90};
  }
`;

export const StoreButtons = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    justify-content: center;
    gap: 12px;
    a {
      height: 34px;
      width: 116px;
      svg {
        height: 34px;
        width: 116px;
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      gap: 9px;
    }
  }
`;

export const CopyRightText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.textSecondarySubtleGrey80};
    font-size: 12px;
    font-weight: 400;
    font-family: ${FONT_FAMILY};
    text-align: left;
    margin-top: 24px;
    padding-bottom: 80px;

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      text-align: center;
      padding-bottom: 80px;
    }

    @media screen and (max-width: ${theme.breakpoints.small}) {
      margin-top: 32px;
    }
  `}
`;

export const DividerStyled = styled(Divider)`
  margin: 32px 0;
  background-color: ${({ theme }) => theme.colors.componentsLowEmphasis};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 34px 0;
  }
`;
