import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function PenToSquareIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2314_3270)">
        <path
          d="M12.485 1.44922L12.8184 1.78264C13.0774 2.04165 13.0774 2.46049 12.8184 2.71675L12.0166 3.52136L10.7463 2.25107L11.5481 1.44922C11.8071 1.19021 12.226 1.19021 12.4822 1.44922H12.485ZM6.11429 6.88582L9.81217 3.18519L11.0825 4.45547L7.38182 8.15335C7.30191 8.23326 7.20271 8.29113 7.09525 8.32144L5.48328 8.7816L5.94345 7.16964C5.97376 7.06217 6.03162 6.96298 6.11153 6.88307L6.11429 6.88582ZM10.614 0.515109L5.17742 5.94895C4.93769 6.18868 4.76409 6.48352 4.67316 6.80591L3.88509 9.56141C3.81896 9.79287 3.88233 10.0409 4.05318 10.2117C4.22402 10.3825 4.47201 10.4459 4.70347 10.3798L7.45897 9.59172C7.78412 9.49803 8.07896 9.32444 8.31593 9.08747L13.7525 3.65362C14.5268 2.87933 14.5268 1.62282 13.7525 0.848524L13.4191 0.515109C12.6448 -0.259187 11.3883 -0.259187 10.614 0.515109ZM2.75809 1.58975C1.41892 1.58975 0.333252 2.67542 0.333252 4.01459V11.5095C0.333252 12.8487 1.41892 13.9344 2.75809 13.9344H10.253C11.5922 13.9344 12.6779 12.8487 12.6779 11.5095V8.42339C12.6779 8.05691 12.383 7.76207 12.0166 7.76207C11.6501 7.76207 11.3552 8.05691 11.3552 8.42339V11.5095C11.3552 12.1185 10.862 12.6117 10.253 12.6117H2.75809C2.14913 12.6117 1.65589 12.1185 1.65589 11.5095V4.01459C1.65589 3.40563 2.14913 2.91239 2.75809 2.91239H5.84425C6.21073 2.91239 6.50557 2.61755 6.50557 2.25107C6.50557 1.88459 6.21073 1.58975 5.84425 1.58975H2.75809Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_3270">
          <rect width="14" height="14" fill="white" transform="translate(0.333252)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default {};
