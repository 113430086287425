import { addDays, addMinutes, addMonths, addWeeks, addYears, subHours } from 'date-fns';
import { DateRangeZoomLevel } from 'legacy/types';

export class DateRange {
  startDate: Date;

  endDate: Date;

  zoomLevel: DateRangeZoomLevel;

  constructor(startDate: Date, zoomLevel: DateRangeZoomLevel) {
    this.startDate = startDate;
    this.zoomLevel = zoomLevel;

    /* 
      Date ranges are set for their corresponding charts. Start dates will always be set
      at the beginning of the day/month/year, and end dates are set keeping in mind that
      our endpoints are exclusive, so days get a minute added to include 12:00 AM and 
      weeks/months/years have an hour subtracted so they don't include an extra day (for weeks)
      or month (for years).
    */
    switch (zoomLevel) {
      case DateRangeZoomLevel.DAY:
        this.endDate = addMinutes(addDays(startDate, 1), 1);
        break;
      case DateRangeZoomLevel.WEEK:
        this.endDate = subHours(addWeeks(startDate, 1), 1);
        break;
      case DateRangeZoomLevel.MONTH:
        this.endDate = subHours(addMonths(startDate, 1), 1);
        break;
      case DateRangeZoomLevel.YEAR:
        this.endDate = subHours(addYears(startDate, 1), 1);
        break;
      default:
        throw new Error(`Unsupported zoom level: ${zoomLevel}`);
    }
  }
}

export default DateRange;
