import React from 'react';
import { RouterProvider } from 'react-router-dom';

import 'legacy/AppDependentRoutes'; // TODO: remove after admin reskin is merged
import GlobalStyles from 'GlobalStyles';
import { router } from 'routes/router';

export function App() {
  return (
    <>
      <GlobalStyles />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
