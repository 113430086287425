import React from 'react';

import { FlexContainer, Label, Title, Value } from './AgreementsAccordion.styles';

type Props = {
  title: string;
  items: { label: string; value: string | undefined }[];
};
export function Section({ title, items }: Props) {
  return (
    <FlexContainer>
      <Title as="h3">{title}</Title>
      <FlexContainer>
        {items.map((item) => (
          <div key={item.label}>
            <Label as="p">{item.label}</Label>
            <Value as="p">{item.value}</Value>
          </div>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
}

export default Section;
