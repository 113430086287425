/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { resolvePath } from 'react-router';
import PasswordStrengthBar from 'react-password-strength-bar';
import { AxiosError } from 'axios';

import DataDogLogs from 'shared/logging/DataDogLogs';
import { UserType } from 'api/types';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import { handlePasswordValidation } from 'modules/auth/Auth.utils';
import { Button } from 'shared/components/Button/Button';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { changePassword } from 'api/account';
import { toast } from 'shared/components/Toast/Toast';
import pages from 'pages';
import { getScoreWords } from 'modules/auth/components/PasswordForm/getScoreWords';
import {
  BodyText,
  ButtonGroup,
  Form,
  FormWrapper,
  Header,
  Label,
  LinkStyled,
  StaticTextWrapper,
  Value,
} from '../Form.styles';

export interface IFormInput {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type Props = {
  user: UserType;
  onCancel: () => void;
  onSave: () => void;
};

export function LoginDetailsForm({ user, onCancel, onSave }: Props) {
  const [passwordScore, setPasswordScore] = useState(0);

  const { t } = useTranslation();
  const theme = useTheme();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();

  const watchNewPassword = useWatch({
    control,
    name: 'newPassword',
  });

  const { mutate } = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('account.successfullyUpdated'),
        theme,
      });
      reset();
      onSave();
    },
    onError: (error: AxiosError | Error) => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('account.unableToSave'),
        theme,
      });

      DataDogLogs.error(
        'Account - LoginDetailsForm',
        {
          user,
        },
        error,
      );
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ oldPassword, newPassword }) => {
    mutate({ old_password: oldPassword, new_password: newPassword });
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const scoreWords = getScoreWords(t);

  return (
    <FormWrapper>
      <Header as="h3">{t('account.loginDetails')}</Header>

      <StaticTextWrapper>
        <BodyText as="p">
          <Trans
            i18nKey="account.contactSupport2"
            components={{
              a: <LinkStyled to={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`)} />,
            }}
          />
        </BodyText>

        <div>
          <Label as="p">{t('account.email')}</Label>
          <Value as="p">{user.email}</Value>
        </div>
      </StaticTextWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id="login-form-old-password"
          ariaLabel={t('account.oldPassword')}
          type="password"
          label={t('account.oldPassword')}
          placeholder=" "
          hasError={Boolean(errors.oldPassword)}
          errorMessage={errors.oldPassword?.message}
          margin="16px 0 0 0"
          {...register('oldPassword', { required: t('account.passwordRequired') })}
        />

        <div>
          <TextInput
            id="login-form-new-password"
            ariaLabel={t('account.newPassword')}
            type="password"
            label={t('account.newPassword')}
            placeholder=" "
            hasError={Boolean(errors.newPassword)}
            errorMessage={errors.newPassword?.message}
            {...register('newPassword', {
              validate: (value) =>
                handlePasswordValidation({
                  value,
                  newPassword: watchNewPassword,
                  passwordScore,
                  translator: t,
                  isNewPasswordField: true,
                }),
            })}
          />

          <PasswordStrengthBar
            password={watchNewPassword}
            minLength={MINIMUM_PASSWORD_LENGTH}
            shortScoreWord={watchNewPassword ? t('passwordStrength.tooWeak') : ''}
            scoreWords={scoreWords}
            onChangeScore={(score) => {
              setPasswordScore(score);
            }}
          />
        </div>

        <TextInput
          id="login-form-confirm-password"
          ariaLabel={t('account.confirmPassword')}
          label={t('account.confirmPassword')}
          type="password"
          placeholder=" "
          hasError={Boolean(errors.confirmPassword)}
          errorMessage={errors.confirmPassword?.message}
          {...register('confirmPassword', {
            validate: (value) =>
              handlePasswordValidation({
                value,
                newPassword: watchNewPassword,
                passwordScore,
                translator: t,
                isConfirmPasswordField: true,
              }),
          })}
        />

        <ButtonGroup>
          <Button
            type="submit"
            dataTestId={`${t('cta.save')}-btn`}
            styleVariant="secondary"
            label={t('cta.save')}
          />
          <Button
            dataTestId={`${t('cta.cancel')}-btn`}
            styleVariant="tertiary"
            label={t('cta.cancel')}
            onClick={handleCancel}
          />
        </ButtonGroup>
      </Form>
    </FormWrapper>
  );
}

export default LoginDetailsForm;
