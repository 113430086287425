import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 20px;
  min-height: 352px;
`;

export default {};
