import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function StatisticsIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2553_1569)">
        <path
          d="M8.57143 1.71429V14.2857H7.42857V1.71429H8.57143ZM7.42857 0C6.48214 0 5.71429 0.767857 5.71429 1.71429V14.2857C5.71429 15.2321 6.48214 16 7.42857 16H8.57143C9.51786 16 10.2857 15.2321 10.2857 14.2857V1.71429C10.2857 0.767857 9.51786 0 8.57143 0H7.42857ZM2.85714 8.57143V14.2857H1.71429V8.57143H2.85714ZM1.71429 6.85714C0.767857 6.85714 0 7.625 0 8.57143V14.2857C0 15.2321 0.767857 16 1.71429 16H2.85714C3.80357 16 4.57143 15.2321 4.57143 14.2857V8.57143C4.57143 7.625 3.80357 6.85714 2.85714 6.85714H1.71429ZM13.1429 4H14.2857V14.2857H13.1429V4ZM11.4286 4V14.2857C11.4286 15.2321 12.1964 16 13.1429 16H14.2857C15.2321 16 16 15.2321 16 14.2857V4C16 3.05357 15.2321 2.28571 14.2857 2.28571H13.1429C12.1964 2.28571 11.4286 3.05357 11.4286 4Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_1569">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StatisticsIcon;
