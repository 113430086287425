import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 405px;
`;

export const StateZipGroup = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 4px;
  div {
    flex: 1;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
