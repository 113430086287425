import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Trans } from 'react-i18next';
import { perSessionBannersKey } from 'constants/localStorageKeys';
import {
  BannerButton,
  BannerContent,
  BannerStyled,
  BannerText,
  IconWrapper,
  LinkStyled,
} from './Banner.styles';
import { initializeBannerVisibility } from './utils/initializeBannerVisibility';
import { CloseIcon } from '../icons';
import { CircleInfoIcon } from '../icons/CircleInfoIcon';
import { Button } from '../Button/Button';

interface BannerProps {
  bannerText: string;
  bgColor?: string;
  link?: { text?: string; url?: string; onClick?: () => void };
  bannerKey?: string;
  icon?: JSX.Element;
  dismissable?: boolean;
  fitContent?: boolean;
}

/**
 * @param {string} [bannerText] - Main body text of banner
 * @param {Color} [bgColor] - Background color of banner
 * @param {{text: string; url: string}} [link] - Clickable link (optional)
 * @param {string} [bannerKey] - Optional banner key used for handling hiding/showing on a per session basis. Should be unique across all <Banner /> components used in app. Omit to handle hiding/showing with just react state. (optional)
 * @param {JSX.Element} [icon] - Icon preceeding text content
 * @param {boolean} [dismissable] - Can the banner be dismissed (optional)
 */
export function Banner({
  bannerText,
  link,
  bannerKey,
  bgColor,
  icon: iconProp,
  dismissable = true,
  fitContent = false,
}: BannerProps) {
  const [bannerVisible, setBannerVisible] = useState(true);
  const { colors } = useTheme();

  const icon = iconProp || (
    <CircleInfoIcon color={colors.iconIconPrimarySecondary1100} size="large" />
  );

  useEffect(() => {
    const visibility = initializeBannerVisibility(bannerKey);
    setBannerVisible(visibility);
  }, [bannerKey]);

  const handleClose = () => {
    const perSessionBannersRaw = localStorage.getItem(perSessionBannersKey);
    if (perSessionBannersRaw != null && bannerKey) {
      const bannersObj = JSON.parse(perSessionBannersRaw);
      bannersObj[bannerKey] = false;
      localStorage.setItem(perSessionBannersKey, JSON.stringify(bannersObj));
    }
    setBannerVisible(false);
  };

  let action: React.ReactElement | null = null;

  if (link?.text) {
    if (link.url) {
      action = (
        <LinkStyled href={link.url} target="_blank" rel="noreferrer">
          {link.text}
        </LinkStyled>
      );
    }

    if (link.onClick) {
      action = <Button styleVariant="tertiary" label={link.text} onClick={link.onClick} />;
    }
  }

  return bannerVisible ? (
    <BannerStyled $bgColor={bgColor} $fitContent={fitContent}>
      <IconWrapper>{icon}</IconWrapper>
      <BannerContent>
        <BannerText as="p">
          <Trans>
            {bannerText} {action}
          </Trans>
        </BannerText>
      </BannerContent>
      {dismissable && (
        <BannerButton onClick={handleClose}>
          <CloseIcon color={colors.iconIconPrimarySecondary1100} />
        </BannerButton>
      )}
    </BannerStyled>
  ) : null;
}

export default Banner;
