import React from 'react';

import { EverBrightIcon } from 'shared/components/icons';
import { HeaderStyled, HeaderText } from './Header.styles';

type Props = {
  title: string;
  titleBold: string;
  showLogo?: boolean;
  headerAlwaysCentered?: boolean;
};
export function Header({ title, titleBold, showLogo = true, headerAlwaysCentered = false }: Props) {
  return (
    <HeaderStyled $showLogo={showLogo} $headerAlwaysCentered={headerAlwaysCentered}>
      {showLogo ? <EverBrightIcon /> : null}
      <HeaderText as="h1">
        {title} <b>{titleBold}</b>
      </HeaderText>
    </HeaderStyled>
  );
}

export default Header;
