import React from 'react';
import { useTheme } from 'styled-components';
import { EverBrightIconLight } from './EverBrightIconLight';
import { EverBrightIconDark } from './EverBrightIconDark';

export function EverBrightIcon() {
  const theme = useTheme();

  return theme.name === 'darkTheme' ? <EverBrightIconDark /> : <EverBrightIconLight />;
}

export default EverBrightIcon;
