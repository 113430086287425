import _ from 'lodash';

interface TableReducerActionType {
  column?: string;
  type: 'LOAD_DATA' | 'CHANGE_SORT';
  tableData?: readonly Object[];
  getter?: (obj: any) => any;
}

export function tableDataReducer(state: any, action: TableReducerActionType) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          tableData: state.tableData.slice().reverse(),
          direction: state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        tableData: _.sortBy(state.tableData, [action.getter || action.column]),
        direction: 'ascending',
      };
    case 'LOAD_DATA':
      return {
        ...state,
        tableData: action.tableData,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      };
    default:
      throw new Error();
  }
}

export default tableDataReducer;
