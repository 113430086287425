import styled from 'styled-components';
import { colors } from 'theme/colors';

import { MOBILE_MAX_WIDTH } from '../../constants';

export const Menu = styled.div`
  display: flex;
  border: none;
  height: 88px;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0rem;
  }
  a:hover {
    color: ${colors.white};
  }
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 16px 2px;
    border-bottom: 1px solid rgba(125, 125, 125, 0.12);
  }
`;

type MenuItemProps = {
  $position?: 'right';
};

export const MenuItem = styled.a<MenuItemProps>`
  color: white !important;
  text-transform: uppercase;
  padding: 24px 0;
  margin: ${({ $position }) => ($position === 'right' ? '0 0 0 auto' : '0 20px')};
  border: none;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  &.active {
    border-bottom: 4px solid ${colors.green};
    border-top: 4px solid ${colors.secondaryDarkBlue};
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 28px 8px 0;
    margin: ${({ $position }) => ($position === 'right' ? '0 0 0 auto' : '0 ')};
  }
`;

export const AccountSelectItem = styled.div`
  text-transform: none;
  height: 32px;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid white;
`;

export const DropdownWrapper = styled.div`
  display: flex;
`;

export const DropdownIconButton = styled.button<{ $hiddenOnDesktop?: boolean }>`
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  display: ${({ $hiddenOnDesktop }) => ($hiddenOnDesktop ? 'none' : 'block')};

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    display: block;
    ul {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 88px;
      padding: 20px 24px;
      h5 {
        font-size: 16px;
      }
    }
  }
`;
