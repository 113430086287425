import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Toast } from 'shared/components/Toast/Toast';
import { darkTheme, lightTheme } from 'shared/design-system/theme/theme';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FlagName } from 'api/types';

type Props = {
  children: JSX.Element;
};
export function ThemeLayout({ children }: Props) {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  const {
    loading,
    flags: { [FlagName.webDarkTheme]: webDarkTheme },
  } = useFeatureFlags();

  useEffect(() => {
    if (webDarkTheme && theme === 'light') {
      setTheme('dark');
    }
  }, [loading, theme, webDarkTheme]);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <Toast />
      {children}
    </ThemeProvider>
  );
}

export default ThemeLayout;
