import styled from 'styled-components';

import { FormWrapper, Header } from 'modules/account/components/Form.styles';

export const UserInfoCardWrapper = styled(FormWrapper)`
  overflow: hidden;
  overflow-wrap: anywhere;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

export const HeaderText = styled(Header)`
  margin: 0;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;

  background-color: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};
  box-shadow:
    0px 0.5px 2px 0px #22345b1c inset,
    0px 0.5px 1px 0px #22345b08 inset;

  border-radius: 50%;
`;
