import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from 'shared/components/Text/Text';
import { TransferAgreementModalBodyStyled } from './TransferAgreementModalBody.styles';

type Props = {
  agreement: string;
  name: string;
};

export function TransferAgreementModalBody({ agreement, name }: Props) {
  const { t } = useTranslation();
  return (
    <TransferAgreementModalBodyStyled>
      <Trans>
        <Text $margin="16px 0 73px" as="p">
          {t('admin.homeownerTransfer.doYouWantToTransfer', { agreement, name })}
        </Text>
      </Trans>
    </TransferAgreementModalBodyStyled>
  );
}

export default TransferAgreementModalBody;
