/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';

import { FaqType } from 'api/types';
import { FaqRequestBody } from 'api/faqs';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { TextArea } from 'shared/components/TextArea/TextArea';
import { Select } from 'shared/components/Select/Select';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { Form, FormGroup } from './FaqFormModal.styles';

const FAQ_FORM_ID = 'FAQ_FORM_ID';

export interface IFormInput extends FaqRequestBody {}

type Props = {
  currentFaqLength: number;
  isOpen: boolean;
  faq?: FaqType;
  onSubmitFaqForm: (formData: IFormInput, faqId?: string) => void;
  onClose: () => void;
};

export function FaqFormModal({ currentFaqLength, faq, isOpen, onSubmitFaqForm, onClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.faq' });
  const { t: tGeneral } = useTranslation();

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      question: faq?.question,
      answer: faq?.answer,
      order: faq?.order || currentFaqLength + 1,
      hidden: faq?.hidden || false,
    },
  });

  useEffect(() => {
    if (faq) {
      setValue('question', faq.question);
      setValue('answer', faq.answer);
      setValue('order', faq.order);
      setValue('hidden', faq.hidden);
    }
  }, [currentFaqLength, faq, setValue]);

  const watchHidden = useWatch({
    control,
    name: 'hidden',
  });

  const handleSubmitFaqForm = (formData: IFormInput) => {
    onSubmitFaqForm(formData, faq?.id);
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  const ctaButtons: CtaButtons = {
    tertiary: {
      label: tGeneral('cta.cancel'),
      onClick: handleClose,
    },
    primary: {
      type: 'submit',
      formId: FAQ_FORM_ID,
      label: tGeneral('cta.submit'),
    },
  };

  const defaultOrderOption = faq?.order
    ? { title: faq.order.toString(), value: faq.order.toString() }
    : undefined;

  const orderOptions = Array.from(
    { length: faq ? currentFaqLength : currentFaqLength + 1 },
    (_, idx) => ({
      title: (idx + 1).toString(),
      value: (idx + 1).toString(),
    }),
  );

  return (
    <Modal
      isOpen={isOpen}
      isFullWidth
      fitContent
      title={faq ? t('editFaq') : t('addFaq')}
      styleVariant="tertiary"
      contentLabel={`${faq ? 'Edit' : 'Add'} Faq Modal`}
      ctaButtons={ctaButtons}
      ctaSpacedBetween
      onRequestClose={handleClose}
    >
      <Form id={FAQ_FORM_ID} onSubmit={handleSubmit(handleSubmitFaqForm)}>
        <TextInput
          id="faq-form-question"
          ariaLabel={t('question')}
          label={t('question')}
          hasError={Boolean(errors.question)}
          errorMessage={errors.question?.message}
          {...register('question', { required: t('required') })}
        />

        <TextArea
          id="faq-form-answer"
          ariaLabel={t('answer')}
          label={t('answer')}
          hasError={Boolean(errors.answer)}
          errorMessage={errors.answer?.message}
          {...register('answer', { required: t('required') })}
        />

        <FormGroup>
          <Select
            id="faq-form-order"
            ariaLabelledBy={t('order')}
            label={t('order')}
            defaultOption={defaultOrderOption}
            options={orderOptions}
            onSelectOption={(value) => {
              setValue('order', parseInt(value, 10), { shouldDirty: true, shouldTouch: true });
            }}
          />

          <Checkbox
            isChecked={watchHidden}
            onChange={() => {
              setValue('hidden', !watchHidden);
            }}
            text={t('hidden')}
          />
        </FormGroup>
      </Form>
    </Modal>
  );
}

export default FaqFormModal;
