import styled from 'styled-components';

export const StateZipGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 245px;
`;

export const StaticPhoneNumber = styled.div`
  margin-bottom: 16px;
`;
