import React from 'react';
import { useTranslation } from 'react-i18next';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useTheme } from 'styled-components';

import { UserType } from 'api/types';
import { Button } from 'shared/components/Button/Button';
import { Label, StaticTextWrapper, Value } from 'modules/account/components/Form.styles';
import { AccountIcon } from 'shared/components/icons';
import { HeaderText, HeaderWrapper, IconWrapper, UserInfoCardWrapper } from './UserInfoCard.styles';

type Props = {
  user: UserType;
  onEdit: () => void;
};

export function UserInfoCard({ user, onEdit }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleEdit = () => {
    onEdit();
  };

  return (
    <UserInfoCardWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <AccountIcon color={theme.colors.iconIconPrimarySecondary1100} filled={false} />
        </IconWrapper>
        <HeaderText as="h3">User Info</HeaderText>
      </HeaderWrapper>

      <StaticTextWrapper>
        <div>
          <Label as="p">{t('account.name')}</Label>
          <Value as="p">
            {user.first_name} {user.last_name}
          </Value>
        </div>

        <div>
          <Label as="p">{t('account.email')}</Label>
          <Value as="p">{user.email}</Value>
        </div>

        <div>
          <Label as="p">{t('account.phoneNumber')}</Label>
          <Value as="p">
            {parsePhoneNumberFromString(user.phone_number, 'US')?.formatNational()}{' '}
          </Value>
        </div>

        <div>
          <Label as="p">{t('account.address')}</Label>
          <Value as="p">
            {user.address.street_1}, {user.address.street_2} {user.address.city}{' '}
            {user.address.state} {user.address.zip_code}
          </Value>
        </div>

        <Button
          dataTestId={`${t('cta.edit')}-btn`}
          styleVariant="secondary"
          label={t('cta.edit')}
          onClick={handleEdit}
        />
      </StaticTextWrapper>
    </UserInfoCardWrapper>
  );
}

export default UserInfoCard;
