import styled from 'styled-components';

export const ErrorModalContent = styled.div`
  ${({ theme }) => `
  
  @media(width>= ${theme.breakpoints.small}){
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        max-width: 354px;
        svg {
            display: flex;  
            height: 162px;
            left: 110px;
            top: 43px;
        }
        h2 {
            color: ${theme.colors.textPrimarySecondary1100};
            font-family: Avenir;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            padding: 32px 0px 24px 0px;
        }

        p {
            color: ${theme.colors.textPrimarySecondary1100};
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
            text-align: center;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
}
@media(width < ${theme.breakpoints.small}){
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        width: fit-content;
        svg {
            display: flex;  
            width: 151px;
            height: 138px;
            left: 68px;
            top: 56px;
        }
        h2 {
            color: ${theme.colors.textPrimarySecondary1100};
            font-family: Avenir;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            padding: 32px 0px 24px 0px;
        }

        p:nth-of-type(1){
            color: ${theme.colors.textPrimarySecondary1100};
            padding-bottom: 16px;
            text-align: center;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
        p:nth-of-type(2){
            color: ${theme.colors.textPrimarySecondary1100};
            text-align: center;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
}
    
    `}
`;

export default {};
