import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

import { ArrowUpRightFromSquare } from 'legacy/components/common/design-system/Icons';
import {
  CardActionText,
  CardIconContainer,
  CardMainBody,
  CardTitle,
  IconColor,
} from './Card.styles';

type Icon = {
  element: JSX.Element;
  color?: IconColor;
};
interface CardProps {
  title: string;
  url?: string;
  route?: string;
  navState?: Record<string, any>;
  newTab?: boolean;
  description: string;
  height?: string;
  width?: string;
  icon?: Icon;
  hasHeaderIcons?: boolean;
  className?: string;
  dataTestId?: string;
  onClick?: () => void;
}

export function Card({
  title,
  url,
  route,
  navState,
  children,
  icon,
  height,
  width,
  actionText,
  actionIcon,
  newTab = true,
  hasHeaderIcons = true,
  className,
  dataTestId,
  onClick,
}: PropsWithChildren<Omit<CardProps, 'description'> & { actionText?: string; actionIcon?: Icon }>) {
  const navigate = useNavigate();

  const testId = dataTestId || `${title.split(' ').join('-').toLowerCase()}-card`;

  const handleClick = () => {
    if (route) {
      navigate(route, { state: navState });
      return;
    }

    if (url && newTab) {
      window.open(url, '_blank');
    } else if (url) {
      window.location.href = url;
    }

    onClick?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  const cardRole = route || url || onClick ? 'button' : 'none';

  return (
    <CardMainBody
      data-testid={testId}
      role={cardRole}
      tabIndex={0}
      $cardHeight={height}
      $cardWidth={width}
      $iconColor={icon ? icon.color : undefined}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      // allows importing/direct styling via styled-components
      className={className}
    >
      {icon && hasHeaderIcons && <CardIconContainer>{icon.element}</CardIconContainer>}

      <CardTitle as="h2">
        {title}
        {url && hasHeaderIcons && (
          <>
            {' '}
            <ArrowUpRightFromSquare />
          </>
        )}
      </CardTitle>
      {children}
      {actionText && (
        <CardActionText>
          {actionIcon?.element} {actionText}
        </CardActionText>
      )}
    </CardMainBody>
  );
}

export default Card;
