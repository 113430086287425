export enum DateRangeZoomLevel {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type SiteTimezoneResponse = {
  timezone: string;
};

export enum BatteryStatusValue {
  'Savings Mode',
  'Full Backup',
  'Self-consumption',
}

export enum GridStatusValue {
  'On Grid',
  'Off Grid',
  'Unknown',
}

export enum SolarEdgeStatusValue {
  'Active',
  'Idle',
  'Disabled',
}

export type PowerFlowDatum<T> = {
  status?: T;
  power_kw: number;
  state_of_charge_pct?: number;
};

export type PowerFlowDataResponse = {
  battery?: PowerFlowDatum<BatteryStatusValue>;
  grid?: PowerFlowDatum<GridStatusValue>;
  home?: PowerFlowDatum<SolarEdgeStatusValue>;
  system?: PowerFlowDatum<SolarEdgeStatusValue>;
  data_quality: {
    empty_response: boolean;
    whole_site_offline: boolean;
    non_zero_sum_deficit: boolean;
    non_zero_sum_surplus: boolean;
    negative_home_consumption: boolean;
    negative_solar_production: boolean;
    solar_power_exceeds_capacity: boolean;
    battery_power_exceeds_capacity: boolean;
    solar_production_at_night: boolean;
  };
};

export enum BucketSize {
  ALL = 'all',
  MINUTE = 'minute',
  FIFTEEN_MIN = '15min',
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export type MonitoringDatum<T> = {
  value: number;
  time: Date;
  unit: T;
};

export enum ChartQueryType {
  POWER,
  ENERGY,
}

export type EnergyUnit = 'Wh' | 'MWh' | 'kWh';
export type PowerUnit = 'W' | 'kW' | 'MW';

export type MonitoringDataParams = {
  aggregation_level?: string;
  start_time: string;
  end_time: string;
};
