import { format } from 'date-fns';
import { DateRange } from './DateRange';
import { getAggregationLevel } from './getAggregationLevel';
import { MonitoringDataParams } from '../types';

/** Returns an ISO-style string that is timezone-agnostic */
export const getPlainDateString = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss");

export const getRequestParams = (
  dateRange: DateRange | undefined | null,
): MonitoringDataParams | undefined => {
  if (!dateRange) {
    return undefined;
  }
  const params = {
    start_time: getPlainDateString(dateRange.startDate),
    end_time: getPlainDateString(dateRange.endDate),
    aggregation_level: getAggregationLevel(dateRange.zoomLevel),
  };
  return params;
};

export default getRequestParams;
