import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import pages from 'pages';
import StatisticsView from 'legacy/views/admin/StatisticsView';
import TagsView from 'legacy/views/admin/TagsView';
import MonitoringDetailView from 'legacy/views/admin/MonitoringDetailView';
import Monitoring from 'legacy/components/admin/monitoring/Monitoring';
import AuthCopyAckView from 'legacy/views/admin/AuthCopyAckView';
import NoMatch from 'legacy/views/noMatch/NoMatch';
import { AdminLayout as Layout } from './admin-layout';
import { AdminLayout } from '../../layouts/AdminLayout';
import { SupportGuard } from '../../guards/SupportGuard';
import { Agreements } from './agreements';
import { UserProfile } from './user-profile';
import { Admins } from './admins';
import { ManageFaqs } from './manage-faqs';
import { Backfill } from './backfill';

export const Admin: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: `${pages.ADMIN}/*`,
        element: <AdminLayout />,

        children: [
          {
            index: true,
            element: <Navigate to={`/${pages.ADMIN}/${pages.AGREEMENTS}`} replace />,
          },

          {
            path: pages.USER,
            element: (
              <SupportGuard>
                <UserProfile />
              </SupportGuard>
            ),
          },
          { path: pages.AGREEMENTS, element: <Agreements /> },
          { path: pages.ADMINS, element: <Admins /> },
          { path: pages.STATISTICS, element: <StatisticsView /> },
          { path: pages.MANAGE_FAQS, element: <ManageFaqs /> },
          { path: pages.TAGS, element: <TagsView /> },
          { path: pages.MONITORING_DETAIL, element: <MonitoringDetailView /> },
          { path: pages.MONITORING, element: <Monitoring /> },
          { path: pages.AUTH_COPY_ACK, element: <AuthCopyAckView /> },
          { path: pages.BACKFILL, element: <Backfill /> },
        ],
        errorElement: <NoMatch />,
      },
    ],
  },
];

export default Admin;
