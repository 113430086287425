/* eslint-disable class-methods-use-this */
import { MonitoringDatum, ChartDataType, RawMonitoringDatumType } from 'legacy/types';
import { DateRange } from '../utils/createDateRange';

export class ChartDataProvider {
  parseDates<T>(rawData: RawMonitoringDatumType<T>[]) {
    return rawData?.map(({ value, timestamp, unit }) => ({
      value,
      time: new Date(timestamp),
      unit,
    }));
  }

  aggregateData<T>(
    _: MonitoringDatum<T>[],
    __: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    throw new Error('Method not implemented.');
  }

  prepareData<T>(
    data: RawMonitoringDatumType<T>[],
    dateRange: DateRange,
  ): [ChartDataType[], number | null, T | null] {
    const datesAdded = this.parseDates(data);
    const [dailyProduction, largestProductionValue, largestProductionUnit] = this.aggregateData<T>(
      datesAdded,
      dateRange,
    );
    const productionData: ChartDataType[] = [];
    dailyProduction.forEach((value: MonitoringDatum<T>, key: number) => {
      productionData.push({
        x: key,
        y: value.value,
        unit: value.unit as string,
        value: value.value,
        time: value.time,
      });
    });
    return [productionData, largestProductionValue, largestProductionUnit];
  }
}

export default ChartDataProvider;
