import React from 'react';

import { Button } from 'shared/components/Button/Button';
import { FoldersIcon } from 'shared/components/icons/FoldersIcon';
import {
  AgreementDetailsSection,
  FlexContainer,
  Label,
  SystemSection,
  Title,
  Value,
} from './AgreementsAccordion.styles';
import { Section } from './Section';

type Props = {
  overviewList: { label: string; value: string | undefined }[];
  contractDetailsList: { label: string; value: string | undefined }[];
  financialsList: { label: string; value: string | undefined }[];
  systemList: { label: string; value: string | undefined }[];
  isArchived: boolean;
  onToggleArchive: () => void;
};
export function AgreementSection({
  overviewList,
  contractDetailsList,
  financialsList,
  systemList,
  isArchived,
  onToggleArchive,
}: Props) {
  return (
    <FlexContainer>
      <AgreementDetailsSection>
        <Section title="Overview" items={overviewList} />
        <Section title="Contract Details" items={contractDetailsList} />
        <Section title="Financials" items={financialsList} />
      </AgreementDetailsSection>

      <SystemSection>
        <Title as="h3">System</Title>
        <AgreementDetailsSection>
          {systemList.map((item) => (
            <div key={item.label}>
              <Label as="p">{item.label}</Label>
              <Value as="p">{item.value}</Value>
            </div>
          ))}
        </AgreementDetailsSection>
      </SystemSection>

      <Button
        label={`${isArchived ? 'Unarchive' : 'Archive'} Agreement`}
        styleVariant="tertiary"
        Icon={FoldersIcon}
        onClick={onToggleArchive}
      />
    </FlexContainer>
  );
}

export default AgreementSection;
