import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function BackfillIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.2199 11.2776C4.51362 11.5713 4.98858 11.5713 5.27917 11.2776C5.56977 10.9839 5.57289 10.5089 5.27917 10.2183L2.56069 7.49986L13.2503 7.49673C13.9408 7.49673 14.5001 8.05605 14.5001 8.74661V13.9961C14.5001 14.4117 14.8345 14.746 15.2501 14.746C15.6657 14.746 16 14.4117 16 13.9961V8.74661C16 7.22801 14.7689 5.99688 13.2503 5.99688H2.56069L5.27917 3.27839C5.57289 2.98467 5.57289 2.50972 5.27917 2.21912C4.98545 1.92852 4.5105 1.9254 4.2199 2.21912L0.220291 6.21561C-0.0734303 6.50933 -0.0734303 6.98428 0.220291 7.27488L4.2199 11.2776Z"
        fill={color}
      />
    </svg>
  );
}

export default BackfillIcon;
