import styled from 'styled-components';
import { TTableStyleVariantsKey } from 'theme/table';

type TTableStyleProps = {
  $isFullWidth: boolean;
  $styleVariant: TTableStyleVariantsKey;
};

export const TableStyled = styled.table<TTableStyleProps>`
  border-collapse: collapse;
`;

export const TbodyContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
`;

export const TbodyStyled = styled.tbody`
  border-radius: 16px;
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.colors.strokeSecondaryGrey10},
    0px 4px 8px -2px #22345b1a,
    0px 2px 4px -2px #22345b0f;
  /* 
  Border effect,
  First elevation shadow,
  Second elevation shadow
*/
`;
