export const SOLAREDGE_MANUFACTURER_ID = '16941363-3cc3-471d-a9de-cc401c81c23d';
export const ENPHASE_MANUFACTURER_ID = '61b93d95-cd4e-4b20-a3e1-0648f36e7f34';

export function formatInverterManufacturer(manufacturerId: string | undefined): string | undefined {
  switch (manufacturerId) {
    case SOLAREDGE_MANUFACTURER_ID:
      return 'SolarEdge';
    case ENPHASE_MANUFACTURER_ID:
      return 'Enphase';
    default:
      return manufacturerId;
  }
}
