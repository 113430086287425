import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backfillWorkflows } from 'api/system';
import { Header } from 'shared/components/Header/Header';
import { toast } from 'shared/components/Toast/Toast';
import { Button } from 'shared/components/Button/Button';
import { BackfillWrapper, Label } from './Backfill.styles';
import { BackfillWorkflowsForm } from '../components/BackfillWorkflowsForm';

export function Backfill() {
  const [workflows, setWorkflows] = useState<string[]>([]);

  const { t } = useTranslation();

  const theme = useTheme();

  const handleSetWorkflows = (newWorkflows: string[]) => {
    setWorkflows((w) => Array.from(new Set([...w, ...newWorkflows])));
  };

  const handleStartBackfillWorkflow = () => {
    if (!workflows.length) {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: 'Invalid workflow length',
        theme,
      });

      return;
    }

    backfillWorkflows(workflows)
      .then(() => {
        toast({
          type: 'success',
          title: t('toast.success'),
          message: 'Backfill process for subset of workflows has started.',
          theme,
        });

        setWorkflows([]);
      })
      .catch(() => {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: 'Unable to start backfill process.',
          theme,
        });

        setWorkflows([]);
      });
  };

  return (
    <BackfillWrapper>
      <Header pageName="BACKFILL" title={t('admin.backfill.backfill')} />

      {workflows.map((workflow) => (
        <Label className="uuid-label" key={workflow}>
          {workflow}
        </Label>
      ))}

      <BackfillWorkflowsForm onSubmit={handleSetWorkflows} />

      <Button label="Backfill Subset" margin="0 auto 0 0" onClick={handleStartBackfillWorkflow} />
    </BackfillWrapper>
  );
}

export default Backfill;
