import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  margin: 14px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
