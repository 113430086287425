import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { resolvePath, useNavigate } from 'react-router';

import { pages } from 'pages';
import { useTransferAccount } from 'api/accounts';
import { useGetAgreement } from 'api/agreement';
import { HomeownerTransferModal } from 'admin/modules/agreements/modals/HomeownerTransferModal/HomeownerTransferModal';
import { Accordion } from 'legacy/components/common/design-system';
import { Address } from 'legacy/components/common/myeverbright';
import { AccountType, MessageType, UserType } from 'legacy/types';
import { UserFormType } from 'legacy/components/forms/UserForm';
import routes from 'routes';
import AgreementSection from './AgreementSection';
import AddAgreement from '../AddAgreement';
import InfoCard from './InfoCard';
import { AdminAccordionWrapper } from './UserDetailStyles';

type Props = {
  account: AccountType;
  user: UserType;
  refreshUser: () => void;
};

export default function AccountSection({ account, user, refreshUser }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);
  const [agreementId, setAgreementId] = useState('');

  const navigate = useNavigate();

  const { data, isSuccess } = useGetAgreement(agreementId);

  useEffect(() => {
    if (data && isSuccess) {
      const newHomeOwnerId = data.account_users_summaries[0].id;
      const userPath = pages.USER.replace(':userId', newHomeOwnerId);

      navigate(resolvePath(userPath, `/${pages.ADMIN}`));
    }
  }, [data, isSuccess, navigate]);

  const infoList = [
    { label: 'Home Owner Account ID ', value: account.homeowner_account_id, id: 'as1' },
    {
      label: 'Address',
      value: <Address address={account.address} />,
      id: 'as2',
    },
    { label: 'Archived', value: account.archived.toString(), id: 'as6' },
  ];

  const toggleArchived = (e: React.SyntheticEvent) => {
    e.preventDefault();
    axios
      .patch(routes.account(account.id), { archived: !account.archived })
      .then(() => {
        refreshUser();
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  };

  const { mutate } = useTransferAccount({
    onSuccess: () => {
      setAgreementId(account.agreements[0].id);
      setModalOpen(false);
    },
    onError: () => {
      setModalOpen(false);
      // TODO: handle Error
    },
  });

  const handleClickTransferAgreement = (new_users: UserFormType[]) => {
    mutate({ account_id: account.id, body: { new_users } });
  };
  const content = (
    <InfoCard
      items={infoList}
      archived={account.archived}
      enableArchive
      message={message}
      onChangeArchived={toggleArchived}
      onClickTransferAccount={() => setModalOpen(true)}
    >
      <>
        {account.agreements.map((agreement) => (
          <AgreementSection key={agreement.id} agreement={agreement} refreshUser={refreshUser} />
        ))}
        <AddAgreement accountId={account.id} addAgreement={refreshUser} />
      </>
    </InfoCard>
  );

  return (
    <>
      <AdminAccordionWrapper>
        <Accordion
          id="admin-accordion"
          styleVariant="secondary"
          contentBgColor={account.archived ? '#dbdbdb' : 'inherit'}
          isOpen
          items={[
            {
              label: `Account #${account.homeowner_account_id} ${
                account.archived ? '(archived)' : ''
              }`,
              content,
            },
          ]}
        />
      </AdminAccordionWrapper>
      <HomeownerTransferModal
        isOpen={modalOpen}
        transferUser={user}
        transferAccount={account}
        onClickTransferAgreement={handleClickTransferAgreement}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
}
