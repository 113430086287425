/**
 *
 * STYLE VARIANTS
 *
 */
// INTERACTIONS
export type TCheckboxInteractionsStyles = {
  // enabled
  checkboxBackgroundUnchecked: string;
  checkboxBackgroundChecked: string;
  borderUnchecked: string;
  borderChecked: string;
  borderRadius: string;
  checkColor: string;
  hintFontColor: string;
  labelFontColor: string;

  // hover
  checkboxBackgroundUncheckedHover: string;
  checkboxBackgroundCheckedHover: string;
  borderUncheckedHover: string;
  borderCheckedHover: string;
  checkColorHover: string;
  hintFontColorHover: string;
  labelFontColorHover: string;

  // active
  checkboxBackgroundUncheckedActive: string;
  checkboxBackgroundCheckedActive: string;
  borderUncheckedActive: string;
  borderCheckedActive: string;
  checkColorActive: string;
  hintFontColorActive: string;
  labelFontColorActive: string;
};

// STATES
export type TCheckboxStates = {
  enabled: TCheckboxInteractionsStyles;
  error: TCheckboxInteractionsStyles;
  disabled: TCheckboxInteractionsStyles;
};
export type TCheckboxStatesKey = keyof TCheckboxStates;
export const ENABLED_CHECKBOX_STATE: TCheckboxStatesKey = 'enabled';
export const DISABLED_CHECKBOX_STATE: TCheckboxStatesKey = 'disabled';
export const ERROR_CHECKBOX_STATE: TCheckboxStatesKey = 'error';

// STYLE VARIANTS
export type TCheckboxStyleVariants = {
  default: TCheckboxStates;
};
export type TCheckboxStyleVariantsKey = keyof TCheckboxStyleVariants;
export const DEFAULT_CHECKBOX_STYLE_VARIANT: TCheckboxStyleVariantsKey = 'default';

/**
 *
 * TCheckboxes in the theme
 *
 */
export type TCheckboxes = {
  variants: TCheckboxStyleVariants;
};
