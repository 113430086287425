import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getSolarEnergy } from 'api/system';
import { AnyChartDataProvider } from 'legacy/components/MonitoringCard/data/AnyChartDataProvider';
import { ChartDataType, EnergyUnit } from 'legacy/types';
import Banner from 'legacy/components/common/design-system/Banner/Banner';
import colors from 'theme/colors';
import { LoadingSpinner } from 'legacy/components/common/design-system';
import { DateRange } from 'legacy/components/MonitoringCard/utils/createDateRange';
import { BarChart } from './BarChart';
import WarningIcon from '../../../../../../static/warning-icon.svg';
import { SpinnerContainer } from './ChartStyles';

interface Props {
  dataProvider: AnyChartDataProvider;
  systemId: string;
  dateRange: DateRange;
}

export function EnergyChart({ dataProvider, systemId, dateRange }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[] | null>(null);
  const [yAxisEnergyUnit, setYAxisEnergyUnit] = useState<EnergyUnit | null>(null);
  const [largestProductionValue, setLargestProductionValue] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { zoomLevel, startDate } = dateRange;

  const cacheStaleTime = 15 * 60 * 1000; // cache items are invalidated after 15 minutes, the rate at which ne360 refreshes telemetry

  const { data, isFetching, isError, error } = useQuery(
    ['solarEnergy', systemId, dateRange, startDate, zoomLevel, dataProvider],
    () => getSolarEnergy({ systemId, dateRange }).then((response) => response.data),
    {
      enabled: Boolean(dateRange),
      retry: false,
      staleTime: cacheStaleTime,
    },
  );

  useEffect(() => {
    if (!isFetching && data && Object.keys(data).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] =
        dataProvider.prepareData<EnergyUnit>(data, dateRange);
      setYAxisEnergyUnit(_yAxisEnergyUnit as EnergyUnit);
      setLargestProductionValue(_largestProductionValue);
      setChartData(productionData);
    }
  }, [startDate, zoomLevel, data, dataProvider, dateRange, isFetching]);

  useEffect(() => {
    if (isError && (error as AxiosError)?.response?.status === 400) {
      setErrorMessage(
        `Monitoring data is currently unavailable for the selected date range. Don’t worry, we are on it! Try selecting a new date, or check back later for updates.`,
      );
    } else if (isError) {
      setErrorMessage(
        `Up-to-date monitoring data is currently unavailable. Don’t worry, we are on it! Please check back for updates.`,
      );
    }
  }, [isError, error]);

  return (
    <>
      {isError && errorMessage && (
        <Banner
          bannerText={errorMessage}
          bgColor={colors.yellow20}
          icon={<img alt="warning" src={WarningIcon} />}
          dismissable={false}
        />
      )}

      {!isError &&
        (yAxisEnergyUnit && largestProductionValue && chartData ? (
          <BarChart
            yAxisUnit={yAxisEnergyUnit}
            domain={dataProvider.getDomain(dateRange, largestProductionValue)}
            dateRange={dateRange}
            domainPaddingX={[75, 10]}
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange),
              y: dataProvider.getYAxis(largestProductionValue, yAxisEnergyUnit),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default EnergyChart;
