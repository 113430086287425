import styled, { css } from 'styled-components';
import {
  getCheckboxStyleVariantValues,
  TCheckboxStatesKey,
  TCheckboxStyleVariantsKey,
} from 'shared/design-system/theme/checkboxes';

type TProps = {
  $styleVariant: TCheckboxStyleVariantsKey;
  $state: TCheckboxStatesKey;
};

type TOuterContainerProps = {
  $margin: string;
};

export const OuterContainer = styled.div<TOuterContainerProps>`
  display: flex;
  flex-direction: column;
  margin: ${({ $margin }) => $margin};
`;

export const Label = styled.label<TProps>`
  ${({ $styleVariant, $state, theme }) => {
    const {
      checkboxBackgroundUncheckedHover,
      checkboxBackgroundCheckedHover,
      borderUncheckedHover,
      borderCheckedHover,
    } = getCheckboxStyleVariantValues(theme, $styleVariant, $state);

    return css`
      display: block;
      position: relative;
      margin-bottom: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover input ~ span {
        background: ${checkboxBackgroundUncheckedHover};
        border: ${borderUncheckedHover};
      }

      &:hover input:checked ~ span {
        background: ${checkboxBackgroundCheckedHover};
        border: ${borderCheckedHover};
      }
    `;
  }}
`;

export const Input = styled.input<TProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { checkboxBackgroundChecked, borderChecked } = getCheckboxStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    return css`
      display: block;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin: 0;
      &:checked ~ span {
        border: ${borderChecked};
        background: ${checkboxBackgroundChecked};
        svg {
          display: block;
        }
      }
      &:checked ~ span:after {
        display: block;
      }
    `;
  }}
`;

export const Box = styled.span<TProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { checkboxBackgroundUnchecked, borderUnchecked } = getCheckboxStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 3px;
      left: 0;
      height: 16px;
      width: 16px;
      background: ${checkboxBackgroundUnchecked};
      border: ${borderUnchecked};
      border-radius: 4px;
      svg {
        display: none;
      }
    `;
  }}
`;

export const Text = styled.p<TProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { labelFontColor } = getCheckboxStyleVariantValues(theme, $styleVariant, $state);

    return css`
      display: flex;
      align-items: center;
      margin: 0 0 0 28px;
      color: ${labelFontColor};
      font-family: AvenirLTPro;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    `;
  }}
`;

export const HintText = styled.p<TProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { hintFontColor } = getCheckboxStyleVariantValues(theme, $styleVariant, $state);

    return css`
      display: flex;
      align-items: center;
      color: ${hintFontColor};
      font-style: normal;
      font-weight: 400;
      font-family: AvenirLTPro;
      font-size: 14px;
      line-height: 20px;
      margin: 4px 0 0 30px;
    `;
  }}
`;
