import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { PenToSquareIcon } from 'shared/components/icons/PenToSquareIcon';
import { CircleCheckIcon } from 'shared/components/icons/CircleCheckIcon';
import { CircleExclamationIcon } from 'shared/components/icons/CircleExclamationIcon';
import {
  ClaimedText,
  ContentWrapper,
  EmailText,
  NameText,
  TableCellUserStyled,
} from './TableCellUser.styles';

type Props = {
  name: string;
  email: string;
  claimed: boolean;
  onClick: () => void;
};

export function TableCellUser({ name, email, claimed, onClick }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <TableCellUserStyled onClick={onClick}>
      <ContentWrapper>
        <NameText as="p">{name}</NameText>
        <PenToSquareIcon color={theme.colors.iconIconPrimaryHoverFunction4100} />
      </ContentWrapper>
      <EmailText as="p">{email}</EmailText>
      <ContentWrapper>
        {claimed ? (
          <CircleCheckIcon color={theme.colors.chartChartGreenPressedFunction5100} />
        ) : (
          <CircleExclamationIcon color={theme.colors.chartChartMagentaPressedAccent1160} />
        )}
        <ClaimedText as="p" $claimed={claimed}>
          {claimed ? t('admin.claimed') : t('admin.unclaimed')}
        </ClaimedText>
      </ContentWrapper>
    </TableCellUserStyled>
  );
}

export default TableCellUser;
