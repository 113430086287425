export function DjangoAdmin() {
  /* 
    we do this because people route to /django_admin 
    without the trailing / which leads them to the ErrorFallback
    */
  window.location.href = `${window.location.origin}/django_admin/`;

  return null; // Render nothing since we are redirecting
}

export default DjangoAdmin;
