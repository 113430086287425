import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyBoldText, BodyText } from '../AuthCopyAckModal.styles';

export function ErrorBody() {
  const { t } = useTranslation();

  const textArray: string[] = t('modal.error', {
    returnObjects: true,
  });

  return (
    <>
      {textArray.map((item, idx) => {
        const key = `err-body-${idx}`;
        if (idx === 0)
          return (
            <BodyBoldText key={key} as="p" $margin="16px 0">
              {item}
            </BodyBoldText>
          );
        return (
          <BodyText key={key} as="p">
            {item}
          </BodyText>
        );
      })}
    </>
  );
}

export default ErrorBody;
