import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const TableCellStringStyled = styled.div``;

export const CellText = styled(Text)<{ $highlightRed?: boolean }>`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ $highlightRed, theme }) =>
    $highlightRed
      ? theme.colors.textSystemErrorStrongFunction1160
      : theme.colors.textPrimarySecondary1100};
`;
