import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_DROPDOWN_STYLE_VARIANT,
  TDropdownStyleVariantsKey,
  getDropdownState,
} from 'theme/dropdowns';
import { useTheme } from 'styled-components';
import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import { generateRandomString } from 'shared/utils';
import { handleFocusedKeyPress } from 'shared/utils/handleFocusedKeypress';
import { getInputState } from 'shared/design-system/theme/inputs';
import { ArrowUpIcon } from '../icons/ArrowUpIcon';
import {
  CustomSelectContainer,
  SelectSelected,
  NativeSelect,
  SelectScrim,
} from '../Select/Select.styles';
import { ArrowDownIcon } from '../icons';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../Dropdown/DropdownItem/DropdownItem';
import { InputLabel } from '../TextInput/TextInput.styles';
import { TextInputStyled } from './SelectInput.styles';

type TSelectProps = {
  id?: string;
  ariaLabelledBy?: string;
  role?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  isDisabled?: boolean;
  defaultOption?: TDropdownDataItem;
  options: TDropdownDataItem[];
  styleVariant?: TDropdownStyleVariantsKey;
  onSelectOption?: (value: string) => void;
  className?: string;
  inputValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

/**
 * @param {string} [id] - id (optional)
 * @param {string} [ariaLabelledBy] - aria-labelledby (optional)
 * @param {string} [role] - role of dropdown container. defaults to listbox (optional)
 * @param {string} [label] - label of dropdown container. (optional)
 * @param {string} [placeholder] - placeholder when no item is selected (optional)
 * @param {string} [value] - value for select (optional)
 * @param {boolean} [isDisabled] - is the select disabled (optional)
 * @param {TDropdownDataItem} [options] - select options
 * @param {TDropdownStyleVariantsKey} [styleVariant] - (optional)
 * @param {function} [onSelectOption] - click handler (optional)
 * @param {string} [className] - required to expose the overall component to styled-components
 * @param {function} [onChange] - onChange handler (optional)
 * @param {function} [onKeyDown] - onKeyDown handler (optional)
 */

export function SelectInput({
  id,
  ariaLabelledBy,
  defaultOption,
  role = 'listbox',
  label,
  placeholder = 'Select an option',
  value,
  isDisabled = false,
  options,
  styleVariant = DEFAULT_DROPDOWN_STYLE_VARIANT,
  onSelectOption,
  className,
  inputValue,
  onChange,
  onKeyDown,
}: TSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TDropdownDataItem>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const theme = useTheme();

  const inputId = useMemo(() => id || generateRandomString(), [id]);

  const inputState = getInputState(false, false);

  const dropdownState = getDropdownState(isDisabled);

  useEffect(() => {
    let newOption = options.find((option) => option.value === value);
    newOption = newOption || defaultOption;
    if (newOption) setSelectedOption(newOption);
  }, [options, value, defaultOption]);

  useEffect(() => {
    if (isClosing) setIsClosing(false);
  }, [isClosing]);

  const handleOpenDropdown = () => {
    if (!isClosing) setIsOpen(true);
    setIsClosing(false);
  };

  const handleCloseDropdown = () => {
    setIsClosing(true);
    setIsOpen(false);
  };

  const handleOptionClick = (option: TDropdownDataItem) => {
    onSelectOption?.(option.value || '');
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleDropdownKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === ' ' && inputRef.current?.value) {
      return;
    }
    handleFocusedKeyPress(e, () => setIsOpen(!isOpen));
  };

  const handleItemKeyPress = (e: React.KeyboardEvent<HTMLElement>, option: TDropdownDataItem) => {
    handleFocusedKeyPress(e, () => handleOptionClick(option));
  };

  const hanldeInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      setIsOpen(false);
    }
  };

  return (
    <CustomSelectContainer
      id={id}
      aria-labelledby={ariaLabelledBy}
      role={role}
      className={className}
      onKeyDown={handleDropdownKeyPress}
    >
      {label && (
        <InputLabel
          id={`${inputId}-select-title`}
          htmlFor={`${inputId}-TextInput`}
          $styleVariant="default"
          $inputState="enabled"
        >
          {label}
        </InputLabel>
      )}

      <SelectScrim $show={isOpen} />

      <NativeSelect id={`${inputId}-TextInput`} value={selectedOption?.value} />
      <SelectSelected
        $styleVariant={styleVariant}
        $state={dropdownState}
        onClick={handleOpenDropdown}
        tabIndex={0}
      >
        <TextInputStyled
          ref={inputRef}
          $styleVariant="default"
          $inputState={inputState}
          $isFullWidth
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          onKeyDown={hanldeInputKeyDown}
        />

        {isOpen ? (
          <ArrowUpIcon color={theme.colors.iconIconPrimaryPressedSecondary2100} />
        ) : (
          <ArrowDownIcon color={theme.colors.iconIconPrimaryPressedSecondary2100} />
        )}
      </SelectSelected>

      <Dropdown
        styleVariant={styleVariant}
        isOpen={isOpen}
        isFullWidth
        onClickOutside={handleCloseDropdown}
      >
        {options.map((option) => (
          <DropdownItem
            styleVariant={styleVariant}
            key={option.title}
            onClick={() => handleOptionClick(option)}
            onKeyDown={(e) => handleItemKeyPress(e, option)}
            dropdownItemData={option}
          />
        ))}
      </Dropdown>
    </CustomSelectContainer>
  );
}

export default SelectInput;
