import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { PowerUnit, ChartDataType } from 'legacy/types';
import { getSolarPower } from 'api/system';
import { DayChartDataProvider } from 'legacy/components/MonitoringCard/data/DayChartDataProvider';
import Banner from 'legacy/components/common/design-system/Banner/Banner';
import colors from 'theme/colors';
import { LoadingSpinner } from 'legacy/components/common/design-system';
import { DateRange } from 'legacy/components/MonitoringCard/utils/createDateRange';
import { AreaChart } from './AreaChart';
import WarningIcon from '../../../../../../static/warning-icon.svg';
import { SpinnerContainer } from './ChartStyles';

interface Props {
  dateRange: DateRange;
  systemId: string;
  systemCapacity?: number;
}

export function DayChart({ dateRange, systemId, systemCapacity }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const [yAxisUnit, setYAxisUnit] = useState<PowerUnit>('W');
  const [dataProvider] = useState<DayChartDataProvider>(new DayChartDataProvider());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { zoomLevel, startDate } = dateRange;
  const refetchInterval = 15 * 60 * 1000; // 15 minutes
  const cacheStaleTime = 15 * 60 * 1000; // cache items are invalidated after 15 minutes, the rate at which ne360 refreshes telemetry

  const { data, isFetching, isError, error } = useQuery(
    ['solarPower', systemId, startDate, dateRange, zoomLevel, dataProvider],
    () => getSolarPower({ systemId, dateRange }).then((res) => res.data),
    {
      refetchInterval,
      retry: false,
      staleTime: cacheStaleTime,
    },
  );

  useEffect(() => {
    if (!isFetching && data && Object.keys(data).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
      const [productionData, _, _yAxisEnergyUnit] = dataProvider.prepareData(data, dateRange);
      setChartData(productionData);
      setYAxisUnit(_yAxisEnergyUnit as PowerUnit);
    }
  }, [startDate, zoomLevel, dataProvider, dateRange, isFetching, data]);

  useEffect(() => {
    if (isError && (error as AxiosError)?.response?.status === 400) {
      setErrorMessage(
        `Monitoring data is currently unavailable for the selected date range. Don’t worry, we are on it! Try selecting a new date, or check back later for updates.`,
      );
    } else if (isError) {
      setErrorMessage(
        `Up-to-date monitoring data is currently unavailable. Don’t worry, we are on it! Please check back for updates.`,
      );
    }
  }, [isError, error]);

  return (
    <>
      {isError && errorMessage && (
        <Banner
          bannerText={errorMessage}
          bgColor={colors.yellow20}
          icon={<img alt="warning" src={WarningIcon} />}
          dismissable={false}
        />
      )}
      {!isError &&
        (chartData.length ? (
          <AreaChart
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange),
              y: dataProvider.getYAxis(systemCapacity || 0, yAxisUnit),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            dateRange={dateRange}
            yAxisUnit={yAxisUnit}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default DayChart;
