/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressType } from 'api/types';
import TextInput from 'shared/components/TextInput/TextInput';
import STATES from 'constants/states';
import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import Select from 'shared/components/Select/Select';
import { getDefaultStateOption } from 'modules/account/components/ContactForm/ContactForm';
import { StateZipGroup, StyledForm } from '../UserFormModalBody/UserFormBody.styles';

export const NEW_BILLING_FORM_MODAL_ID = 'new-billing-form-modal-id';

type Address = Omit<AddressType, 'id' | 'archived'>;

export interface IFormInput extends Address {}

type Props = {
  initialValues?: IFormInput;
  onSubmit: (formData: IFormInput) => void;
};

export function BillingFormModalBody({ initialValues, onSubmit }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.homeownerTransfer' });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: initialValues,
  });

  const stateOptions: TDropdownDataItem[] = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  return (
    <StyledForm id={NEW_BILLING_FORM_MODAL_ID} onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="contact-form-street1"
        ariaLabel={t('street1')}
        label={t('street1')}
        hasError={Boolean(errors.street_1)}
        errorMessage={errors.street_1?.message}
        {...register('street_1', { required: t('required') })}
      />
      <TextInput
        id="contact-form-street2"
        ariaLabel={t('street2')}
        label={t('street2')}
        hasError={Boolean(errors.street_2)}
        errorMessage={errors.street_2?.message}
        {...register('street_2')}
      />
      <TextInput
        id="contact-form-city"
        ariaLabel={t('city')}
        label={t('city')}
        hasError={Boolean(errors.city)}
        errorMessage={errors.city?.message}
        {...register('city', { required: t('required') })}
      />

      <StateZipGroup>
        <Select
          id="contact-form-state"
          ariaLabelledBy={t('state')}
          label={t('state')}
          options={stateOptions}
          defaultOption={
            initialValues?.state
              ? getDefaultStateOption(initialValues.state, stateOptions)
              : undefined
          }
          onSelectOption={(value) => {
            setValue('state', value, { shouldDirty: true, shouldTouch: true });
          }}
        />

        <TextInput
          id="contact-form-zip"
          ariaLabel={t('zip')}
          label={t('zip')}
          hasError={Boolean(errors.zip_code)}
          errorMessage={errors.zip_code?.message}
          {...register('zip_code', { required: t('required') })}
        />
      </StateZipGroup>
    </StyledForm>
  );
}

export default BillingFormModalBody;
