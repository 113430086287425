import axios, { AxiosError, AxiosPromise } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { E164Number } from 'libphonenumber-js/types';
import parsePhoneNumberFromString from 'libphonenumber-js';

import routes from 'routes';
import { GetUsersRequestBody } from 'legacy/types';
import { AddressFormType } from 'legacy/components/forms/AddressForm';
import { RolesKey } from 'modules/types/util';
import { AddressType, UserType } from 'api/types';
import { UserFormType } from 'legacy/components/forms/UserForm';
import { isHomeowner } from 'AppDependentRoutes/utils/checkUserRole';

export function getUser(id: string): AxiosPromise<UserType> {
  return axios.get(routes.user(id));
}

export function getUsers(body?: GetUsersRequestBody) {
  return axios.get(routes.users, { params: { ...body } });
}

type UnImpersonateUserResponse = {
  user: UserType;
  impersonator: UserType;
};

export async function unimpersonateUser(): AxiosPromise<UnImpersonateUserResponse> {
  await axios.post(routes.unimpersonate);
  const response = await axios.get(routes.me);

  return response;
}

export function impersonateUser(user_id: string) {
  return axios.post(routes.impersonate, { user_id });
}

export function updateUserPhone(user_id: string, phone_number: E164Number) {
  return axios.patch(routes.user(user_id), { phone_number });
}

export type UpdateUserRequestBody = {
  email?: string;
  phone_number?: E164Number;
  archived?: boolean;
  role?: RolesKey;
  address?: AddressType | AddressFormType; // TODO: remove AddressFormType when taking out legacy code
};

export function updateUser({ user_id, body }: { user_id: string; body: UpdateUserRequestBody }) {
  const updatedBody = {
    ...body,
    ...(body.phone_number
      ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
      : {}),
  };
  return axios.patch(routes.user(user_id), updatedBody);
}

type UseUpdateUserBody = {
  onSuccess?: (data: UserType, variables: { user_id: string; body: UpdateUserRequestBody }) => void;
  onError?: (error: AxiosError, variabes: { user_id: string; body: UpdateUserRequestBody }) => void;
};

export const useUpdateUser = ({
  onSuccess,
  onError,
}: UseUpdateUserBody): UseMutationResult<
  UserType,
  AxiosError,
  { user_id: string; body: UpdateUserRequestBody }
> =>
  useMutation(
    ({ user_id, body }) => {
      const updatedBody = {
        ...body,
        ...(body.phone_number
          ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
          : {}),
      };
      return axios.patch(routes.user(user_id), updatedBody).then((res) => res.data);
    },
    {
      onSuccess,
      onError,
    },
  );

type UseInviteUserBody = {
  onSuccess?: (data: UserType) => void;
  onError?: (error: AxiosError<ErrorResponse>) => void;
};

type ErrorResponse = {
  detail?: string | string[];
  default_code?: string;
  error_codes?: string;
};

export const useInviteUser = ({
  onSuccess,
  onError,
}: UseInviteUserBody): UseMutationResult<unknown, AxiosError<ErrorResponse>, UserFormType> =>
  useMutation(
    (body: UserFormType) => {
      const updatedBody = {
        ...body,
        is_admin_invite: !isHomeowner(body.role),
        ...(body.phone_number
          ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
          : {}),
      };

      return axios.post(routes.invite, updatedBody).then((res) => res.data);
    },
    {
      onSuccess,
      onError,
    },
  );

type UseImpersonateUserBody = {
  onSuccess?: (data: { user: UserType; impersonator: UserType }) => void;
  onError?: (error: AxiosError) => void;
};

export const useImpersonateUser = ({
  onSuccess,
  onError,
}: UseImpersonateUserBody): UseMutationResult<unknown, unknown, { user_id: string }> =>
  useMutation((body) => axios.post(routes.impersonate, body).then((res) => res.data), {
    onSuccess,
    onError,
  });

export const useResendClaimAccountEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}): UseMutationResult<unknown, unknown, { email: string }> =>
  useMutation(
    ({ email }) =>
      axios.post(routes.sendClaimAccountEmail, { email, is_resend: true }).then((res) => res.data),
    {
      onSuccess,
      onError,
    },
  );
