import React from 'react';
import { resolvePath } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FlagName } from 'api/types';
import pages from 'pages';
import { usePageName } from 'hooks/usePageName';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Layout } from 'shared/components/Layout/Layout';
import { AgreementsIcon } from 'shared/components/icons/AgreementsIcon';
import { AdminsIcon } from 'shared/components/icons/AdminsIcon';
import { StatisticsIcon } from 'shared/components/icons/StatisticsIcon';
import { FAQIcon } from 'shared/components/icons/FAQIcon';
import { BackfillIcon } from 'shared/components/icons/BackfillIcon';
import LayoutLegacy from 'legacy/views/Layout';

export function AdminLayout() {
  const theme = useTheme();
  const { currentPage } = usePageName();
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  const agreementsIcon = () => (
    <AgreementsIcon
      color={
        currentPage === 'AGREEMENTS'
          ? theme.colors.chartChartBlueSecondary2100
          : theme.colors.iconIconSecondaryGrey90
      }
    />
  );

  const adminsIcon = () => (
    <AdminsIcon
      color={
        currentPage === 'ADMINS'
          ? theme.colors.chartChartBlueSecondary2100
          : theme.colors.iconIconSecondaryGrey90
      }
    />
  );

  const statisticsIcon = () => (
    <StatisticsIcon
      color={
        currentPage === 'STATISTICS'
          ? theme.colors.chartChartBlueSecondary2100
          : theme.colors.iconIconSecondaryGrey90
      }
    />
  );

  const faqIcon = () => (
    <FAQIcon
      color={
        currentPage === 'MANAGE_FAQS'
          ? theme.colors.chartChartBlueSecondary2100
          : theme.colors.iconIconSecondaryGrey90
      }
    />
  );

  const backfillIcon = () => (
    <BackfillIcon
      color={
        currentPage === 'BACKFILL'
          ? theme.colors.chartChartBlueSecondary2100
          : theme.colors.iconIconSecondaryGrey90
      }
    />
  );

  const adminMenuOptions = [
    {
      label: 'Agreements',
      link: resolvePath(pages.AGREEMENTS, `/${pages.ADMIN}`),
      icon: agreementsIcon,
    },
    {
      label: 'Admins',
      link: resolvePath(pages.ADMINS, `/${pages.ADMIN}`),
      icon: adminsIcon,
    },
    {
      label: 'Statistics',
      link: resolvePath(pages.STATISTICS, `/${pages.ADMIN}`),
      icon: statisticsIcon,
    },
    {
      label: 'FAQ',
      link: resolvePath(pages.MANAGE_FAQS, `/${pages.ADMIN}`),
      icon: faqIcon,
    },
    {
      label: 'Backfill',
      link: resolvePath(pages.BACKFILL, `/${pages.ADMIN}`),
      icon: backfillIcon,
    },
  ];

  return adminV2 ? (
    <Layout hasNav menuOptions={adminMenuOptions} />
  ) : (
    <LayoutLegacy hasNav hasFooter />
  );
}

export default AdminLayout;
