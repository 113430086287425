import React from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FlagName } from 'api/types';
import FaqsView from 'legacy/views/admin/FaqsView';
import { ManageFaqs as ManageFaqsV2 } from 'admin/modules/manage-faqs/screens/ManageFaqs';

export function ManageFaqs() {
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  return adminV2 ? <ManageFaqsV2 /> : <FaqsView />;
}

export default ManageFaqs;
