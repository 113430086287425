import React from 'react';
import { IconProps } from './types';

export function ScreenCastIcon({ color }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.2222 3.33333H1.77778C1.53333 3.33333 1.33333 3.53333 1.33333 3.77778V6.475C1.11389 6.45556 0.891667 6.44444 0.666667 6.44444H0V3.77778C0 2.79722 0.797222 2 1.77778 2H14.2222C15.2028 2 16 2.79722 16 3.77778V12.6667C16 13.6472 15.2028 14.4444 14.2222 14.4444H8V13.7778C8 13.5528 7.98889 13.3306 7.96944 13.1111H14.2222C14.4667 13.1111 14.6667 12.9111 14.6667 12.6667V3.77778C14.6667 3.53333 14.4667 3.33333 14.2222 3.33333ZM0.666667 7.33333C4.225 7.33333 7.11111 10.2194 7.11111 13.7778C7.11111 14.1472 6.81389 14.4444 6.44444 14.4444C6.075 14.4444 5.77778 14.1472 5.77778 13.7778C5.77778 10.9556 3.48889 8.66667 0.666667 8.66667C0.297222 8.66667 0 8.36944 0 8C0 7.63056 0.297222 7.33333 0.666667 7.33333ZM0.888889 12.6667C1.12464 12.6667 1.35073 12.7603 1.51743 12.927C1.68413 13.0937 1.77778 13.3198 1.77778 13.5556C1.77778 13.7913 1.68413 14.0174 1.51743 14.1841C1.35073 14.3508 1.12464 14.4444 0.888889 14.4444C0.653141 14.4444 0.427049 14.3508 0.26035 14.1841C0.0936505 14.0174 0 13.7913 0 13.5556C0 13.3198 0.0936505 13.0937 0.26035 12.927C0.427049 12.7603 0.653141 12.6667 0.888889 12.6667ZM0 10.6667C0 10.2972 0.297222 10 0.666667 10C2.75278 10 4.44444 11.6917 4.44444 13.7778C4.44444 14.1472 4.14722 14.4444 3.77778 14.4444C3.40833 14.4444 3.11111 14.1472 3.11111 13.7778C3.11111 12.4278 2.01667 11.3333 0.666667 11.3333C0.297222 11.3333 0 11.0361 0 10.6667Z"
        fill={color}
      />
    </svg>
  );
}

export default ScreenCastIcon;
