import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Plus } from 'shared/components/icons';
import { Text } from 'shared/components/Text/Text';
import { Button } from 'shared/components/Button/Button';
import { ConfirmUserModalBodyContainer, UserCardsWrapper } from './ConfirmUserModalBody.styles';
import { UserCard, UserCardUserType } from '../UserCard/UserCard';

type Props = {
  account: string;
  primaryUser: UserCardUserType;
  secondaryUser?: UserCardUserType;
  onAddAdditionalUser: () => void;
  onEditUser: (userType: 'primary' | 'secondary') => void;
};

export function ConfirmUserModalBody({
  account,
  primaryUser,
  secondaryUser,
  onAddAdditionalUser,
  onEditUser,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.homeownerTransfer' });

  const hasSecondaryUser = Boolean(secondaryUser);

  return (
    <ConfirmUserModalBodyContainer>
      <Text as="p">
        {hasSecondaryUser ? (
          <Trans
            i18nKey="transferAgreementMultiple"
            t={t}
            values={{
              account,
              name: `${primaryUser.firstName} ${primaryUser.lastName}`,
              name2: `${secondaryUser?.firstName} ${secondaryUser?.lastName}`,
            }}
          />
        ) : (
          <Trans
            i18nKey="transferAgreementSingle"
            t={t}
            values={{
              account,
              name: `${primaryUser.firstName} ${primaryUser.lastName}`,
            }}
          />
        )}
      </Text>
      <UserCardsWrapper>
        <UserCard
          header={hasSecondaryUser ? t('primaryUser') : undefined}
          user={primaryUser}
          styleVariant="secondary"
          onEditUser={() => onEditUser('primary')}
        />

        {secondaryUser && (
          <UserCard
            header={t('secondaryUser')}
            user={secondaryUser}
            styleVariant="secondary"
            onEditUser={() => onEditUser('secondary')}
          />
        )}
      </UserCardsWrapper>
      {!hasSecondaryUser && (
        <Button
          styleVariant="tertiary"
          label={t('addAdditionalUser')}
          onClick={onAddAdditionalUser}
          Icon={Plus}
        />
      )}
    </ConfirmUserModalBodyContainer>
  );
}

export default ConfirmUserModalBody;
