import React from 'react';
import { DateRange } from 'api/system/utils/DateRange';
import { format } from 'date-fns';
import { FlyoutProps } from 'victory';
import { TooltipIcon } from 'shared/components/icons';
import { useTheme } from 'styled-components';
import { useBreakpoints } from 'hooks/useWindowSize';
import {
  getTooltipPosition,
  TOOLTIP_STANDARD_CHARS,
} from 'modules/system/utils/getTooltipPosition';
import { DateRangeZoomLevel } from 'api/types';
import { ToolTipTextContainer, Line, ToolTipkW, TooltipTime } from '../Chart.styles';
import { getChartStyles, getDayChartStyles } from '../getChartStyles';

export const valueFormatted = (value: number, unit: string) => `${value.toFixed(2)} ${unit}`;

export const timeFormatted = (time: Date, dateFormat: string = 'hh:mm a') =>
  format(time, dateFormat);

interface Props extends FlyoutProps {
  datum?: FlyoutProps['datum'] & {
    unit: string;
    value: number;
    time: Date;
    customTooltip?: string;
  };
  dateRange?: DateRange;
  tooltipDateFormatter?: (date: Date, dateRange: DateRange) => string;
  chartType: 'area' | 'bar';
}

export interface TooltipPosition {
  x: number;
  y: number;
}

export function ToolTip({ x, y, datum, dateRange, tooltipDateFormatter, chartType }: Props) {
  const theme = useTheme();
  const screenSize = useBreakpoints() || 'lg';

  const { time, unit, value, customTooltip } = datum!;
  const tooltipWidth = 150;

  const chartStyles =
    chartType === 'area'
      ? getDayChartStyles(theme, screenSize)
      : getChartStyles(theme, screenSize, dateRange?.zoomLevel || DateRangeZoomLevel.WEEK);

  const tooltipIconPosition = {
    x: x ? x - 14 : x,
    y: y ? y - 14 : y,
  };

  const tooltipTextPosition = getTooltipPosition({
    screenSize,
    dataPosition: { x: x || 0, y: y || 0 },
    chartType,
  });

  const tooltipValue = valueFormatted(value, unit);
  /* 
    if the tooltip value is longer than 8 characters (e.g. "123.45 kWh")
    we need to move the whole thing to make space
  */
  const additionalDigits = tooltipValue.length - TOOLTIP_STANDARD_CHARS;

  return (
    <g style={{ top: 0, position: 'relative' }}>
      <Line x1={x} x2={x} y1={chartStyles.tooltip.lineHeight} y2={chartStyles.tooltip.y2} />
      <foreignObject
        x={tooltipTextPosition.x - tooltipTextPosition.digitOffset * additionalDigits}
        y={tooltipTextPosition.y}
        width={tooltipWidth}
        height="44"
        style={{ overflow: 'visible' }}
      >
        <ToolTipTextContainer>
          {customTooltip ? (
            <TooltipTime>{customTooltip}</TooltipTime>
          ) : (
            <>
              <ToolTipkW>{tooltipValue}</ToolTipkW>
              {dateRange && tooltipDateFormatter && (
                <TooltipTime>{tooltipDateFormatter(time, dateRange)}</TooltipTime>
              )}
            </>
          )}
        </ToolTipTextContainer>
      </foreignObject>
      <foreignObject
        x={tooltipIconPosition.x}
        y={tooltipIconPosition.y}
        style={{ overflow: 'visible' }}
      >
        <TooltipIcon />
      </foreignObject>
    </g>
  );
}

export default ToolTip;
