import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import { Header } from 'shared/components/Header/Header';
import { MilestoneType } from 'modules/types';
import {
  AccountNumber,
  AccountWrapper,
  ContactSection,
  LoginSection,
  SectionTitle,
} from './Account.styles';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { LoginDetailsForm } from '../components/LoginDetailsForm/LoginDetailsForm';
import { ContactCard } from '../components/ContactCard/ContactCard';
import { LoginCard } from '../components/LoginCard/LoginCard';

export function Account() {
  const [updateContactInfo, setUpdateContactInfo] = useState(false);
  const [updateCredentials, setUpdateCredentials] = useState(false);

  const { t } = useTranslation();
  const { userResult, selectedAccount, refetch } = useUser();
  const user = userResult?.user;

  const canEditAccountInfo = Boolean(
    selectedAccount?.agreements.every(
      (agreement) =>
        agreement.current_milestone === MilestoneType.IN_SERVICE ||
        agreement.current_milestone === null,
    ),
  );

  const showContactForm = () => {
    setUpdateContactInfo(true);
  };

  const hideContactForm = () => {
    setUpdateContactInfo(false);
  };

  const showLoginForm = () => {
    setUpdateCredentials(true);
  };

  const hideLoginForm = () => {
    setUpdateCredentials(false);
  };

  const handleOnSave = async () => {
    refetch();
    hideContactForm();
  };

  return (
    <AccountWrapper>
      <Header pageName="ACCOUNT" title={t('account.account')} />

      {user ? (
        <>
          <AccountNumber as="h3">#{selectedAccount?.homeowner_account_id}</AccountNumber>

          <ContactSection>
            <SectionTitle as="h3">{t('account.contactInformation')}</SectionTitle>
            {updateContactInfo ? (
              <ContactForm
                user={user}
                onCancel={hideContactForm}
                onSave={handleOnSave}
                showAccountInfo={canEditAccountInfo}
              />
            ) : (
              <ContactCard user={user} onEdit={showContactForm} />
            )}
          </ContactSection>

          <LoginSection>
            <SectionTitle as="h3">{t('account.loginInformation')}</SectionTitle>

            {updateCredentials ? (
              <LoginDetailsForm user={user} onCancel={hideLoginForm} onSave={hideContactForm} />
            ) : (
              <LoginCard user={user} onEdit={showLoginForm} />
            )}
          </LoginSection>
        </>
      ) : null}
    </AccountWrapper>
  );
}

export default Account;
