import React from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';

import { UserType } from 'api/types';
import { Label, StaticTextWrapper, Value } from 'modules/account/components/Form.styles';
import { ActivityIcon } from 'shared/components/icons/ActivityIcon';
import {
  HeaderText,
  HeaderWrapper,
  IconWrapper,
  UserInfoCardWrapper,
} from '../UserInfoCard/UserInfoCard.styles';

type Props = {
  user: UserType;
};

export function ActivityCard({ user }: Props) {
  const theme = useTheme();

  return (
    <UserInfoCardWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <ActivityIcon color={theme.colors.iconIconPrimarySecondary1100} />
        </IconWrapper>
        <HeaderText as="h3">Activity</HeaderText>
      </HeaderWrapper>

      <StaticTextWrapper>
        <div>
          <Label as="p">Date Joined</Label>
          <Value as="p">{format(new Date(user.date_joined), 'MMM d, yyyy')}</Value>
        </div>

        <div>
          <Label as="p">Claimed Date</Label>
          <Value as="p">{format(new Date(user.claimed_date), 'MMM d, yyyy')}</Value>
        </div>

        <div>
          <Label as="p">Last Password Reset Date</Label>
          <Value as="p">{format(new Date(user.last_password_reset_date), 'MMM d, yyyy')}</Value>
        </div>

        <div>
          <Label as="p">Archive Status</Label>
          <Value as="p">{user.archived.toString()}</Value>
        </div>
      </StaticTextWrapper>
    </UserInfoCardWrapper>
  );
}

export default ActivityCard;
