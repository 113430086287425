import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const TableCellUserStyled = styled.button`
  display: flex;
  flex-direction: column;
  gap: 6px;

  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const NameText = styled(Text)`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const EmailText = styled(Text)`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const ClaimedText = styled(EmailText)<{ $claimed: boolean }>`
  font-family: Avenir;
  font-weight: 500;
  color: ${({ $claimed, theme }) =>
    $claimed
      ? theme.colors.chartChartGreenPressedFunction5100
      : theme.colors.chartChartMagentaPressedAccent1160};
`;
