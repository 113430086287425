import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const FaqCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 12px;
  max-width: 857px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
  box-shadow: ${({ theme }) => theme.elevations.mid};
`;

export const Header = styled.div<{ $isHidden: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: ${({ $isHidden, theme }) =>
    $isHidden ? theme.colors.backgroundSecondaryGrey05 : theme.colors.cardElevationMidWhite};
  box-shadow: ${({ theme }) => theme.elevations.mid};
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeaderText = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Question = styled(Text)`
  font-family: Avenir;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Answer = styled(Text)`
  font-family: Avenir;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 21px 24px;
  gap: 16px;
  background: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};
  box-shadow: ${({ theme }) => theme.elevations.extraSunken};
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Tag = styled.div<{ $isHidden: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 8px 8px 12px;
  border-radius: 4px;
  border: 1px solid
    ${({ $isHidden, theme }) =>
      $isHidden
        ? theme.colors.strokeAccentGreyStrongerGrey50
        : theme.colors.strokeInteractionActiveSecondary2100};
  width: fit-content;
`;

export const TagText = styled(Text)<{ $isHidden: boolean }>`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ $isHidden, theme }) =>
    $isHidden ? theme.colors.textSecondaryGrey90 : theme.colors.textLinkLinkVisitedFunction4100};
`;

export const RemoveButton = styled.button<{ $isHidden: boolean }>`
  display: flex;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
