import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WeekChartDataProvider from 'legacy/components/MonitoringCard/data/WeekChartDataProvider';
import MonthChartDataProvider from 'legacy/components/MonitoringCard/data/MonthChartDataProvider';
import YearChartDataProvider from 'legacy/components/MonitoringCard/data/YearChartDataProvider';
import { DateRangeZoomLevel, TabType } from 'legacy/types';
import { DateRange } from 'legacy/components/MonitoringCard/utils/createDateRange';
import { DayChart } from './Chart/DayChart';
import { EnergyChart } from './Chart/EnergyChart';
import { Container } from './MonitoringChartStyles';
import { Tabs } from './Tabs';
import { DatePicker } from './DatePicker';

export const getTabValues = () => Object.values(DateRangeZoomLevel).map((tab) => tab as string);

type Props = {
  dateRange: DateRange | null;
  systemId: string;
  systemCapacity?: number;
  currentDate: Date;
  onDateChange: (date: Date) => void;
  onZoomLevelChange: (zoomLevel: DateRangeZoomLevel) => void;
};

export function MonitoringChart({
  dateRange,
  systemId,
  systemCapacity,
  currentDate,
  onDateChange,
  onZoomLevelChange,
}: Props) {
  const [zoomLevel, setZoomLevel] = useState(DateRangeZoomLevel.DAY);

  const handleTabClick = (value: string) => {
    if (value === TabType.DAY) {
      onDateChange(new Date());
    }
    setZoomLevel(value as DateRangeZoomLevel);
    onZoomLevelChange(value as DateRangeZoomLevel);
  };

  const { t } = useTranslation();

  if (!dateRange) return null;

  return (
    <Container>
      <Tabs tabValues={getTabValues()} onClick={handleTabClick} />
      <DatePicker
        timeFrame={zoomLevel}
        currentDate={currentDate}
        onDateChange={onDateChange}
        dateRange={{ newest: new Date(), oldest: new Date('2020-01-01') }} // TODO: this range is arbitrary because we don't know the overall range for which a system will have data
      />

      <>
        {dateRange.zoomLevel === DateRangeZoomLevel.DAY && (
          <DayChart dateRange={dateRange} systemId={systemId} systemCapacity={systemCapacity} />
        )}
        {dateRange.zoomLevel === DateRangeZoomLevel.WEEK && (
          <EnergyChart
            dataProvider={new WeekChartDataProvider()}
            dateRange={dateRange}
            systemId={systemId}
          />
        )}
        {dateRange.zoomLevel === DateRangeZoomLevel.MONTH && (
          <EnergyChart
            dataProvider={new MonthChartDataProvider(t)}
            dateRange={dateRange}
            systemId={systemId}
          />
        )}
        {dateRange.zoomLevel === DateRangeZoomLevel.YEAR && (
          <EnergyChart
            dataProvider={new YearChartDataProvider()}
            dateRange={dateRange}
            systemId={systemId}
          />
        )}
      </>
    </Container>
  );
}

export default MonitoringChart;
