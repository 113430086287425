import React, { useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { CalendarIcon } from 'shared/components/icons';
import { DateSelectorWrapper, DateHeading } from './DateSelector.styles';
import { YearPicker } from './YearPicker';
import { MonthPicker } from './MonthPicker';
import { WeekPicker } from './WeekPicker';
import { DayPicker } from './DayPicker';
import { getHeadingText } from './DateSelector.utils';

type Props = {
  timescale: string;
  timescaleOptions: Record<string, string>;
  currentDate: Date;
  selectedDate: Date;
  onDateChange: MonthChangeEventHandler;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dataRange: { oldest: Date; newest: Date };
  inServiceDate: Date;
};

export function DateSelector({
  timescale,
  timescaleOptions,
  currentDate,
  selectedDate,
  onDateChange,
  dataRange,
  inServiceDate,
}: Props) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((a) => !a);
  };

  const handleCloseModal = () => {
    setIsActive(false);
  };

  const modalRef = useOutsideClick(handleCloseModal);
  const orderedTimescaleOptions = [
    timescaleOptions.day,
    timescaleOptions.week,
    timescaleOptions.month,
    timescaleOptions.year,
  ];

  return (
    <div ref={modalRef}>
      <DateHeading onClick={handleClick}>
        <CalendarIcon />
        <p>{getHeadingText(orderedTimescaleOptions.indexOf(timescale), selectedDate)}</p>
      </DateHeading>
      <DateSelectorWrapper $isActive={isActive}>
        {timescale === timescaleOptions.year && (
          <YearPicker
            onDateChange={onDateChange}
            selectedDate={selectedDate}
            onCloseModal={handleCloseModal}
            dateRange={dataRange}
            inServiceDate={inServiceDate}
          />
        )}
        {timescale === timescaleOptions.month && (
          <MonthPicker
            onDateChange={onDateChange}
            selectedDate={selectedDate}
            onCloseModal={handleCloseModal}
            dateRange={dataRange}
            inServiceDate={inServiceDate}
          />
        )}
        {timescale === timescaleOptions.week && (
          <WeekPicker
            currentDate={currentDate}
            selectedDate={selectedDate}
            onDateChange={onDateChange}
            onCloseModal={handleCloseModal}
            dateRange={dataRange}
            inServiceDate={inServiceDate}
          />
        )}
        {timescale === timescaleOptions.day && (
          <DayPicker
            currentDate={currentDate}
            selectedDate={selectedDate}
            onDateChange={onDateChange}
            onCloseModal={handleCloseModal}
            dateRange={dataRange}
            inServiceDate={inServiceDate}
          />
        )}
      </DateSelectorWrapper>
    </div>
  );
}

export default DateSelector;
