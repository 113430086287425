import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function CircleInfoIcon({ color, size = 'small' }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;
  const dimensions = size === 'small' ? '16' : '24';

  return (
    <svg
      width={dimensions}
      height={dimensions}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8.27614 6 8.5 6.22386 8.5 6.5V11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5V6.5C7.5 6.22386 7.72386 6 8 6Z"
        fill={fillColor}
      />
      <path
        d="M8.5 4.5C8.5 4.77614 8.27614 5 8 5C7.72386 5 7.5 4.77614 7.5 4.5C7.5 4.22386 7.72386 4 8 4C8.27614 4 8.5 4.22386 8.5 4.5Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default {};
