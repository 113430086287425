import React from 'react';

export default function AutopaySuccessIcon() {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="64" height="64" rx="32" stroke="#458116" />
      <path
        d="M42.7785 25.9585C43.0738 26.2338 43.0738 26.6867 42.7785 26.962L30.0292 39.0402C29.7386 39.3199 29.2605 39.3199 28.9699 39.0402L22.2196 32.6459C21.9268 32.3706 21.9268 31.9176 22.2196 31.6423C22.5123 31.3626 22.9871 31.3626 23.2801 31.6423L29.4996 37.5349L41.7192 25.9585C42.0098 25.6805 42.4879 25.6805 42.7785 25.9585Z"
        fill="#458116"
      />
    </svg>
  );
}
