import React, { useEffect, useMemo, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { format, startOfMonth } from 'date-fns';
import { capitalize } from 'lodash';
import { CustomPicker } from './CustomPicker';
import { CustomPickerButton } from './DateSelector.styles';

interface Props {
  onDateChange: MonthChangeEventHandler;
  selectedDate: Date;
  onCloseModal: () => void;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dateRange: { oldest: Date; newest: Date };
  inServiceDate?: Date;
}

export function MonthPicker({
  onDateChange,
  selectedDate,
  onCloseModal,
  dateRange,
  inServiceDate,
}: Props) {
  const [selectedMonth, setSelectedMonth] = useState<number>(selectedDate.getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(selectedDate.getFullYear());
  const [displayedYear, setDisplayedYear] = useState(selectedDate.getFullYear());
  const [isEarliestYear, setIsEarliestYear] = useState(false);
  const [isLatestYear, setIsLatestYear] = useState(false);

  const months = useMemo(() => {
    const monthsArr = [];
    for (let i = 0; i < 12; i += 1) {
      monthsArr.push(capitalize(format(new Date().setMonth(i), 'LLLL')));
    }
    return monthsArr;
  }, []);

  useEffect(() => {
    const earliestYear = inServiceDate
      ? inServiceDate.getFullYear()
      : dateRange.oldest.getFullYear();
    setIsEarliestYear(earliestYear === displayedYear);
    setIsLatestYear(new Date().getFullYear() === displayedYear);
    if (earliestYear <= displayedYear && displayedYear <= selectedDate.getFullYear()) {
      setSelectedMonth(selectedDate.getMonth());
    } else {
      setSelectedMonth(-1);
    }
  }, [selectedDate, displayedYear, dateRange, inServiceDate]);

  const onMonthClick = (month: number) => {
    setSelectedMonth(month);
    setSelectedYear(displayedYear);
  };

  const onPrevClick = () => {
    setDisplayedYear(new Date(displayedYear - 1, 0).getFullYear());
  };

  const onNextClick = () => {
    setDisplayedYear(new Date(displayedYear + 1, 0).getFullYear());
  };

  const onOkClick = () => {
    if (selectedMonth > -1) {
      onDateChange(new Date(displayedYear, selectedMonth));
    }
    onCloseModal();
  };

  const renderMonthPickerGrid = () =>
    months.map((month: string, idx: number) => {
      let disableButton = false;
      const monthDate = new Date(displayedYear, idx);
      if (monthDate > new Date() || (inServiceDate && monthDate < startOfMonth(inServiceDate)))
        disableButton = true;
      return (
        <CustomPickerButton
          key={month}
          selected={month === months[selectedMonth] && displayedYear === selectedYear}
          onClick={() => onMonthClick(idx)}
          disabled={disableButton}
          $timeScale="month"
        >
          {month.substring(0, 3)}
        </CustomPickerButton>
      );
    });

  return (
    months && (
      <CustomPicker
        header={`${
          displayedYear === selectedYear ? `${months[selectedMonth]} ` : ''
        }${displayedYear}`}
        items={renderMonthPickerGrid()}
        onCloseModal={onCloseModal}
        onPrevClick={isEarliestYear ? undefined : onPrevClick}
        onNextClick={isLatestYear ? undefined : onNextClick}
        onOkClick={onOkClick}
        size="small"
        timeScale="month"
      />
    )
  );
}

export default MonthPicker;
