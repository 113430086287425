import React from 'react';
import { useTranslation } from 'react-i18next';

import { MilestoneType, WelcomeChecklistStatusType } from 'modules/types';
import { StatusPill } from 'shared/components/StatusPill/StatusPill';
import { TThemeColorsKey } from 'shared/design-system/theme/colors/TThemeColors';
import {
  isWelcomeChecklistFailed,
  isWelcomeChecklistRequired,
} from 'modules/status/utils/getWelcomeChecklistStatus';
import { Container, ProjectStatusText, StatusGroup, StatusText } from './ProjectStatus.styles';

export type CurrentMilestoneType = MilestoneType | 'CANCELED';

interface Props {
  currentMilestone: CurrentMilestoneType;
  welcomeChecklistStatus: WelcomeChecklistStatusType;
}

export function ProjectStatus({ currentMilestone, welcomeChecklistStatus }: Props) {
  const { t } = useTranslation();

  const actionRequired =
    (currentMilestone === MilestoneType.CONTRACT_SIGNED &&
      isWelcomeChecklistFailed(welcomeChecklistStatus)) ||
    isWelcomeChecklistRequired(welcomeChecklistStatus);

  const milestoneDisplayMap = {
    [MilestoneType.CONTRACT_SIGNED]: {
      label: actionRequired ? t('status.actionRequired') : t('status.qualification'),
      copy: t('earlyAccess.contractSignedStatus'),
    },
    [MilestoneType.PROJECT_QUALIFICATION_COMPLETED]: {
      label: t('status.installation'),
      copy: t('earlyAccess.projectQualificationStatus'),
    },
    [MilestoneType.INSTALLATION_COMPLETED]: {
      label: t('status.systemOperational'),
      copy: t('earlyAccess.installationCompleteStatus'),
    },
    [MilestoneType.IN_SERVICE]: {
      label: t('status.inService'),
      copy: t('earlyAccess.inServiceStatus'),
    },
    CANCELED: { label: t('status.canceled'), copy: t('earlyAccess.yourProjectCanceled') },
  };

  let pillBackgroundColor: TThemeColorsKey = 'backgroundSystemSuccessPrimary220';

  if (actionRequired) {
    pillBackgroundColor = 'backgroundSystemWarnFunction220';
  } else if (currentMilestone === 'CANCELED') {
    pillBackgroundColor = 'backgroundSystemErrorFunction120';
  }

  return (
    <Container>
      <StatusGroup>
        <ProjectStatusText as="h3">{t('earlyAccess.projectStatus')}:</ProjectStatusText>
        <StatusPill
          text={milestoneDisplayMap[currentMilestone].label}
          bgColor={pillBackgroundColor}
        />
      </StatusGroup>
      <StatusText as="body">{milestoneDisplayMap[currentMilestone]?.copy}</StatusText>
    </Container>
  );
}

export default ProjectStatus;
