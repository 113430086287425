import { TFunction } from 'i18next';

export const validateCity = (value: string, t: TFunction) => {
  // Check for empty value
  if (!value || value.trim() === '') {
    return t('account.invalidCity');
  }

  // Remove double spaces
  if (/\s\s+/.test(value)) {
    return t('account.invalidCity');
  }

  // Check for only spaces
  if (value.trim() === '') {
    return t('account.invalidCity');
  }

  // Check for non-alphabetic characters only
  if (!/[a-zA-Z]/.test(value)) {
    return t('account.invalidCity');
  }

  // Single-character entries
  if (value.length === 1) {
    return t('account.invalidCity');
  }

  // Disallowed special characters
  const specialChars = /[*(),.“”:;@&]/;
  if (specialChars.test(value)) {
    return t('account.invalidCity');
  }

  // No purely numerical characters
  if (/^\d+$/.test(value)) {
    return t('account.invalidCity');
  }

  // If all validations pass
  return true;
};

export default validateCity;
