import React from 'react';

import { CellText, TableCellStringStyled } from './TableCellString.styles';

type Props = {
  text: string;
  highlightRed?: boolean;
};

export function TableCellString({ text, highlightRed }: Props) {
  return (
    <TableCellStringStyled>
      <CellText as="p" $highlightRed={highlightRed}>
        {text}
      </CellText>
    </TableCellStringStyled>
  );
}

export default TableCellString;
