import React from 'react';

import AgreementTableView from 'legacy/views/admin/AgreementTableView';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Agreements as AgreementsV2 } from 'admin/modules/agreements/screens/Agreements';
import { FlagName } from 'api/types';

export function Agreements() {
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  return adminV2 ? <AgreementsV2 /> : <AgreementTableView />;
}

export default Agreements;
