import React from 'react';
import { Button } from 'legacy/components/common/design-system';
import { Column, Row, Message } from 'legacy/components/common/myeverbright';
import { MessageType } from 'legacy/types';
import { ButtonGroup, VerticalSpacer } from './UserDetailStyles';

type Props = {
  items: { label: string; value: string | React.ReactElement; id: string }[];
  archived: boolean;
  onChangeArchived?: (e: React.SyntheticEvent) => void;
  message?: MessageType | null;
  children?: React.ReactElement;
  enableArchive: boolean;
  onClickTransferAccount?: () => void;
};

export default function InfoCard({
  items,
  archived,
  onChangeArchived,
  message,
  children,
  enableArchive,
  onClickTransferAccount,
}: Props) {
  return (
    <div>
      {message && <Message severity={message.type}>{message.message}</Message>}

      <Row $flexWrap="wrap">
        {items.map((item) => (
          <Column key={`infocard-${item.id}`} $width="25%">
            <div>
              <div className="label">{item.label}</div>
              <p>{item.value}</p>
            </div>
          </Column>
        ))}
      </Row>
      {enableArchive ? (
        <ButtonGroup>
          <Button
            label={archived ? 'Unarchive' : 'Archive'}
            styleVariant="tertiary"
            maxWidth="64px"
            onClick={onChangeArchived}
          />

          {Boolean(onClickTransferAccount) && (
            <Button label="Transfer Account" onClick={onClickTransferAccount} />
          )}
        </ButtonGroup>
      ) : (
        <VerticalSpacer $height="10px" />
      )}
      {children}
    </div>
  );
}
