export const getEnv = (key: string) => {
  const val = import.meta.env[key];
  if (val === undefined) {
    throw new Error(`${key} should not be undefined`);
  } else {
    return val;
  }
};

export default getEnv;
