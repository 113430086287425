import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { E164Number } from 'libphonenumber-js/types';
import { useSearchParams } from 'react-router-dom';

import { UserFormType } from 'legacy/components/forms/UserForm';
import { USER_ROLES } from 'legacy/constants';

import { AccountType, UserType } from 'api/types';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import { ArrowLeftIcon } from 'shared/components/icons';
import { TransferAgreementModalBody } from '../../components/TransferAgreementModalBody/TransferAgreementModalBody';
import {
  UserFormModalBody,
  IFormInput as UserFormInputType,
} from '../../components/UserFormModalBody/UserFormModalBody';
import {
  BillingFormModalBody,
  NEW_BILLING_FORM_MODAL_ID,
} from '../../components/BillingFormModalBody/BillingFormModalBody';
import { ConfirmUserModalBody } from '../../components/ConfirmUserModalBody/ConfirmUserModalBody';
import { UserCardUserType } from '../../components/UserCard/UserCard';

export enum TransferModalEnum {
  NEW_USER = 'NEW_USER',
  NEW_BILLING = 'NEW_BILLING',
  CONFIRM_USER = 'CONFIRM_USER',
}

type Props = {
  isOpen: boolean;
  transferUser: UserType;
  transferAccount: AccountType;
  onClickTransferAgreement: (newUsers: UserFormType[]) => void;
  onClose: () => void;
};

export function HomeownerTransferModal({
  isOpen,
  transferUser,
  transferAccount,
  onClickTransferAgreement,
  onClose,
}: Props) {
  const [modal, setModal] = useState<TransferModalEnum | null>(null);
  const [newUsers, setNewUsers] = useState<{
    primary?: UserCardUserType;
    secondary?: UserCardUserType;
  } | null>(null);

  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const addUserParam = searchParams.get('addUser');
  const editUserParam = searchParams.get('editUser');

  const handleAddUser = useCallback(() => {
    searchParams.set('addUser', 'secondary');
    setSearchParams(searchParams);
    setModal(TransferModalEnum.NEW_USER);
  }, [searchParams, setSearchParams]);

  const handleEditUser = useCallback(
    (type: 'primary' | 'secondary') => {
      searchParams.set('editUser', type);
      setSearchParams(searchParams);
      setModal(TransferModalEnum.NEW_USER);
    },
    [searchParams, setSearchParams],
  );

  const handleClose = useCallback(() => {
    searchParams.delete('addUser');
    searchParams.delete('editUser');
    setSearchParams(searchParams);
    setModal(null);
    setNewUsers(null);

    onClose();
  }, [onClose, searchParams, setSearchParams]);

  const modalEl = useMemo(() => {
    const backButtonIcon = () => (
      <ArrowLeftIcon color={theme.colors.buttonButtonPrimarySecondary2100} />
    );

    let title = t('admin.homeownerTransfer.transferAgreement');

    let body = (
      <TransferAgreementModalBody
        agreement={transferAccount.agreements[0].homeowner_contract_id}
        name={`${transferUser.first_name} ${transferUser.last_name}`}
      />
    );

    let ctaButtons: CtaButtons | undefined = {
      tertiary: {
        label: t('admin.homeownerTransfer.noCancel'),
        onClick: handleClose,
      },
      primary: {
        type: 'button',

        label: t('admin.homeownerTransfer.yesCreate'),
        onClick: () => {
          setModal(TransferModalEnum.NEW_USER);
        },
      },
    };

    if (modal === TransferModalEnum.NEW_USER) {
      let initialUserValues: UserFormInputType | undefined;

      if (addUserParam) {
        initialUserValues = newUsers?.secondary
          ? {
              first_name: newUsers.secondary.firstName,
              last_name: newUsers.secondary.lastName,
              email: newUsers.secondary.email,
              phone_number: newUsers.secondary.phoneNumber as E164Number,
            }
          : undefined;
      } else if (editUserParam && newUsers) {
        const user = newUsers[editUserParam as 'primary' | 'secondary']!;
        initialUserValues = {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          phone_number: user.phoneNumber as E164Number,
        };
      } else if (newUsers?.primary) {
        initialUserValues = {
          first_name: newUsers.primary.firstName,
          last_name: newUsers.primary.lastName,
          email: newUsers.primary.email,
          phone_number: newUsers.primary.phoneNumber as E164Number,
        };
      }
      title = t('admin.homeownerTransfer.newUserContact');
      body = (
        <UserFormModalBody
          initialValues={initialUserValues}
          onBack={() => {
            if (addUserParam || editUserParam) {
              setModal(TransferModalEnum.CONFIRM_USER);
              searchParams.delete('addUser');
              searchParams.delete('editUser');
              setSearchParams(searchParams);
            } else {
              setModal(null);
            }
          }}
          onSubmit={(formData) => {
            const users = {
              ...newUsers,
            };

            const user = {
              firstName: formData.first_name,
              lastName: formData.last_name,
              email: formData.email,
              phoneNumber: formData.phone_number,
            };

            if (addUserParam) {
              users.secondary = user;
            } else if (editUserParam) {
              users[editUserParam as 'primary' | 'secondary'] = user;
            } else {
              users.primary = user;
            }

            setNewUsers(users);

            setModal(TransferModalEnum.NEW_BILLING);
          }}
        />
      );
      ctaButtons = undefined;
    }

    if (modal === TransferModalEnum.NEW_BILLING && newUsers?.primary) {
      const systemAddress = transferAccount.agreements[0].system.address;
      let initialAddress = newUsers.primary.address || transferAccount.agreements[0].system.address;
      if (editUserParam) {
        initialAddress =
          newUsers[editUserParam as 'primary' | 'secondary']?.address || systemAddress;
      } else if (addUserParam) {
        initialAddress = newUsers.secondary?.address || systemAddress;
      }

      title = t('admin.homeownerTransfer.newBillingAddress');
      body = (
        <BillingFormModalBody
          initialValues={initialAddress}
          onSubmit={(formData) => {
            const users = {
              ...newUsers,
            };

            if (addUserParam && users.secondary) {
              users.secondary = {
                ...users.secondary,
                address: formData,
              };
            } else if (editUserParam) {
              if (editUserParam === 'primary' && users.primary) {
                users.primary = {
                  ...users.primary,
                  address: formData,
                };
              } else if (editUserParam === 'secondary' && users.secondary) {
                users.secondary = {
                  ...users.secondary,
                  address: formData,
                };
              }
            } else if (users.primary) {
              users.primary = {
                ...users.primary,
                address: formData,
              };
            }

            setNewUsers(users);

            setModal(TransferModalEnum.CONFIRM_USER);
            if (addUserParam || editUserParam) {
              searchParams.delete('addUser');
              searchParams.delete('editUser');
              setSearchParams(searchParams);
            }
          }}
        />
      );

      ctaButtons = {
        secondary: {
          label: t('cta.back'),
          onClick: () => {
            setModal(TransferModalEnum.NEW_USER);
          },
          Icon: backButtonIcon,
        },
        primary: {
          formId: NEW_BILLING_FORM_MODAL_ID,
          type: 'submit',
          label: t('cta.next'),
        },
      };
    }

    if (modal === TransferModalEnum.CONFIRM_USER && newUsers?.primary) {
      title = t('admin.homeownerTransfer.confirmUser');
      body = (
        <ConfirmUserModalBody
          account={transferAccount.agreements[0].homeowner_contract_id}
          primaryUser={newUsers.primary}
          secondaryUser={newUsers.secondary}
          onAddAdditionalUser={handleAddUser}
          onEditUser={handleEditUser}
        />
      );
      ctaButtons = {
        tertiary: {
          label: t('cta.cancel'),
          onClick: handleClose,
        },
        primary: {
          label: t('admin.homeownerTransfer.transferAgreementCta'),
          onClick: () => {
            const users = [];

            if (newUsers.primary) {
              users.push({
                first_name: newUsers?.primary?.firstName,
                last_name: newUsers?.primary?.lastName,
                email: newUsers?.primary?.email,
                phone_number: newUsers?.primary?.phoneNumber,
                address: newUsers?.primary?.address,
                role: USER_ROLES.HOMEOWNER,
              });
            }

            if (newUsers.secondary) {
              users.push({
                first_name: newUsers.secondary.firstName,
                last_name: newUsers.secondary.lastName,
                email: newUsers.secondary.email,
                phone_number: newUsers.secondary.phoneNumber,
                address: newUsers.secondary.address,
                role: USER_ROLES.HOMEOWNER,
              });
            }
            onClickTransferAgreement(users);
          },
        },
      };
    }

    return { title, body, ctaButtons };
  }, [
    addUserParam,
    editUserParam,
    handleAddUser,
    handleClose,
    handleEditUser,
    modal,
    newUsers,
    onClickTransferAgreement,
    searchParams,
    setSearchParams,
    t,
    theme.colors.buttonButtonPrimarySecondary2100,
    transferAccount.agreements,
    transferUser.first_name,
    transferUser.last_name,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      fitContent
      title={modalEl.title}
      styleVariant="tertiary"
      contentLabel={`${modalEl.title} Modal`}
      ctaButtons={modalEl.ctaButtons}
      ctaSpacedBetween
      onRequestClose={handleClose}
    >
      {modalEl.body}
    </Modal>
  );
}

export default HomeownerTransferModal;
