import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { BodyBoldText, BodyText, OrderedList } from '../AuthCopyAckModal.styles';

type Props = {
  confirmed: boolean;
  checkboxErrorMessage: string | null;
  onChangeConfirm: () => void;
};
export function AuthCopyAckBody({ confirmed, checkboxErrorMessage, onChangeConfirm }: Props) {
  const { t } = useTranslation();

  const checkboxRef = useRef<HTMLInputElement | null>(null);

  const body4List: string[] = t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body4List', {
    returnObjects: true,
  });

  useEffect(() => {
    if (checkboxRef && checkboxErrorMessage) {
      checkboxRef.current?.scrollIntoView();
    }
  }, [checkboxErrorMessage]);

  return (
    <>
      <BodyText as="p" $margin="24px 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body1')}
      </BodyText>

      <BodyText as="p" $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body2')}
      </BodyText>

      <BodyBoldText as="p">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body3Title')}
      </BodyBoldText>
      <BodyText as="p" $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body3')}
      </BodyText>

      <BodyBoldText as="p">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body4Title')}
      </BodyBoldText>

      <OrderedList>
        {Array.isArray(body4List) &&
          body4List?.map((item: string) => (
            <li key={item}>
              <BodyText as="p">{item}</BodyText>
            </li>
          ))}
      </OrderedList>

      <BodyBoldText as="p">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body5Title')}
      </BodyBoldText>
      <BodyText as="p" $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body5')}
      </BodyText>

      <Checkbox
        forwardRef={checkboxRef}
        isChecked={confirmed}
        isRequired
        onChange={onChangeConfirm}
        text={t('acknowledgeAuthoritativeCopy.acknowledgeCopy.checkbox')}
        margin="24px 0"
        hint={checkboxErrorMessage || undefined}
        error={Boolean(checkboxErrorMessage)}
      />

      <BodyText as="p" $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body6')}
      </BodyText>

      <BodyText as="p" $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body7')}
      </BodyText>

      <BodyText as="p">
        <Trans i18nKey="acknowledgeAuthoritativeCopy.acknowledgeCopy.body8">
          Stay sunny,
          <br />
          EverBright
        </Trans>
      </BodyText>
    </>
  );
}

export default AuthCopyAckBody;
