import axios, { AxiosError, AxiosPromise } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

import { FaqType, TagType } from 'api/types';
import { routes } from '../../routes';

export type FaqRequestBody = Omit<FaqType, 'id' | 'tags' | 'url_slug'>;

export type TagRequestBody = {
  faqId: string;
  tagId: string;
};

export function getFAQs(): AxiosPromise<FaqType[]> {
  return axios.get(routes.faqs);
}

export function getTags() {
  return axios.get(routes.tags);
}

export function addTag({ faqId, tagId }: TagRequestBody) {
  return axios.post(routes.faqTag(faqId), {}, { params: { tag_id: tagId } });
}

export function removeTag({ faqId, tagId }: TagRequestBody) {
  return axios.delete(routes.faqTag(faqId), { params: { tag_id: tagId } });
}

type UseFaqResultBody = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

type UseCreateTagsBody = {
  onSuccess?: (tag: TagType[]) => void;
  onError?: (error: AxiosError) => void;
};

export const useAddFaq = ({
  onSuccess,
  onError,
}: UseFaqResultBody): UseMutationResult<unknown, AxiosError, FaqRequestBody> =>
  useMutation((body) => axios.post(routes.faqs, body).then((res) => res.data), {
    onSuccess,
    onError,
  });

export const useEditFaq = ({
  onSuccess,
  onError,
}: UseFaqResultBody): UseMutationResult<
  unknown,
  AxiosError,
  { id: string; body: FaqRequestBody }
> =>
  useMutation(({ id, body }) => axios.patch(routes.faq(id), body).then((res) => res.data), {
    onSuccess,
    onError,
  });

export const useAddTag = ({
  onSuccess,
  onError,
}: UseFaqResultBody): UseMutationResult<unknown, AxiosError, TagRequestBody> =>
  useMutation(
    ({ faqId, tagId }) =>
      axios.post(routes.faqTag(faqId), {}, { params: { tag_id: tagId } }).then((res) => res.data),
    {
      onSuccess,
      onError,
    },
  );

export const useRemoveTag = ({
  onSuccess,
  onError,
}: UseFaqResultBody): UseMutationResult<unknown, AxiosError, TagRequestBody> =>
  useMutation(
    ({ faqId, tagId }) =>
      axios.delete(routes.faqTag(faqId), { params: { tag_id: tagId } }).then((res) => res.data),
    {
      onSuccess,
      onError,
    },
  );

export const useCreateTags = ({
  onSuccess,
  onError,
}: UseCreateTagsBody): UseMutationResult<TagType[], AxiosError, { tags: string[] }> =>
  useMutation(
    ({ tags }) => {
      const tagsPromise = Promise.all(
        tags.map((tag) => axios.post(routes.tags, { tag }).then((res) => res.data)),
      );
      return tagsPromise;
    },
    {
      onSuccess,
      onError,
    },
  );
