import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AgreementDetailsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

export const SystemSection = styled(FlexContainer)`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
`;

export const Title = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Label = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;

export const Value = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;
