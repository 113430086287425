import { AccountType, AgreementType } from 'api/types';

export const authCopyAckNeeded = (account?: AccountType) => {
  let isNeeded = false;
  let agreements: AgreementType[] = [];

  if (!account) return { isNeeded, agreements };

  agreements = account.agreements.filter(
    (agreement) => agreement.authoritative_copy_acknowledged === false,
  );

  isNeeded = Boolean(agreements.length);

  return { isNeeded, agreements };
};

export default authCopyAckNeeded;
