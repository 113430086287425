import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AxiosError } from 'axios';
import { UserType } from 'api/types';
import { useUser } from 'hooks/useUser';
import { sendAdminClaimAccountEmail, sendHomeownerClaimAccountEmail } from 'api/accounts';
import { Table } from 'shared/components/Table/Table';
import { PenToSquareIcon } from 'shared/components/icons/PenToSquareIcon';
import { TableCellString } from 'shared/components/Table/TableCell/TableCellString/TableCellString';
import { isAdmin, isHomeowner } from 'AppDependentRoutes/utils/checkUserRole';
import { CircleCheckIcon } from 'shared/components/icons/CircleCheckIcon';
import { toast } from 'shared/components/Toast/Toast';
import { Button } from 'shared/components/Button/Button';
import { tableDataReducer } from '../../utils/tableDataReducer';
import { formatDateShort } from '../../utils/formatDateShort';
import { ContentWrapper, NameText } from './AdminsTable.styles';

type Props = {
  internalUsers: ReadonlyArray<UserType>;
  onClickEditRole: (user: UserType) => void;
};

export function AdminsTable({ internalUsers, onClickEditRole }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(tableDataReducer, {
    tableData: [],
  });

  const { tableData } = state;
  const { userResult } = useUser();
  const currentUser = userResult?.user;

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', tableData: internalUsers });
  }, [internalUsers]);

  const onResendClaimAccountEmail = async (user: UserType) => {
    try {
      if (isHomeowner(user.role)) {
        await sendHomeownerClaimAccountEmail(user.email, true);
      } else {
        await sendAdminClaimAccountEmail(user.id);
      }
      toast({
        type: 'success',
        title: t('toast.success'),
        message: `Claim Account Email sent to ${user.email}`,
        theme,
      });
    } catch (error: any) {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: (error as AxiosError).message,
        theme,
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'last_name' }),
    },
    {
      title: 'Email',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'email' }),
    },
    {
      title: 'Role',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'role' }),
    },
    {
      title: 'Claimed',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'claimed_account' }),
    },
    {
      title: 'Date Invited',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'date_joined' }),
    },
  ];

  const rows = tableData.map((user: UserType) => ({
    key: `table-row${user.id}`,
    cells: [
      <TableCellString text={`${user.first_name} ${user.last_name}`} />,
      <TableCellString text={user.email} />,
      <ContentWrapper>
        <NameText as="p">{user.role}</NameText>
        {currentUser && isAdmin(currentUser.role) && (
          <Button
            label=""
            onClick={() => onClickEditRole(user)}
            styleVariant="tertiary"
            Icon={PenToSquareIcon}
          />
        )}
      </ContentWrapper>,
      user.claimed_account ? (
        <CircleCheckIcon color={theme.colors.chartChartGreenPressedFunction5100} />
      ) : (
        <Button
          label="Resend Email"
          onClick={() => onResendClaimAccountEmail(user)}
          styleVariant="tertiary"
        />
      ),
      <TableCellString text={formatDateShort(user.date_joined)} />,
    ],
  }));

  return <Table columns={columns} rows={rows} />;
}

export default AdminsTable;
