import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import pages from 'pages';
import { useNavigate, useLocation, matchRoutes } from 'react-router-dom';
import { getEnv } from 'legacy/utils';

const AxiosInterceptorContext = React.createContext({});

const routes = [
  { path: `/${pages.CLAIM_ACCOUNT}` },
  { path: `/${pages.FORGOT_PASSWORD}` },
  { path: `/${pages.RESET_PASSWORD}` },
  { path: `/${pages.CHAT}` },
  { path: `/${pages.REGISTRATION}` },
  { path: `/${pages.VERIFICATION}` },
  { path: `/${pages.PASSWORD}` },
  { path: `/${pages.TOO_MANY_ATTEMPTS}` },
];

interface Props {
  children: JSX.Element;
}

function AxiosInterceptorProvider({ children }: Props) {
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const resInterceptor = useCallback((response: AxiosResponse) => response, []);

  const errInterceptor = useCallback(
    (error: AxiosError) => {
      const excluded = matchRoutes(routes, location);
      if (error.response?.status === 401 && !excluded?.length) {
        navigate(pages.LOGIN, { replace: true, state: { path: location.pathname } });
      }
      const requestMethod = error.config?.method
        ? error.config.method.toUpperCase()
        : 'UNDEFINED_REQ_METHOD';
      const requestUrl = error.config?.url || 'UNDEFINED_REQ_URL';
      const statusCode = error.response?.status || 'UNDEFINED_STATUS_CODE';
      const message = `${requestMethod} ${requestUrl} failed with status code ${statusCode}`;
      const newErrorWithUpdatedMessage = { ...error, message };
      return Promise.reject(newErrorWithUpdatedMessage);
    },
    [navigate, location],
  );

  useEffect(() => {
    const resInterceptorId = axios.interceptors.response.use(resInterceptor, errInterceptor);
    const reqInterceptorId = axios.interceptors.request.use(
      (config) => {
        const newConfig = config;
        newConfig.headers['app-version'] = getEnv('VITE_APP_VERSION');
        return newConfig;
      },
      (error) => Promise.reject(error),
    );

    setIsReady(true);
    return () => {
      axios.interceptors.response.eject(resInterceptorId);
      axios.interceptors.request.eject(reqInterceptorId);
    };
  }, [errInterceptor, resInterceptor]);

  const value = useMemo(() => ({}), []);

  return (
    <AxiosInterceptorContext.Provider value={value}>
      {isReady && children}
    </AxiosInterceptorContext.Provider>
  );
}

export { AxiosInterceptorProvider, AxiosInterceptorContext };
