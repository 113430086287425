import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function FoldersIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2222 2.57777H10.3306L8.96556 1.32082C8.63056 0.98721 8.18056 0.799988 7.70833 0.799988H4.44444C3.46389 0.799988 2.66667 1.5961 2.66667 2.57777V8.79999C2.66667 9.78165 3.4625 10.5778 4.44444 10.5778H14.2222C15.2042 10.5778 16 9.78165 16 8.79999V4.35554C16 3.37499 15.2028 2.57777 14.2222 2.57777ZM14.6667 8.79999C14.6667 9.0451 14.4673 9.24443 14.2222 9.24443H4.44444C4.19939 9.24443 4 9.0451 4 8.79999V2.57777C4 2.33265 4.19939 2.13332 4.44444 2.13332H7.70833C7.82703 2.13332 7.93869 2.17954 8.0225 2.26354L9.77778 3.9111H14.2222C14.4673 3.9111 14.6667 4.11043 14.6667 4.35554V8.79999ZM12.6667 13.2444H3.33333C1.49528 13.2444 0 11.75 0 9.9111V3.24443C0 2.87777 0.298611 2.57777 0.666667 2.57777C1.03472 2.57777 1.33333 2.87777 1.33333 3.24443V9.9111C1.33333 11.0139 2.23056 11.9111 3.33333 11.9111H12.6667C13.0347 11.9111 13.3333 12.2097 13.3333 12.5778C13.3333 12.9458 13.0361 13.2444 12.6667 13.2444Z"
        fill={color}
      />
    </svg>
  );
}

export default FoldersIcon;
