import React from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { UserProfile as UserProfileV2 } from 'admin/modules/user-profile/screens/UserProfile';
import { FlagName } from 'api/types';
import UserDetailView from 'legacy/views/admin/UserDetailView';

export function UserProfile() {
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  return adminV2 ? <UserProfileV2 /> : <UserDetailView />;
}

export default UserProfile;
