import styled from 'styled-components';

export const AgreementsWapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AgreementsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;
