import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { generateRandomString } from 'shared/utils/generateRandomString';
import {
  getCheckboxState,
  getCheckboxStyleVariantValues,
  TCheckboxStyleVariantsKey,
} from 'shared/design-system/theme/checkboxes';
import { Input, Label, OuterContainer, Box, HintText, Text } from './Checkbox.styles';
import { CheckIcon } from '../icons';

export type TCheckboxProps = {
  dataTestId?: string;
  id?: string;
  isRequired?: boolean;
  forwardRef?: React.MutableRefObject<HTMLInputElement | null>;
  isChecked: boolean;
  margin?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string | React.ReactNode;
  hint?: string;
  error?: boolean;
  styleVariant?: TCheckboxStyleVariantsKey;
};

/**
 * @param {string} [dataTestId] - id value for the checkbox used for testing purposes (optional)
 * @param {string} [id] - id value for the checkbox. Used for accessibility purposes. This value should not change. Default provided if none passed as a prop (optional)
 * @param {boolean} [isRequired] - is this input required? Defaults to false (optional)
 * @param {React.MutableRefObject<HTMLInputElement | null>} [forwardRef] - ref object to pass to the component (optional)
 * @param {boolean} isChecked - is the checkbox checked? Defaults to false (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {function} [onChange] - onChange handler (optional)
 * @param {string} [text] - text regarding what checkbox is referring too (optional)
 * @param {string} [hint] - text that appears when user needs a hint to check checkbox (optional)
 * @param {boolean} [error] - did an error occur? defaults to false (optional)
 */

export function Checkbox({
  dataTestId,
  id: idProp,
  isRequired = false,
  isChecked = false,
  forwardRef,
  margin = '0',
  onChange,
  text,
  hint,
  error,
  styleVariant = 'default',
}: TCheckboxProps) {
  const checkboxId = useMemo(() => idProp || generateRandomString(), [idProp]);

  const theme = useTheme();

  const checkboxState = getCheckboxState(false, Boolean(error));

  const { checkColor } = getCheckboxStyleVariantValues(theme, styleVariant, checkboxState);

  return (
    <OuterContainer $margin={margin}>
      <Label
        $styleVariant={styleVariant}
        $state={checkboxState}
        aria-describedby={`${checkboxId}-label`}
        id={checkboxId}
        data-testid={dataTestId}
        htmlFor={`${checkboxId}-input`}
      >
        <Input
          $styleVariant={styleVariant}
          $state={checkboxState}
          required={isRequired}
          aria-required={isRequired}
          ref={forwardRef}
          id={`${checkboxId}-input`}
          aria-checked={isChecked}
          checked={isChecked}
          type="checkbox"
          onChange={onChange}
        />
        <Box $styleVariant={styleVariant} $state={checkboxState}>
          <CheckIcon color={checkColor} />
        </Box>
      </Label>
      <Text $styleVariant={styleVariant} $state={checkboxState}>
        {text}
      </Text>
      {hint && (
        <HintText $styleVariant={styleVariant} $state={checkboxState}>
          {hint}
        </HintText>
      )}
    </OuterContainer>
  );
}

export default Checkbox;
