import React from 'react';
import { useTheme } from 'styled-components';

import { FaqType } from 'api/types';
import { TagRequestBody } from 'api/faqs';
import { Button } from 'shared/components/Button/Button';
import { PenToSquareIcon } from 'shared/components/icons/PenToSquareIcon';
import { StatusPill } from 'shared/components/StatusPill/StatusPill';
import { CircleXIcon, Plus } from 'shared/components/icons';
import {
  Answer,
  Body,
  FaqCardStyled,
  Header,
  HeaderText,
  HeaderTextWrapper,
  Question,
  RemoveButton,
  Tag,
  Tags,
  TagText,
} from './FaqCard.styles';

type Props = {
  label: string;
  faq: FaqType;
  onClickEditFaq: () => void;
  onClickAddTag: () => void;
  onClickRemoveTag: (body: TagRequestBody) => void;
};

export function FaqCard({ label, faq, onClickEditFaq, onClickAddTag, onClickRemoveTag }: Props) {
  const theme = useTheme();

  return (
    <FaqCardStyled>
      <Header $isHidden={faq.hidden}>
        <HeaderTextWrapper>
          <HeaderText as="h3">{label}</HeaderText>
          {faq.hidden && <StatusPill text="Hidden" bgColor="backgroundInteractionHoverGrey10" />}
        </HeaderTextWrapper>
        <Button
          styleVariant="tertiary"
          label="Edit"
          onClick={onClickEditFaq}
          Icon={PenToSquareIcon}
        />
      </Header>
      <Body>
        <Question as="h3">{faq.question}</Question>
        <Answer as="p">{faq.answer}</Answer>

        <Tags>
          {faq.tags.map((tag) => (
            <Tag key={tag.id} $isHidden={faq.hidden}>
              <TagText as="p" $isHidden={faq.hidden}>
                {tag.tag}
              </TagText>
              <RemoveButton
                $isHidden={faq.hidden}
                onClick={() => onClickRemoveTag({ faqId: faq.id, tagId: tag.id })}
              >
                <CircleXIcon
                  color={
                    faq.hidden
                      ? theme.colors.textSecondaryGrey90
                      : theme.colors.textLinkLinkVisitedFunction4100
                  }
                />
              </RemoveButton>
            </Tag>
          ))}
          <Button styleVariant="tertiary" label="Add Tags" Icon={Plus} onClick={onClickAddTag} />
        </Tags>
      </Body>
    </FaqCardStyled>
  );
}

export default FaqCard;
