import React, { useEffect, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { CustomPicker } from './CustomPicker';
import { CustomPickerButton } from './DateSelector.styles';

interface Props {
  onDateChange: MonthChangeEventHandler;
  selectedDate: Date;
  onCloseModal: () => void;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dateRange: { oldest: Date; newest: Date };
  inServiceDate?: Date;
}

export function YearPicker({
  onDateChange,
  selectedDate,
  onCloseModal,
  dateRange,
  inServiceDate,
}: Props) {
  const [selectedYear, setSelectedYear] = useState<number>(selectedDate.getFullYear());
  const [startYear, setStartYear] = useState<number>(selectedDate.getFullYear());
  const YEARS_PER_PAGE = 12;
  const today = new Date();

  useEffect(() => {
    const dateRangeStartYear = dateRange.oldest.getFullYear() - (YEARS_PER_PAGE - 1);
    const initialStartYear =
      inServiceDate && inServiceDate.getFullYear() > dateRangeStartYear
        ? inServiceDate.getFullYear()
        : dateRangeStartYear;
    setStartYear(initialStartYear);
  }, [dateRange, inServiceDate]);

  const onYearClick = (year: number) => {
    setSelectedYear(year);
  };

  const onOkClick = () => {
    onDateChange(new Date(selectedYear, 0));
    onCloseModal();
  };

  const getOnPrevClick = () => {
    if (inServiceDate && startYear <= inServiceDate.getFullYear()) return undefined;

    return () => setStartYear(startYear - YEARS_PER_PAGE);
  };

  const getOnNextClick = () => {
    if (startYear + (YEARS_PER_PAGE + 1) >= today.getFullYear()) return undefined;
    return () => setStartYear(startYear + YEARS_PER_PAGE);
  };

  const yearPickerGrid = () => {
    const yearGrid: React.ReactNode[] = [];
    for (let i = startYear; i < startYear + YEARS_PER_PAGE; i += 1) {
      let disableButton = false;
      if (i > today.getFullYear() || (inServiceDate && i < inServiceDate.getFullYear()))
        disableButton = true;

      yearGrid.push(
        <CustomPickerButton
          key={i}
          selected={i === selectedYear}
          onClick={() => onYearClick(i)}
          disabled={disableButton}
          $timeScale="year"
        >
          {i}
        </CustomPickerButton>,
      );
    }
    return yearGrid;
  };

  return (
    <CustomPicker
      header={selectedYear}
      items={yearPickerGrid()}
      onCloseModal={onCloseModal}
      onOkClick={onOkClick}
      onPrevClick={getOnPrevClick()}
      onNextClick={getOnNextClick()}
      size="small"
      timeScale="year"
    />
  );
}

export default YearPicker;
