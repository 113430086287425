import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function ActivityIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.875 0.428571C0.875 0.192857 0.678125 0 0.4375 0C0.196875 0 0 0.192857 0 0.428571V9.85714C0 11.0411 0.978906 12 2.1875 12H13.5625C13.8031 12 14 11.8071 14 11.5714C14 11.3357 13.8031 11.1429 13.5625 11.1429H2.1875C1.46289 11.1429 0.875 10.567 0.875 9.85714V0.428571ZM12.9965 3.30268C13.166 3.13661 13.166 2.86339 12.9965 2.69732C12.827 2.53125 12.548 2.53125 12.3785 2.69732L8.75 6.25179L6.43398 3.98304C6.26445 3.81696 5.98555 3.81696 5.81602 3.98304L2.75352 6.98304C2.58398 7.14911 2.58398 7.42232 2.75352 7.58839C2.92305 7.75446 3.20195 7.75446 3.37148 7.58839L6.125 4.89107L8.44102 7.15982C8.61055 7.32589 8.88945 7.32589 9.05898 7.15982L12.9965 3.30268Z"
        fill={color}
      />
    </svg>
  );
}

export default ActivityIcon;
