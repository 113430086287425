import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DateRangeZoomLevel } from 'legacy/types';
import { getEnergySummary, getPowerFlow, getSiteTimezone } from 'api/system';
import { Banner } from '../common/design-system/Banner/Banner';
import { colors } from '../../../theme/colors';
import WarningIcon from '../../../static/warning-icon.svg';
import MonitoringSnapshot from '../MonitoringSnapshot';
import { MonitoringChart } from '../../views/dashboard/System/MonitoringChart';
import { createDateRange, DateRange, DateRangeStartDateBehavior } from './utils/createDateRange';

const REALTIME_POWER_FLOW_REFETCH_INTERVAL = 10 * 1000; // 10 seconds

export enum RendableState {
  UNDETERMINED,
  NOT_RENDERABLE,
  RENDERABLE,
}

type Props = {
  systemId?: string;
  systemCapacity?: number;
  homeOwnerContractId?: string;
};

export function MonitoringCard({
  systemCapacity,
  systemId: systemIdProp,
  homeOwnerContractId,
}: Props) {
  const [systemTimezone, setSystemTimezone] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [dateRanges, setDateRanges] = useState<Partial<Record<DateRangeZoomLevel, DateRange>>>({});
  const [renderableState, setRenderableState] = useState<RendableState>(RendableState.UNDETERMINED);

  const systemId = systemIdProp || '';

  const { data: powerFlowResponseQuery } = useQuery(
    ['powerFlow', systemId],
    () => getPowerFlow(systemId).then((res) => res.data),
    {
      enabled: Boolean(systemId),
      refetchInterval: REALTIME_POWER_FLOW_REFETCH_INTERVAL,
    },
  );

  const { data: energySummaryResponseQuery } = useQuery(
    ['solarEnergySummary', systemId],
    () => getEnergySummary(systemId).then((res) => res.data),
    {
      enabled: Boolean(systemId),
    },
  );

  const { data: systemMetadata, isError: systemMetadataError } = useQuery(
    'siteTimezone',
    () => getSiteTimezone(systemId).then((res) => res.data),
    {
      enabled: Boolean(systemId),
    },
  );

  useEffect(() => {
    if (systemMetadataError) {
      setRenderableState(RendableState.NOT_RENDERABLE);
    }
  }, [systemMetadataError]);

  useEffect(() => {
    if (!systemId) {
      setRenderableState(RendableState.NOT_RENDERABLE);
    }
  }, [systemId]);

  useEffect(() => {
    if (systemMetadata) {
      setSystemTimezone(systemMetadata.timezone);
      setRenderableState(RendableState.RENDERABLE);
      const newDateRange = createDateRange(
        new Date(),
        DateRangeZoomLevel.DAY,
        systemMetadata.timezone,
        DateRangeStartDateBehavior.FROM_CALENDAR_UNIT,
      );

      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{
          [DateRangeZoomLevel.DAY]: newDateRange!,
        },
      });
    }
    // disabling because we only want this to run when systemMetadata changes,
    // and the others aren't likely to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMetadata]);

  const handleDateChange = (date: Date) => {
    if (!dateRange || !systemTimezone) {
      return;
    }
    const newDateRange = createDateRange(
      date,
      dateRange.zoomLevel,
      systemTimezone,
      DateRangeStartDateBehavior.FROM_DATE,
    );
    setDateRange(newDateRange);

    setDateRanges({
      ...dateRanges,
      ...{
        [dateRange.zoomLevel]: newDateRange,
      },
    });
  };

  const handleZoomlevelChanged = (zoomLevel: DateRangeZoomLevel) => {
    const existingDateRange = dateRanges[zoomLevel];
    if (existingDateRange) {
      setDateRange(existingDateRange);
    } else {
      const newDateRange = createDateRange(
        new Date(),
        zoomLevel,
        systemTimezone,
        DateRangeStartDateBehavior.FROM_CALENDAR_UNIT,
      );
      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{
          [zoomLevel]: newDateRange,
        },
      });
    }
  };

  const errorBannerText = `Up-to-date monitoring data is currently unavailable. Don’t worry, we are on it! Please check back for updates.`;

  return systemId ? (
    <>
      {renderableState === RendableState.NOT_RENDERABLE && (
        <Banner
          bannerText={errorBannerText}
          bgColor={colors.yellow20}
          icon={<img alt="warning" src={WarningIcon} />}
          dismissable={false}
        />
      )}
      <MonitoringSnapshot
        energySummary={energySummaryResponseQuery || null}
        powerFlow={powerFlowResponseQuery || null}
      />

      {renderableState === RendableState.RENDERABLE && (
        <MonitoringChart
          dateRange={dateRange}
          systemId={systemId}
          systemCapacity={systemCapacity}
          currentDate={dateRange?.startDate || new Date()}
          onDateChange={handleDateChange}
          onZoomLevelChange={handleZoomlevelChanged}
        />
      )}
    </>
  ) : (
    <p>No Monitoring Data for {homeOwnerContractId}</p>
  );
}

export default MonitoringCard;
