import styled from 'styled-components';

type TableRowStyledProps = {
  $hasCursor: boolean;
};

export const TableRowStyled = styled.tr<TableRowStyledProps>`
  cursor: ${({ $hasCursor }) => ($hasCursor ? 'pointer' : 'initial')};
  border-bottom: solid 1px ${({ theme }) => theme.colors.strokeSecondaryGrey10};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundInteractionHoverGrey10};
  }

  td:first-child {
    padding-left: 16px;
  }
`;

export const TableCell = styled.td`
  padding: 16px 0 16px 36px;
  vertical-align: top;
  overflow: hidden;
  overflow-wrap: anywhere;
  width: calc(150px + 36px);
`;
