import React, { useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { getAgreements } from 'api/agreement';
import { AdminContext } from 'contexts/AdminContext';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { Header } from 'shared/components/Header/Header';
import AgreementsTable from '../components/AgreementsTable/AgreementsTable';
import { AgreementsBody, AgreementsWapper } from './Agreements.styles';

export function Agreements() {
  const { agreementTableState, setAgreementTableState } = useContext(AdminContext);
  const { page, pageSize, direction, sortBy } = agreementTableState;

  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const debouncedSetQuery = debounce((q) => setQuery(q), 750);

  useEffect(() => {
    getAgreements({
      search: query,
      page,
      page_size: pageSize,
      sort_by: sortBy,
      direction,
    }).then((response) => {
      setAgreementTableState({
        agreements: response.data.results,
        page,
        pageSize,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / pageSize),
        query,
        direction,
        sortBy,
      });
    });
  }, [query, page, pageSize, sortBy, direction, setAgreementTableState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetQuery(e.target.value);
  };

  const handlePageChange = (activePage: number) => {
    setAgreementTableState({
      ...agreementTableState,
      page: activePage,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      debouncedSetQuery.cancel();
      setQuery((e.target as HTMLInputElement).value);
    }
  };

  return (
    <AgreementsWapper>
      <Header pageName="AGREEMENTS" title={t('admin.agreements')} />

      <AgreementsBody>
        <TextInput
          ariaLabel="Search"
          hasLabel={false}
          placeholder="Search Agreements"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />

        <AgreementsTable
          agreementsTableState={agreementTableState}
          setAgreementsTableState={setAgreementTableState}
        />

        <Pagination
          totalPages={agreementTableState.totalPages}
          activePage={agreementTableState.page}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </AgreementsBody>
    </AgreementsWapper>
  );
}

export default Agreements;
