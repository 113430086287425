/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { toast } from 'shared/components/Toast/Toast';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import { RolesKey, USER_ROLES } from 'modules/types/util';
import { Select } from 'shared/components/Select/Select';
import { Form } from './EditRoleModal.styles';

const EDIT_USER_FORM_ID = 'EDIT_USER_FORM_ID';

export interface IFormInput {
  role: RolesKey;
}

type Props = {
  isOpen: boolean;
  initialRole: RolesKey;
  onSubmitUserForm: (formData: IFormInput) => void;
  onClose: () => void;
};

export function EditRoleModal({ isOpen, initialRole, onSubmitUserForm, onClose }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { reset, handleSubmit, setValue } = useForm<IFormInput>({
    defaultValues: { role: initialRole },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const ctaButtons: CtaButtons = {
    tertiary: {
      label: t('cta.cancel'),
      onClick: handleClose,
    },
    primary: {
      type: 'submit',
      formId: EDIT_USER_FORM_ID,
      label: t('cta.submit'),
    },
  };

  const roleOptions = Object.keys(USER_ROLES).map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    value: key,
  }));

  const onSubmit = (formData: IFormInput) => {
    if (!formData.role) {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: 'Role is required',
        theme,
      });

      return;
    }

    onSubmitUserForm(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Role"
      styleVariant="tertiary"
      contentLabel="Edit User Modal"
      ctaButtons={ctaButtons}
      ctaSpacedBetween
      onRequestClose={handleClose}
    >
      <Form id={EDIT_USER_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <Select
          id="contact-form-role"
          ariaLabelledBy={t('account.role')}
          options={roleOptions}
          onSelectOption={(value) => {
            setValue('role', value as RolesKey, { shouldDirty: true, shouldTouch: true });
          }}
        />
      </Form>
    </Modal>
  );
}

export default EditRoleModal;
