import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import Button from 'shared/components/Button/Button';
import { AutopayGraphic } from 'shared/components/icons';
import { Modal } from 'shared/components/Modal/Modal';
import { Text } from 'shared/components/Text/Text';
import { perSessionModalKey } from 'constants/localStorageKeys';
import { useTheme } from 'styled-components';
import { AxiosError } from 'axios';
import { getEnv } from 'shared/utils/getEnv';
import { AutopayModalContent, AutopayModalSigningContainer } from './AutopayModal.styles';
import { useGetEmbeddedSigningUrl } from '../../../../api/agreement';
import {
  getButtonStyleVariantValues,
  themeButtons,
} from '../../../../shared/design-system/theme/buttons';
import AutopaySuccessIcon from '../../../../shared/components/icons/AutopaySuccessIcon';
import { toast } from '../../../../shared/components/Toast/Toast';
import { guessEnvironment } from '../../../../shared/utils/guessEnvironment';

declare global {
  interface Window {
    DocuSign: any;
  }
}

enum DocusignSessionEndType {
  SIGNING_COMPLETE = 'signing_complete',
  VIEWING_COMPLETE = 'viewing_complete',
}

const DOCUSIGN_SIGNING_SECTION_ID = 'signing-section';

export type AutopayModalProps = {
  agreementId: string;
  isOpen: boolean;
  discountEligible: boolean;
  modalKey?: string;
  onCloseAutopayModal: () => void;
};

export function AutopayModal({
  agreementId,
  isOpen,
  discountEligible,
  modalKey,
  onCloseAutopayModal,
}: AutopayModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const [loading, setLoading] = useState<boolean>(false);
  const [signingReady, setSigningReady] = useState<boolean>(false);
  const [signingComplete, setSigningComplete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const docusignRef = useRef<any>(null);

  const theme = useTheme();
  const { fontColor: docuSignButtonFontColor, background: docusignButtonBackgroundColor } =
    getButtonStyleVariantValues(theme, themeButtons, 'primary');

  const integrationKey = getEnv('VITE_APP_DOCUSIGN_INTEGRATION_KEY');

  const { mutateAsync: getEmbeddedSigningUrlAsync } = useGetEmbeddedSigningUrl({
    onError(error: AxiosError) {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: error.message,
        theme,
      });
    },
  });

  useEffect(() => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);

    if (perSessionModalsRaw !== null) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      // if this modal has a defined item we've already shown it and can preemptively close the modal
      if (!modalKey || (modalKey && modalsObj[modalKey])) {
        onCloseAutopayModal();
      } else {
        localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
      }
    }
  }, [onCloseAutopayModal, modalKey]);

  const handleCloseAutopayModal = useCallback(() => {
    onCloseAutopayModal();
  }, [onCloseAutopayModal]);

  const loadDocuSignScript = (): Promise<void> =>
    new Promise((resolve, reject) => {
      const docusignScript = document.getElementById('docusignScript');
      if (docusignScript || window.DocuSign) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'docusignScript';
      script.src =
        guessEnvironment() === 'prod'
          ? 'https://js.docusign.com/bundle.js'
          : 'https://js-d.docusign.com/bundle.js';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load DocuSign script'));
      document.body.appendChild(script);
    });

  const initializeDocuSign = useCallback(async () => {
    await loadDocuSignScript();
    docusignRef.current = await window.DocuSign.loadDocuSign(integrationKey);
  }, [integrationKey]);

  useEffect(() => {
    initializeDocuSign();
  }, [initializeDocuSign, integrationKey]);

  const handleOpenDocuSign = async () => {
    setLoading(true);
    try {
      if (!docusignRef.current) {
        await initializeDocuSign();
      }
      const signingUrlResponse = await getEmbeddedSigningUrlAsync({ agreement_id: agreementId });
      const signingConfiguration = {
        url: signingUrlResponse.url,
        displayFormat: 'focused',
        style: {
          branding: {
            primaryButton: {
              backgroundColor: docusignButtonBackgroundColor,
              color: docuSignButtonFontColor,
            },
          },
          signingNavigationButton: {
            finishText: 'Finish',
          },
        },
      };

      const signing = docusignRef.current.signing(signingConfiguration);

      signing.on('sessionEnd', (event: any) => {
        if (
          event.sessionEndType === DocusignSessionEndType.SIGNING_COMPLETE ||
          event.sessionEndType === DocusignSessionEndType.VIEWING_COMPLETE
        ) {
          setSigningComplete(true);
        }
      });

      setSigningReady(true);
      signing.mount(`#${DOCUSIGN_SIGNING_SECTION_ID}`);
    } catch (error: any) {
      if (error instanceof AxiosError && (error as AxiosError).message) {
        setErrorMessage((error as AxiosError).message);
      } else {
        setErrorMessage('An error occurred. Please refresh this page and try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    if (errorMessage) {
      return (
        <>
          <AutopayGraphic />
          <Text as="h2">{t('error.generic.header')}</Text>
          <Text as="p">{t('autopayModal.errorBody1')}</Text>
          <Text as="p">{errorMessage}</Text>
        </>
      );
    }

    if (signingComplete) {
      return (
        <>
          <AutopaySuccessIcon />
          <Text as="h2">{t('autopayModal.successHeader')}</Text>
          <Text as="p">{t('autopayModal.successBody1')}</Text>
          <Text as="p">{t('autopayModal.successBody2')}</Text>
          <Button label={t('autopayModal.gotItButton')} onClick={handleCloseAutopayModal} />
        </>
      );
    }
    return (
      <>
        <AutopayModalSigningContainer
          id={DOCUSIGN_SIGNING_SECTION_ID}
          $isSigningReady={signingReady}
        />

        {!signingReady && <AutopayGraphic />}
        {!signingReady && discountEligible && (
          <>
            <Text as="h2">{t('autopayModal.setUpAutopay')}</Text>
            <Text as="p">
              <Trans>{t('autopayModal.body1')}</Trans>
            </Text>
            <Text as="p">{t('autopayModal.body2')}</Text>
          </>
        )}
        {!signingReady && !discountEligible && (
          <>
            <Text as="h2">{t('autopayModal.neverMissABill')}</Text>
            <Text as="p">{t('autopayModal.goodbyeToPaperChecks')}</Text>
            <Text as="p">{t('autopayModal.safeSecure')}</Text>
          </>
        )}

        {!signingReady && (
          <Button
            label={t('autopayModal.acknowledge')}
            onClick={handleOpenDocuSign}
            isLoading={loading}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      contentLabel={
        discountEligible ? t('autopayModal.setUpAutopay') : t('autopayModal.neverMissABill')
      }
      styleVariant="tertiary"
      isOpen={isOpen}
      subHeader
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="24px"
      onRequestClose={handleCloseAutopayModal}
    >
      <AutopayModalContent>{renderModalContent()}</AutopayModalContent>
    </Modal>
  );
}

export default AutopayModal;
