import React from 'react';
import {
  TextAnchorType,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { ChartDataType, AxisType } from 'legacy/types';
import { DateRange } from 'legacy/components/MonitoringCard/utils/createDateRange';
import { Container, chartStyles } from './ChartStyles';
import { Tooltip } from './Tooltip/ToolTip';
import { GridComponent } from './GridComponent';
import { ChartLinearGradient } from './ChartLinearGradient';

type Props = {
  data: ChartDataType[];
  axes?: { x: AxisType; y: AxisType };
  tooltipDateFormatter: (date: Date, dateRange: DateRange) => string;
  dateRange: DateRange;
  yAxisUnit: string;
};

export function AreaChart({ data, axes, tooltipDateFormatter, dateRange, yAxisUnit }: Props) {
  const yTickLabelOffset = {
    x: -29,
    y: 15,
  };

  return (
    <Container>
      <VictoryChart
        singleQuadrantDomainPadding={false}
        domainPadding={chartStyles.domainPadding}
        padding={chartStyles.padding}
        height={chartStyles.height}
        width={chartStyles.width}
        name="chart"
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            voronoiBlacklist={['area', 'chart']}
            labels={() => ' '}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={
                  <Tooltip dateRange={dateRange} tooltipDateFormatter={tooltipDateFormatter} />
                }
              />
            }
          />
        }
      >
        <VictoryAxis
          dependentAxis
          tickFormat={axes?.y.tickFormat}
          style={{
            grid: { strokeWidth: 0.5, stroke: '#DAEBF6' },
            axis: { stroke: 'transparent' },
            tickLabels: chartStyles.tickLabels,
          }}
          tickValues={axes?.y.tickValues}
          tickLabelComponent={
            <VictoryLabel dy={yTickLabelOffset.y} dx={yTickLabelOffset.x} textAnchor="start" />
          }
          gridComponent={<GridComponent />}
          standalone={false}
        />
        <VictoryAxis
          name="yAxis"
          tickValues={axes?.x.tickValues}
          domain={axes?.x.domain}
          tickFormat={axes?.x.tickFormat}
          style={{
            tickLabels: chartStyles.tickLabels,
            axis: { stroke: '#949494' },
          }}
        />
        <ChartLinearGradient />
        <VictoryArea
          style={{ data: { fill: 'url(#myGradient)' } }}
          data={data}
          interpolation="natural"
          name="area"
        />
        <VictoryLine
          name="line"
          style={{
            data: {
              stroke: '#006FC8',
              strokeWidth: 1.0,
            },
          }}
          data={data}
          interpolation="natural"
        />
        <VictoryLabel
          x={chartStyles.label.x}
          y={chartStyles.label.y}
          textAnchor={chartStyles.label.textAnchor as TextAnchorType}
          text={yAxisUnit}
          style={chartStyles.label.style}
        />
      </VictoryChart>
    </Container>
  );
}

export default AreaChart;
