import styled, { css } from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const Card = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
  max-width: 555px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 24px;
  }
`;

export const Header = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 12px;
  }
`;

export const StaticTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled(Text)<{ $styleVariant: 'primary' | 'secondary' }>`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  color: ${({ $styleVariant, theme }) =>
    $styleVariant === 'primary'
      ? theme.colors.textPrimarySecondary1100
      : theme.colors.textSecondarySubtleGrey80};
`;

export const Value = styled(Text)<{ $styleVariant: 'primary' | 'secondary' }>`
  font-family: Avenir;
  font-size: 16px;

  ${({ $styleVariant, theme }) =>
    $styleVariant === 'primary'
      ? css`
          font-weight: 500;
          color: ${theme.colors.textSecondarySubtleGrey80};
        `
      : css`
          font-weight: 800;
          color: ${theme.colors.textPrimarySecondary1100};
        `}
`;

export const EditIconWrapper = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;
