import React from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FlagName } from 'api/types';
import BackfillWorkflows from 'legacy/components/admin/BackfillWorkflows';
import { Backfill as BackfillV2 } from 'admin/modules/backfill/screens/Backfill';

export function Backfill() {
  const {
    loading,
    flags: { [FlagName.adminV2]: adminV2 },
  } = useFeatureFlags();

  if (loading) return null;

  return adminV2 ? <BackfillV2 /> : <BackfillWorkflows />;
}

export default Backfill;
