import React from 'react';

export function EverBrightIconDark() {
  return (
    <svg
      width="120"
      height="121"
      viewBox="0 0 120 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.374 105.778H120V113.711H118.824V107.124H118.796L115.712 113.711H114.675L111.591 107.263H111.564V113.711H110.387V105.778H112.041L115.181 112.365L118.374 105.778Z"
        fill="white"
      />
      <path
        d="M105.337 106.787H102.309V105.778H109.485V106.787H106.485V113.711H105.309V106.787H105.337Z"
        fill="white"
      />
      <path
        d="M25.3266 28.7437L44.3215 33.1406L54.6984 16.608L32.5377 5L25.3266 28.7437Z"
        fill="white"
      />
      <path
        d="M86.0046 28.7437L78.6177 5L56.6329 16.608L67.0097 33.1406L86.0046 28.7437Z"
        fill="white"
      />
      <path
        d="M28.1406 49.1455L23.9195 30.1506L0 37.5375L11.7839 59.5224L28.1406 49.1455Z"
        fill="white"
      />
      <path
        d="M11.7839 61.4571L0 83.6178L23.9195 90.8289L28.1406 71.834L11.7839 61.4571Z"
        fill="white"
      />
      <path
        d="M56.6329 104.372L78.6177 116.155L86.0046 92.2359L67.0097 88.0148L56.6329 104.372Z"
        fill="white"
      />
      <path
        d="M25.3266 92.2359L32.5377 116.155L54.6984 104.372L44.3215 88.0148L25.3266 92.2359Z"
        fill="white"
      />
      <path
        d="M83.0148 71.834L87.4118 90.8289L111.155 83.6178L99.5474 61.4571L83.0148 71.834Z"
        fill="white"
      />
      <path
        d="M99.5474 59.5224L111.155 37.5375L87.4118 30.1506L83.0148 49.1455L99.5474 59.5224Z"
        fill="white"
      />
    </svg>
  );
}

export default EverBrightIconDark;
