import { DateRange } from 'api/system/utils/DateRange';

export const generateDatesInRange = (dateRange: DateRange, numberOfTicks: number): Date[] => {
  const dates: Date[] = [];
  const start = dateRange.startDate.getTime();
  const end = dateRange.endDate.getTime();

  // Calculate the total time difference in milliseconds
  const difference = end - start;

  // Calculate the interval in milliseconds
  const interval = difference / (numberOfTicks - 1);

  for (let i = 0; i < numberOfTicks; i += 1) {
    // Create new date for each tick and add to the array
    const date = new Date(start + i * interval);
    dates.push(date);
  }

  return dates;
};

export default generateDatesInRange;
