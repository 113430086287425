import React, { useEffect, useMemo, useState } from 'react';
import { useBreakpoints } from 'hooks/useWindowSize';
import { AxiosError } from 'axios';
import { DateRange } from 'api/system/utils/DateRange';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { ChartDataType } from 'modules/types';
import { DateRangeZoomLevel, PowerUnit, SystemType } from 'api/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getSolarPower } from 'api/system';
import { CACHE_STALE_TIME, MonitoringQueryKey, REFETCH_INTERVAL } from 'modules/system/utils/prefetchQueries';
import { AreaChart } from './AreaChart';
import { SpinnerContainer } from '../Chart.styles';
import { DayChartDataProvider } from '../../../utils/ChartDataProviders/DayChartDataProvider';
import { ChartDataError, DisplayChartError } from '../../ChartDataError/ChartDataError';

type Props = {
  dateRange: DateRange;
  system: SystemType;
  chartError: DisplayChartError | null
  onError: (error: null | DisplayChartError) => void;
}

export function DayChart({ dateRange, system, chartError,  onError }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const [yAxisUnit, setYAxisUnit] = useState<PowerUnit>('W');
  const [largestProductionValue, seLargestProductionValue] = useState<number | null>(null);
  
  const { t } = useTranslation();
  const screenSize = useBreakpoints() || 'md';

  const { zoomLevel, startDate } = dateRange;
  const isChartDataEmpty = chartData && chartData.length === 0;
  
  const dataProvider = useMemo(() => new DayChartDataProvider(system.site_timezone), [system.site_timezone]);
  
  const solarPowerQuery = useQuery(
    [MonitoringQueryKey.SOLAR_POWER, system?.id, dateRange?.startDate],
    () => getSolarPower({ systemId: String(system?.id), dateRange }).then((res) => res.data),
    {
      enabled:
        Boolean(system?.id) &&
        Boolean(dateRange) &&
        dateRange?.zoomLevel === DateRangeZoomLevel.DAY,
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

  const {data, isFetching, isError, error, refetch} = solarPowerQuery;

  useEffect(() => {
    if (!isFetching && data && Object.keys(data).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] = dataProvider.prepareData(
        data,
        dateRange,
      );
      seLargestProductionValue(_largestProductionValue);
      setChartData(productionData);
      setYAxisUnit(_yAxisEnergyUnit as PowerUnit);
    }
  }, [startDate, zoomLevel, dataProvider, dateRange, isFetching, data]);

  useEffect(() => {
    // don't set errors if query is still loading
    if (!isFetching) {
      if ((isError && (error as AxiosError)?.response?.status === 400) || isChartDataEmpty) {
        onError(DisplayChartError.TRY_AGAIN)
      } else if (isError) {
        onError(DisplayChartError.CONTACT_SUPPORT)
      } else {
        onError(null)
      }
    }
  }, [isError, error, t, isChartDataEmpty, isFetching, onError]);

  return (
    <>
    {(isError || isChartDataEmpty) && chartError && (
      <ChartDataError error={chartError} onClickTryAgain={refetch}/>
      )}

      {!isError &&
        !isChartDataEmpty &&
        (chartData.length && largestProductionValue !== null ? (
          <AreaChart
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange),
              y: dataProvider.getYAxis(largestProductionValue, yAxisUnit, screenSize),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            dateRange={dateRange}
            yAxisUnit={yAxisUnit}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default DayChart;
