import React, { useState } from 'react';
import { Link, NavLink, resolvePath, useMatch, useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useUser } from 'hooks/useUser';
import pages from 'pages';
import { unimpersonateUser } from 'api/user';
import { UserType } from 'legacy/types';
import { Container } from 'legacy/views/dashboard/DashboardStyles';
import { HeaderStyled } from 'legacy/views/LayoutStyles';
import { getDashboardRedirectPath, isHomeowner } from 'legacy/utils';
import { EverBrightLogo } from 'shared/components/icons/EverBrightLogo';
import AccountSelect from '../AccountSelect/AccountSelect';
import { Modal } from '../common/design-system';
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from '../../constants';
import { NavDropdown } from './NavDropdown';
import { AccountSelectItem, Menu, MenuItem } from './NavBarStyles';

function NavBar() {
  const matchDashboard = useMatch('/dashboard/*');
  const matchStatus = useMatch('/status');

  const showAccountSelect = matchDashboard || matchStatus;

  const matchLogin = useMatch('/login');
  const matchChat = useMatch('/chat');

  const navigate = useNavigate();
  const { userResult, setUserResult, selectedAccount, logout } = useUser();

  const user = userResult?.user;

  const { hasDashboardAccess } = getDashboardRedirectPath(user || undefined, selectedAccount);

  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const toggleModal = () => setOpenLogoutModal(!openLogoutModal);

  const handleLogout = () => {
    logout();
  };

  const handleUnimpersonate = async () => {
    const response = await unimpersonateUser();
    const newUser: UserType = response.data.user;
    const newImpersonator: UserType = response.data?.impersonator;

    setUserResult({
      user: newUser,
      impersonator: newImpersonator,
      selectedAccount: newUser.accounts[0],
    });
    navigate(pages.ADMIN);
  };

  return (
    <HeaderStyled>
      <Container>
        <Menu>
          <MenuItem
            as={Link}
            to={
              isHomeowner(user?.role)
                ? resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`).pathname
                : `/${pages.ADMIN}`
            }
          >
            <EverBrightLogo />
          </MenuItem>

          {user ? (
            <>
              {hasDashboardAccess ? (
                <MediaQuery minWidth={DESKTOP_MIN_WIDTH}>
                  <MenuItem
                    as={NavLink}
                    to={resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`).pathname}
                  >
                    <span className="active-underscore">Dashboard</span>
                  </MenuItem>
                  <MenuItem
                    as={NavLink}
                    to={resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname}
                  >
                    <span className="active-underscore">System</span>
                  </MenuItem>
                  <MenuItem
                    as={NavLink}
                    to={resolvePath(pages.BILLING, `/${pages.DASHBOARD}`).pathname}
                  >
                    <span className="active-underscore">Billing & Payment</span>
                  </MenuItem>
                </MediaQuery>
              ) : null}

              <MenuItem $position="right">
                {showAccountSelect && (
                  <MediaQuery minWidth={DESKTOP_MIN_WIDTH}>
                    <AccountSelectItem>
                      <AccountSelect accounts={user.accounts} />
                    </AccountSelectItem>
                  </MediaQuery>
                )}
                <NavDropdown
                  user={user}
                  hasDashboardAccess={hasDashboardAccess}
                  impersonator={userResult.impersonator}
                  onUnimpersonate={handleUnimpersonate}
                  onLogoutModal={toggleModal}
                />
              </MenuItem>
              {user && matchLogin && (
                <MenuItem $position="right">
                  <a href="https://www.goeverbright.com/myeverbright">Back to Site</a>
                </MenuItem>
              )}
            </>
          ) : null}

          {!user && !matchLogin && !matchChat && (
            <MenuItem $position="right" as={NavLink} to={`/${pages.LOGIN}`}>
              Login
            </MenuItem>
          )}
        </Menu>
      </Container>
      {user
        ? showAccountSelect && (
            <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
              <div className="responsive-account-select">
                <AccountSelect accounts={user.accounts} />
              </div>
            </MediaQuery>
          )
        : null}
      <Modal
        isOpen={openLogoutModal}
        contentLabel="Log Out Modal"
        title="Log Out?"
        isFullWidth
        onRequestClose={toggleModal}
        ctaButtons={{ permanentPrimary: { label: 'Log Out', onClick: handleLogout } }}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </HeaderStyled>
  );
}

export default NavBar;
