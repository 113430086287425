import React, { useState } from 'react';
import parsePhoneNumberFromString, {
  AsYouType,
  CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';

import { TInputStyleVariantsKey } from 'shared/design-system/theme/inputs';
import { TextInput } from '../TextInput/TextInput';

const initialFormat = (number: string, country: CountryCode) => {
  const phoneNumber = parsePhoneNumberFromString(number, country);
  return phoneNumber?.formatNational() || '';
};

type Props = {
  /**
   * Aria Label for accesibility
   */
  ariaLabel: string;
  /**
   * is the input required in a form?
   */
  isRequired?: boolean;
  /**
   * default selected value
   */
  defaultValue?: string;
  /**
   * Optional data test ID value for testing purposes
   */
  dataTestId?: string;
  /**
   * Input Style Variant
   */
  styleVariant?: TInputStyleVariantsKey;
  /**
   * Optional error status boolean
   */
  hasError?: boolean;
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * Optional id
   */
  id?: string;
  /**
   * Optional Margin CSS value
   */
  margin?: string;
  /**
   * Optional Label text
   */
  label?: string;
  /**
   * Input's name being registered.
   */
  name?: string;
  placeholder?: string;
  /**
   * Fired after phone number is validated
   */
  onValidate?: (value: boolean) => void;
  /**
   * Optional onChange handler
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional onBlur handler
   */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onClick handler
   */
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyDown handler
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyUp handler
   */
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onFocus handler
   */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseEnter handler
   */
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseDown handler
   */
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseLeave handler
   */
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseUp handler
   */
  onMouseUp?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

export const PhoneInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      ariaLabel,
      isRequired,
      defaultValue: initialValue = '',
      dataTestId,
      styleVariant,
      hasError,
      errorMessage,
      id,
      margin,
      label,
      name,
      placeholder,
      onValidate,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
    }: Props,
    forwardRef: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [country] = useState<CountryCode>('US');
    const [number, setNumber] = useState(initialFormat(initialValue, country));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const parsedNumber = parsePhoneNumberFromString(value, country);

      // when using national format for US backspacing gets stuck, this checks the value length for area code to allow backspacing
      if (parsedNumber?.nationalNumber && parsedNumber?.nationalNumber.length > 3) {
        const formatted = new AsYouType(country).input(value);
        setNumber(formatted);
      } else {
        setNumber(value);
      }
      const valid = isValidPhoneNumber(parsedNumber?.number ?? '');
      onValidate?.(valid);
      onChange?.(e);
    };

    return (
      <TextInput
        ariaLabel={ariaLabel}
        isRequired={isRequired}
        value={number}
        dataTestId={dataTestId}
        styleVariant={styleVariant}
        hasError={hasError}
        errorMessage={errorMessage}
        id={id}
        placeholder={placeholder}
        margin={margin}
        label={label}
        ref={forwardRef}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
      />
    );
  },
);

export default PhoneInput;
