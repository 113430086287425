/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js';
import { useForm, useWatch } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { toast } from 'shared/components/Toast/Toast';
import { AddressType } from 'api/types';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import { RolesKey, USER_ROLES } from 'modules/types/util';
import { validateCity } from 'modules/account/utils/validateCity';
import { STATES } from 'constants/states';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { PhoneInput } from 'shared/components/PhoneInput/PhoneInput';
import { Select } from 'shared/components/Select/Select';
import { Form, FormGroup } from './AddUserModal.styles';

const ADD_USER_FORM_ID = 'ADD_USER_FORM_ID';

type Address = Omit<AddressType, 'id' | 'archived'>;

export interface IFormInput {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: E164Number;
  role: RolesKey;
  address?: Address;
}

type Props = {
  isOpen: boolean;
  onSubmitUserForm: (formData: IFormInput) => void;
  onClose: () => void;
};

export function AddUserModal({ isOpen, onSubmitUserForm, onClose }: Props) {
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const { t } = useTranslation('translation', { keyPrefix: 'admin.homeownerTransfer' });
  const { t: tGeneral } = useTranslation();
  const theme = useTheme();

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const watchRole = useWatch({
    control,
    name: 'role',
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const ctaButtons: CtaButtons = {
    tertiary: {
      label: tGeneral('cta.cancel'),
      onClick: handleClose,
    },
    primary: {
      type: 'submit',
      formId: ADD_USER_FORM_ID,
      label: tGeneral('cta.submit'),
    },
  };

  const roleOptions = Object.keys(USER_ROLES).map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    value: key,
  }));

  const stateOptions = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  const onSubmit = (formData: IFormInput) => {
    if (!formData.role) {
      toast({
        type: 'error',
        title: tGeneral('toast.error'),
        message: 'Role is required',
        theme,
      });

      return;
    }

    onSubmitUserForm(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      isFullHeight
      isFullWidth
      title="Add User"
      styleVariant="tertiary"
      contentLabel="Add User Modal"
      ctaButtons={ctaButtons}
      ctaSpacedBetween
      onRequestClose={handleClose}
    >
      <Form id={ADD_USER_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <TextInput
            id="contact-form-firstName"
            ariaLabel={t('firstName')}
            label={t('firstName')}
            hasError={Boolean(errors.first_name)}
            errorMessage={errors.first_name?.message}
            {...register('first_name', { required: t('required') })}
          />

          <TextInput
            id="contact-form-lastName"
            ariaLabel={t('lastName')}
            label={t('lastName')}
            hasError={Boolean(errors.last_name)}
            errorMessage={errors.last_name?.message}
            {...register('last_name', { required: t('required') })}
          />

          <TextInput
            id="contact-form-email"
            ariaLabel={tGeneral('account.email')}
            label={tGeneral('account.email')}
            hasError={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            {...register('email', { required: t('required') })}
          />
          <PhoneInput
            id="contact-form-phone"
            ariaLabel={tGeneral('account.phoneNumber')}
            label={tGeneral('account.phoneNumber')}
            onValidate={setIsPhoneValid}
            hasError={Boolean(errors.phone_number)}
            errorMessage={errors.phone_number?.message}
            margin="0 0 4px 0"
            {...register('phone_number', {
              validate: () => {
                if (!isPhoneValid) {
                  return tGeneral('account.invalidPhoneNumber');
                }
                return true;
              },
            })}
          />

          <Select
            id="contact-form-role"
            ariaLabelledBy={tGeneral('account.role')}
            options={roleOptions}
            onSelectOption={(value) => {
              setValue('role', value as RolesKey, { shouldDirty: true, shouldTouch: true });
            }}
          />
        </FormGroup>
        {watchRole === 'HOMEOWNER' && (
          <FormGroup>
            <TextInput
              id="contact-form-street1"
              ariaLabel={tGeneral('account.street1')}
              label={tGeneral('account.street1')}
              hasError={Boolean(errors.address?.street_1)}
              errorMessage={errors.address?.street_1?.message}
              {...register('address.street_1', { required: tGeneral('account.addressRequired') })}
            />

            <TextInput
              id="contact-form-street2"
              ariaLabel={tGeneral('account.street2')}
              label={tGeneral('account.street2')}
              hasError={Boolean(errors.address?.street_2)}
              errorMessage={errors.address?.street_2?.message}
              {...register('address.street_2')}
            />

            <TextInput
              id="contact-form-city"
              ariaLabel={tGeneral('account.city')}
              label={tGeneral('account.city')}
              hasError={Boolean(errors.address?.city)}
              errorMessage={errors.address?.city?.message}
              {...register('address.city', {
                required: tGeneral('account.cityRequired'),
                validate: (value: string) => validateCity(value, tGeneral),
              })}
            />

            <Select
              id="contact-form-state"
              ariaLabelledBy={tGeneral('account.state')}
              label={tGeneral('account.state')}
              options={stateOptions}
              onSelectOption={(value) => {
                setValue('address.state', value, { shouldDirty: true, shouldTouch: true });
              }}
            />

            <TextInput
              id="contact-form-zip"
              ariaLabel={tGeneral('account.zip')}
              label={tGeneral('account.zip')}
              hasError={Boolean(errors.address?.zip_code)}
              errorMessage={errors.address?.zip_code?.message}
              {...register('address.zip_code', { required: tGeneral('account.zipCodeRequired') })}
            />
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
}

export default AddUserModal;
