/* eslint-disable class-methods-use-this */
import { addHours, differenceInHours, differenceInSeconds, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { MonitoringDatum, AxisType, Tuple } from 'legacy/types';
import { ChartDataProvider } from './ChartDataProvider';
import { AnyChartDataProvider } from './AnyChartDataProvider';
import { generateDatesInRange } from '../utils/generateDatesInRange';
import { DateRange } from '../utils/createDateRange';

export class DayChartDataProvider extends ChartDataProvider implements AnyChartDataProvider {
  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    const dailyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit = null;

    monitoringData.forEach(({ value, time, unit }) => {
      if (value > largestProductionValue) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      }
      const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
      const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
      dailyProduction.set(hoursSinceCurrentDate, { time, value, unit });
    });

    return [dailyProduction, null, largestProductionUnit];
  }

  getDomain = (_: DateRange, __: number | null): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, 0],
    y: [0, 0],
  });

  tickXFormat = (hours: number, range: DateRange): string => {
    const date = addHours(range.startDate, hours);
    const zoned = toZonedTime(date, range.systemTimezone);
    return format(zoned, 'ha').toLowerCase();
  };

  tickYFormat = <T>(value: number, _: T): string => `${value.toFixed(1)}`;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = (maximumValue: number): number[] => {
    const numberOfTicks = 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return tickValues;
  };

  getYAxis = <T>(maximumValue: number, unit: T): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = (date: Date, dateRange: DateRange): string => {
    const zoned = toZonedTime(date, dateRange.systemTimezone);
    return format(zoned, 'h:mma').toLowerCase();
  };
}

export default DayChartDataProvider;
