import styled from 'styled-components';
import { Input } from '../TextInput/TextInput.styles';

export const TextInputStyled = styled(Input)`
  padding: 0;
  border: 0;
  &:focus {
    border: 0;
  }
`;

export default TextInputStyled;
