import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin: 16px 0;
`;

export const InfoCardWrapper = styled.div`
  margin-bottom: 32px;
`;

export const AdminAccordionWrapper = styled.div`
  #admin-accordion {
    padding: 0px;
  }
`;

export const VerticalSpacer = styled.div<{ $height: string }>`
  ${({ $height }) => `height: ${$height};`}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
`;
